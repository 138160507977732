<template>
  <v-container fluid>
    <div v-if="current.createdAt">
      <v-row class="header">
        <v-col class="d-flex flex-row justify-space-between" cols="12">
          <div class="d-flex align-center">
            <v-card-title>{{ firstName }}'s Account</v-card-title>
            <span :class="'ml-2 px-1 badge status-' + current.status.toLowerCase()">
              {{ current.status }}
            </span>
          </div>
        </v-col>
      </v-row>

      <div class="pt-10">
        <v-form ref="form">
          <v-row>
            <v-col class="px-10 d-flex flex-row justify-sm-start justify-space-between" sm="6" cols="12">
              <v-row>
                <v-col cols="12" xl="7" class="d-flex flex-row justify-sm-start justify-space-between flex-wrap">
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Account Number:</div>
                    <div>{{ current.number }}</div>
                  </div>
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Registration date:</div>
                    <div>{{ current.createdAt.slice(0, 10) }}</div>
                  </div>
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Account Owner:</div>
                    <div>{{ firstName }} {{ middleName }} {{ lastName }}</div>
                  </div>
                </v-col>
                <v-col cols="12" xl="5" class="d-flex flex-row justify-sm-start justify-space-between flex-wrap">
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Account Payment Reference:</div>
                    <div>{{ paymentRef }}</div>
                  </div>
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Account Organization:</div>
                    <div>{{ organization }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="px-10 d-flex flex-row justify-sm-end justify-space-between" sm="6" cols="12">
              <v-row>
                <v-col cols="12" xl="7" class="d-flex flex-row justify-sm-end justify-space-between flex-wrap">
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Fiat Ballance</div>
                    <div v-for="(value, name) in totalBallance.cashTotals" :key="name">
                      <div>${{ value.available }} {{ name }}</div>
                    </div>
                    <v-divider class="mb-8 mt-4" />
                  </div>
                  <div class="mx-4 my-2"></div>
                  <div class="mx-4 my-2">
                    <div class="font-weight-bold">Bitcoin Ballance</div>
                    <div v-for="(value, name) in totalBallance.assetsTotals" :key="name">
                      <div v-if="name === 'BTC'">{{ value.available }} {{ name }}</div>
                    </div>
                    <v-divider class="mb-8 mt-4" />
                  </div>
                </v-col>
                <v-col cols="12" xl="7" class="d-flex flex-row justify-sm-end justify-space-between flex-wrap">
                  <div>
                    <v-btn
                      class="mx-4"
                      fab
                      color="primary"
                      @click="depositDialog = true"
                      :disabled="status !== 'opened'"
                    >
                      <v-icon dark> mdi-deposit </v-icon>
                    </v-btn>
                    <p class="text-center btn-description">Deposit /<br />Receive</p>
                  </div>
                  <div>
                    <v-btn class="mx-4" fab color="#0BB8B0" @click="quoteDialog = true" :disabled="status !== 'opened'">
                      <v-icon dark> mdi-buy </v-icon>
                    </v-btn>
                    <p class="text-center btn-description">Buy /<br />Sell</p>
                  </div>
                  <div>
                    <v-btn class="mx-4" fab color="#8297AF" @click="sendDialog = true" :disabled="status !== 'opened'">
                      <v-icon dark> mdi-withdrawal </v-icon>
                    </v-btn>
                    <p class="text-center btn-description">Withdrawal<br />/ Send</p>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-divider class="mb-8 mt-4" />
          <transfers-table />
        </v-form>
      </div>
    </div>

    <div class="d-flex justify-center align-center vh-100" v-else>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>

    <template>
      <v-dialog v-model="depositDialog" @keydown.esc="closeDialogConfirm" persistent scrollable max-width="800">
        <v-form ref="form_deposit">
          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <span class="text-h5">Deposit / Receive</span>
              <v-btn depressed small fab @click="closeDialogConfirm"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="depositType"
                      :items="typeList"
                      item-text="text"
                      item-value="value"
                      label="Cash or Asset"
                      @change="handleDepositTypeChange"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="depositVia"
                      :items="viaList"
                      item-text="label"
                      item-value="value"
                      label="Via"
                      @change="enableDepositButton"
                    />
                  </v-col>
                </v-row>
              </v-container>
              <div v-if="depositVia && depositType.split('_')[0] === 'fiat'">
                <div v-if="depositVia === 'new-ach'">
                  <p class="mb-0 mt-5">ACH Details</p>
                  <v-divider />
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Label"
                          v-model="label"
                          hint="Add a custom label to this counterparty (optional)"
                          persistent-hint
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Name on Bank Account *"
                          v-model="accountName"
                          hint="Make sure it is exactly as it appears on your bank account"
                          persistent-hint
                          required
                          :rules="requireRule"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Account Number *" v-model="accountNumber" :rules="numberRule" required />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Routing Number *" v-model="routingNumber" :rules="routingRule" required />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <p class="mb-0">Account Type</p>
                        <v-radio-group v-model="checkType" class="mt-0">
                          <v-radio label="Personal" value="personal"></v-radio>
                          <v-radio label="Business" value="business"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <p class="mb-0">Account</p>
                        <v-radio-group v-model="accountType" class="mt-0">
                          <v-radio label="Checking" value="checking"></v-radio>
                          <v-radio label="Savings" value="savings"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <div v-if="depositVia !== 'new-wire'">
                  <p class="mb-0">Deposit Details</p>
                  <v-divider class="mb-3" />
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Amount *"
                          v-model="amount"
                          :rules="cashAmountRule"
                          type="number"
                          required
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field label="Special Instructions" v-model="instruction" :rules="instructionRule" />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </div>
              <div v-if="depositVia && depositType.split('_')[0] === 'fiat'">
                <div v-if="depositVia === 'new-wire'">
                  <div>
                    <p class="mb-0 mt-5">Domestic</p>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="my-2">
                            <div class="font-weight-bold">Depository Bank Name:</div>
                            <div>{{ wireInstructions['depository-bank-name'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Routing Number:</div>
                            <div>{{ wireInstructions['routing-number'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Credit To:</div>
                            <div>{{ wireInstructions['credit-to'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Address:</div>
                            <div>{{ wireInstructions['beneficiary-address'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Account Number:</div>
                            <div>{{ wireInstructions['account-number'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Reference:</div>
                            <div>
                              {{
                                wireInstructions['reference'] + ', ' + firstName + ' ' + lastName + ' ' + current.number
                              }}
                            </div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Bank Address:</div>
                            <div>{{ wireInstructions['bank-address'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Bank Phone:</div>
                            <div>{{ wireInstructions['bank-phone'] }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <div>
                    <p class="mb-0 mt-5">International</p>
                    <v-divider />
                    <v-container>
                      <v-row>
                        <v-col>
                          <div class="my-2">
                            <div class="font-weight-bold">Depository Bank Name:</div>
                            <div>{{ wireInstructionsIntl['depository-bank-name'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Routing Number:</div>
                            <div>{{ wireInstructionsIntl['routing-number'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">SWIFT Code:</div>
                            <div>{{ wireInstructionsIntl['swift-code'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Credit To:</div>
                            <div>{{ wireInstructionsIntl['credit-to'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Address:</div>
                            <div>{{ wireInstructionsIntl['beneficiary-address'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Account Number:</div>
                            <div>{{ wireInstructionsIntl['account-number'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Reference:</div>
                            <div>
                              {{
                                wireInstructionsIntl['reference'] +
                                ', ' +
                                firstName +
                                ' ' +
                                lastName +
                                ' ' +
                                current.number
                              }}
                            </div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Bank Address:</div>
                            <div>{{ wireInstructionsIntl['bank-address'] }}</div>
                          </div>
                          <div class="my-2">
                            <div class="font-weight-bold">Bank Phone:</div>
                            <div>{{ wireInstructionsIntl['bank-phone'] }}</div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                  <v-divider />
                  <div class="my-2">
                    <div class="font-weight-bold">Note:</div>
                    <div>
                      Your wire instructions might be different from the above. If you have any doubts or questions
                      please verify your instructions with your account representative.
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="depositVia === 'new-wallet' && depositType.split('_')[0] === 'assets'">
                <p class="mb-0 mt-5">Asset Details</p>
                <v-divider />
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="1"></v-col>
                    <v-col cols="12" sm="10">
                      <v-text-field
                        label="Label"
                        v-model="label"
                        hint="Add a custom label to this counterparty"
                        :rules="requireRule"
                      />
                      <v-checkbox v-model="specifyTax" label="Specify tax information" />
                      <div v-if="specifyTax">
                        <v-text-field label="Currency *" v-model="assetsCurrency" readonly />
                        <v-text-field label="Cost Basis *" v-model="assetsCost" :rules="amountRule" />
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="assetsAcquisition"
                              label="Acquisition Date *"
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              @blur="date = parseDate(assetsAcquisition)"
                              :rules="acquisitionRules"
                              @click:append="menu = true"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="1"></v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeDialogConfirm"> Cancel </v-btn>
              <v-btn
                color="primary"
                text
                @click="activateConfirmDepositDialog()"
                :loading="load"
                :disabled="disabledDepositButton"
              >
                Finish
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </template>

    <template>
      <v-dialog v-model="quoteDialog" @keydown.esc="quoteDialog = false" persistent max-width="800">
        <v-card class="position-relative">
          <v-card-title class="d-flex justify-space-between">
            <span class="text-h5">Buy / Sell</span>
            <v-btn depressed small fab @click="quoteDialog = false"><v-icon> mdi-close </v-icon></v-btn>
          </v-card-title>
          <!---------------------------------- Place Quote Preview -------------------------------------->
          <v-container v-if="placeQuotePage === false">
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Account" :value="firstName + ' ' + lastName + ' - ' + current.number" disabled />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Trade Account" value="Prime Trust Trade Acount" disabled />
              </v-col>
            </v-row>
            <v-form ref="form_quote_preview">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="quoteTransactionType"
                    :items="transactionType"
                    @change="transactionTypeChanged"
                    item-text="text"
                    item-value="value"
                    label="Transaction Type"
                    required
                    :rules="requireRule"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="quoteAssetID"
                    :items="assetsListSelectDS"
                    item-text="text"
                    item-value="value"
                    label="Asset"
                    required
                    :rules="requireRule"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    ref="quoteAmountHtml1"
                    label="Amount"
                    type="number"
                    v-model="quoteAmount"
                    :rules="
                      quoteAmountType === 'amount' || quoteAmountType === 'total-amount'
                        ? cashAmountRule
                        : quoteTransactionType === 'sell' ? assetsSellAmountRule : assetsAmountRule
                    "
                    :prefix="'$'"
                    required
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="quoteAmountType"
                    :items="quoteAmountTypesData"
                    item-text="text"
                    item-value="value"
                    label="Amount Type"
                    required
                    :rules="requireRule"
                    @change="validateQuoteAmount"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span style="color: red">Note:</span> Due to changing volatile prices, after submitting the
                  information above you will have only 10 seconds to review the purchase details before the confirmation
                  screen will expire. This is a requirement from Prime Trust.
                </v-col>
                <!-- <v-col>
                  <v-checkbox v-model="quoteHot" label="Access hot wallets from the quote provider." />
                </v-col> -->
              </v-row>
            </v-form>
          </v-container>
          <!---------------------- Place Quote Execute ------------------------->
          <v-container fluid v-if="placeQuotePage === true">
            <input ref="closeHack" style="width: 0px" />
            <v-form ref="form_quote_place">
              <v-row>
                <v-col cols="3">
                  <v-subheader>Price Per Unit:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Amount"
                    :value="quotePreviewResult.pricePerUnit"
                    disabled
                    prefix="$"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Unit Count:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field label="Quote ID" :value="quotePreviewResult.unitCount" disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Fee:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field label="Fee" :value="quotePreviewResult.fee" prefix="$" disabled></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Amount:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Amount"
                    :value="quotePreviewResult.baseAmount"
                    prefix="$"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Total Amount:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                    label="Total Amount"
                    :value="quotePreviewResult.totalAmount"
                    prefix="$"
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="3">
                  <v-subheader>Status:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <!-- <v-text-field label="Status" :value="quotePreviewResult.status" disabled></v-text-field> -->
                  <span
                    :class="
                      'mt-1 px-1 badge status-' +
                      (quotePreviewResult.status ? quotePreviewResult.status.toLowerCase() : '')
                    "
                  >
                    {{ quotePreviewResult.status }}
                  </span>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="3">
                  <v-subheader>Expires At:</v-subheader>
                </v-col>
                <v-col cols="9">
                  <v-datetime-picker label="Expires At" v-model="quoteExpiresAt" required> </v-datetime-picker>
                </v-col>
              </v-row> -->
            </v-form>
          </v-container>
          <v-card-actions :class="{ btnSticky: placeQuotePage }">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="clearQuoteDialog"> Cancel </v-btn>
            <template v-if="placeQuotePage === false">
              <v-btn color="primary" text @click="handlePreviewQuote()" :loading="load"> Preview > </v-btn>
            </template>
            <template v-if="placeQuotePage === true">
              <v-btn
                color="primary"
                text
                @click="handleQuotePlace"
                :loading="load"
                :disabled="!(placeQuoteTimeout > 0)"
              >
                Place
                <v-progress-circular
                  class="ml-1"
                  :rotate="-90"
                  :size="30"
                  :value="placeQuoteTimeoutValue"
                  color="primary"
                  >{{ placeQuoteTimeout }}</v-progress-circular
                >
              </v-btn>
            </template>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-dialog v-model="sendDialog" @keydown.esc="closeDialogConfirm" persistent max-width="800">
        <v-form ref="form_send">
          <v-card>
            <v-card-title class="d-flex justify-space-between">
              <span class="text-h5">Withdrawal / Send</span>
              <v-btn depressed small fab @click="closeDialogConfirm"><v-icon> mdi-close </v-icon></v-btn>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="sendType"
                      :items="typeList"
                      item-text="text"
                      item-value="value"
                      label="Cash or Asset"
                      @change="handleSendTypeChange"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select v-model="sendTo" :items="sendToList" item-text="label" item-value="value" label="To" />
                  </v-col>
                </v-row>
              </v-container>
              <div v-if="sendTo && sendType.split('_')[0] === 'fiat'">
                <v-container>
                  <v-row v-if="sendTo === 'new-ach'">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Label"
                        v-model="label"
                        hint="Add a custom label to this counterparty (optional)"
                        persistent-hint
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Name on Bank Account *"
                        v-model="accountName"
                        hint="Make sure it is exactly as it appears on your bank account"
                        persistent-hint
                        required
                        :rules="requireRule"
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Account Number *" v-model="accountNumber" :rules="numberRule" required />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Routing Number *" v-model="routingNumber" :rules="routingRule" required />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p class="mb-0">Check Type</p>
                      <v-radio-group v-model="checkType" class="mt-0">
                        <v-radio label="Personal" value="personal"></v-radio>
                        <v-radio label="Business" value="business"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <p class="mb-0">Account Type</p>
                      <v-radio-group v-model="accountType" class="mt-0">
                        <v-radio label="Checking" value="checking"></v-radio>
                        <v-radio label="Savings" value="savings"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
                <p class="mb-0">Send Details</p>
                <v-divider class="mb-3" />
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Amount *"
                        v-model="amount"
                        :rules="sendCashAmountRule"
                        type="number"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Reference" v-model="instruction" :rules="instructionRule" />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
              <div v-if="sendTo && sendType.split('_')[0] === 'assets'">
                <div v-if="sendTo === 'new-wallet'">
                  <p class="mb-0">Wallet Details</p>
                  <v-divider class="mb-3" />
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Label"
                          v-model="label"
                          hint="Add a custom label to this counterparty (optional)"
                          persistent-hint
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          label="Wallet Address *"
                          v-model="sendWalletAddress"
                          persistent-hint
                          required
                          :rules="requireRule"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
                <p class="mb-0">Transfer Details</p>
                <v-divider class="mb-3" />
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        label="Amount *"
                        v-model="amount"
                        :rules="sendAssetsAmountRule"
                        type="number"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field label="Reference" v-model="instruction" :rules="instructionRule" />
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closeDialogConfirm"> Cancel </v-btn>
              <v-btn color="primary" @click="activateConfirmSendDialog()" :loading="load" :disabled="!hasAssets">
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </template>

    <template>
      <v-dialog v-model="qrcodeDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <span class="text-h5">Asset Instructions</span>
          </v-card-title>
          <v-card-text>
            <p>
              Send only selected assets to this wallet address. Any other asset type sent may be lost and unrecoverable.
            </p>
            <qr-code :text="walletAddress" error-level="L" :size="150" class="d-flex justify-center" />
            <div class="mt-3 d-flex justify-space-between">
              <p class="text-subtitle-2">Wallet Address:</p>
              <div>
                <span v-if="copyTp" class="copy-tp">Copied !</span>
                <v-btn text icon @click="handleCopyAddress">
                  <v-icon small> mdi-content-copy </v-icon>
                </v-btn>
              </div>
            </div>
            <b>{{ walletAddress }}</b>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="qrcodeDialog = false"> CLOSE </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <ConfirmSendDialog
      ref="confirmSendDialogRef"
      :event="eventConfirmSendDialog"
      :loading="load"
      :data="{
        instruction: instruction,
        amount: amount,
        sendType: sendType,
        sendTo: sendTo,
        sendToList,
        newAch: {
          label,
          accountName,
          accountNumber,
          routingNumber,
          checkType,
          accountType,
        },
        newWallet: {
          label,
          sendWalletAddress,
        },
      }"
      @handleSend="handleSend"
    />

    <ConfirmDepositDialog
      ref="confirmDepositDialogRef"
      :event="eventConfirmDepositDialog"
      :loading="load"
      :data="{
        instruction: instruction,
        amount: amount,
        depositVia,
        depositType,
        viaList,
        newAch: {
          label,
          accountName,
          accountNumber,
          routingNumber,
          checkType,
          accountType,
        },
        newWallet: {
          label,
          sendWalletAddress,
        },
      }"
      @handleDeposit="handleDeposit"
    />
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import * as brnv from 'bank-routing-number-validator'
import TransfersTable from './tables/TransfersTable'
import VueQRCodeComponent from 'vue-qrcode-component'
import ConfirmDepositDialog from '../dialogs/ConfirmDepositDialog.vue'
import ConfirmSendDialog from '../dialogs/ConfirmSendDialog.vue'
import {
  tradeHistoryHeaders,
  assetHeaders,
  typeList as cashTypes,
  cashTypeList,
  transferType,
  transactionType,
  quoteAmountTypes,
  quoteAmountTypesSell,
} from '../../utils'
Vue.component('qr-code', VueQRCodeComponent)

export default Vue.extend({
  name: 'AccountInfo',
  components: { TransfersTable, ConfirmDepositDialog, ConfirmSendDialog },
  data() {
    return {
      eventConfirmSendDialog: '',
      eventConfirmDepositDialog: '',
      disabledDepositButton: true,
      typeList: [],
      cashTypeList,
      transactionType,
      quoteAmountTypes,
      quoteAmountTypesSell,
      quoteAmountTypesData: [],
      menu: null,
      load: false,
      tradeHistoryHeaders,
      assetHeaders,
      current: {},
      firstName: '',
      middleName: '',
      lastName: '',
      status: '',
      label: '',
      accountName: '',
      accountNumber: '',
      routingNumber: '',
      checkType: 'personal',
      accountType: 'checking',
      amount: '',
      instruction: '',
      paymentRef: '',
      organization: '',
      viaList: [],
      sendToList: [],
      loading: false,
      quoteDialog: false,
      tradeDialog: false,
      depositDialog: false,
      sendDialog: false,
      withdrawDialog: false,
      qrcodeDialog: false,
      depositType: null,
      sendType: '',
      sendTo: null,
      depositVia: null,
      specifyTax: false,
      assetsCurrency: 'US Dollar (USD)',
      assetsCost: null,
      assetsAcquisition: '',
      walletAddress: '',
      sendWalletAddress: '',
      copyTp: false,
      assetsListSelectDS: [],
      accountsSelectDS: [],
      // ---------------- Trading ------------------
      initiatorAssetID: null,
      initiatorAmount: 0.0,

      acceptorAccountID: null,
      acceptorCurrencyType: null,
      acceptorAmount: 0.0,
      // --------------------Quotes -----------------
      quoteAmountType: null, // amount, total-amount, unit-count
      placeQuotePage: false,
      quoteTransactionType: null,
      quoteAssetID: null,
      quoteAmount: null,
      quoteHot: false,
      quoteExpiresAt: null,
      placeQuoteTimeout: 10, // timeout by default - 10 sec
      placeQuoteTimeoutValue: 100, // is used for progress component
      minBTC: 0,
      requireRule: [(v) => !!v || 'This field is required'],
      amountRule: [
        (v) => !!v || 'Amount is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
        (v) => v <= 5000 || 'The max amount is 5,000',
        (v) => (v && v > 0) || 'Amount should be greater than 0',
      ],
      instructionRule: [
        (v) => (v ? v.length >= 3 || 'Min char length is 3' : true),
        (v) => (v ? v.length <= 60 || 'Max char length is 60' : true),
      ],
      numberRule: [
        (v) => !!v || 'This field is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
      ],
      cashAmountRule: [
        (v) => !!v || 'This field is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
        (v) => v <= 5000 || 'The max cash amount is $5,000',
        (v) => v >= 0.5 || 'The min cash amount is $0.5',
      ],
      assetsAmountRule: [],
      assetsSellAmountRule: [],
      routingRule: [
        (v) => !!v || 'This field is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
        (v) => v.length === 9 || 'Number must be 9 digits length',
        (v) => brnv.ABARoutingNumberIsValid(v) || 'is not a valid ABA routing number',
      ],
      acquisitionRules: [
        (v) => !!v || 'This field is required',
        (v) => new Date(v) < new Date() || 'cannot be in the future',
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    }
  },
  created() {
    this.getCurrentAccount({ id: this.$route.params.id }).then(() => {
      this.current = this.currentAccount
      this.setInitial(this.current)
    })
  },
  mounted() {
    this.$vuetify.theme.dark = this.user.user.darkmode
    this.getAccounts({
      pagination: { itemsPerPage: -1, page: 1 }, // Get all accounts for Select DS. Consider to impl. lazy loading.
    })
    this.quoteAmountTypesData = this.quoteAmountTypes
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    sendCashAmountRule() {
      let rules = this.cashAmountRule

      if (this.sendType.split('__')[0] === 'fiat') {
        const currency = this.sendType.split('__')[1].toUpperCase()
        if (this.totalBallance && this.totalBallance.cashTotals) {
          const balance = this.totalBallance.cashTotals[currency]
          if (currency && balance) {
            rules = rules.concat([
              (v) => v <= balance.total || `Insufficient account balance: ${balance.total} ${currency}`,
            ])
          } else {
            console.log(
              `Validator | Could not get currency/balance from type list, currency=${currency}, balance=${balance.total}`
            )
          }
        }
      }

      return rules
    },
    hasAssets() {
      if (this.sendType && this.sendType.split('__')[0] === 'assets') {
        const currency = this.sendType.split('__')[3].toUpperCase()
        const balance = this.totalBallance.assetsTotals[currency]
          ? this.totalBallance.assetsTotals[currency].available
          : 0
        return balance > 0
      }
      return true
    },
    sendAssetsAmountRule() {
      let rules = this.assetsAmountRule

      if (this.sendType.split('__')[0] === 'assets') {
        const currency = this.sendType.split('__')[3].toUpperCase()
        if (this.totalBallance && this.totalBallance.assetsTotals) {
          const balance = this.totalBallance.assetsTotals[currency]
          if (currency && balance) {
            rules = rules.concat([
              (v) => v <= balance.available || `Insufficient account balance: ${balance.available} ${currency}`,
            ])
          } else {
            console.log(
              `Validator | Could not get currency/balance from type list, currency=${currency}, balance=${balance.available}`
            )
          }
        }
      }

      return rules
    },
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      documents: 'accounts/documents',
      checks: 'accounts/checks',
      transfers: 'accounts/transfers',
      tradeContributions: 'accounts/tradeContributions',
      assetContributions: 'accounts/assetContributions',
      assetsList: 'accounts/assetsList',
      assetsMethod: 'accounts/assetsMethod',
      accounts: 'accounts/accounts',
      quotePreviewResult: 'accounts/quotePreviewResult',
      wireInstructions: 'accounts/getWireInstructions',
      wireInstructionsIntl: 'accounts/getWireInstructionsIntl',
      totalBallance: 'accounts/totalBallance',
      user: 'auth/user',
    }),
  },
  methods: {
    validateQuoteAmount() {
      const amount = this.quoteAmount
      this.quoteAmount = null
      setTimeout(() => {
        this.quoteAmount = amount
      }, 100)
    },
    getDate(date) {
      return date.replace('T', ' ').slice(0, 19)
    },
    getCurrency(value) {
      if (value < 0) {
        const absValue = Math.abs(value)
        const multiplier = Math.pow(10, 2)
        const round = (Math.round((+absValue + Number.EPSILON) * multiplier) / multiplier).toFixed(2)
        return `-$${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2 }).format(round)}`
      } else {
        const multiplier = Math.pow(10, 2)
        const round = (Math.round((+value + Number.EPSILON) * multiplier) / multiplier).toFixed(2)
        return `$${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2 }).format(round)}`
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    calculateAge(birthday) {
      const ageDifMs = Date.now() - birthday.getTime()
      const ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    setInitial(current) {
      this.firstName = current.name.split(' ')[0]
      this.middleName = current.name.split(' ')[1]
      this.lastName = current.name.split(' ')[2]
      this.paymentRef = current.paymentRef || ''
      this.organization = current.organizationLabel
      this.status = current.status.toLowerCase()
    },
    preAddress(str) {
      return `${str.slice(0, 6)}****${str.slice(-6)}`
    },
    enableDepositButton() {
      this.disabledDepositButton = false
    },
    handleDepositTypeChange() {
      this.depositVia = null
      const depositTypeGroup = this.depositType.split('__')[0]
      if (depositTypeGroup === 'fiat') {
        this.viaList = this.transfers.reduce(
          (list, trans) => [
            ...list,
            { value: trans.transferID, label: `${trans.label || ''} (ACH ****${trans.last4})` },
          ],
          [{ header: 'ACH' }, { value: 'new-ach', label: 'New ACH' }]
        )
        this.viaList = this.viaList.concat([{ header: 'Wire' }, { value: 'new-wire', label: 'New Wire' }])
      } else {
        this.viaList = this.assetsMethod
          .filter((method) => method.typeID === this.depositType.split('__')[2] && method.walletType === 'incoming')
          .reduce(
            (list, method) => [
              ...list,
              { value: method.walletAddress, label: `${method.label} (${this.preAddress(method.walletAddress)})` },
            ],
            [{ value: 'new-wallet', label: 'New Wallet Address' }]
          )
        this.depositVia = this.viaList[0].value
        this.enableDepositButton()
      }
    },
    handlePreviewQuote() {
      if (this.$refs.form_quote_preview.validate()) {
        this.load = true
        const params = {
          accountID: this.current.accountID,
          assetID: this.quoteAssetID.split('__')[2],
          ...(!this.quoteHot ? { hot: false } : { hot: true }),
          transactionType: this.quoteTransactionType,
        }
        if (this.quoteAmountType === 'amount') {
          params.amount = this.quoteAmount
        }
        if (this.quoteAmountType === 'total-amount') {
          params.totalAmount = this.quoteAmount
        }
        if (this.quoteAmountType === 'unit-count') {
          params.unitCount = this.quoteAmount
        }
        this.quotePreview(params)
          .then((result) => {
            this.load = false
            if ((+result.totalAmount > this.totalBallance.cashTotals.USD.total) && this.quoteTransactionType === 'buy') {
              this.$store.dispatch('alert/error', 'Insufficient funds')
            } else {
              this.placeQuotePage = true
              this.quoteExpiresAt = result.expiresAt

              const diff = new Date(this.quoteExpiresAt).getTime() - Date.now()
              if (diff < 0) {
                this.$refs.form_quote_preview.reset()
                console.log(`quote preview | quote has been expired [diff=${diff}]`)
                return
              }
              this.placeQuoteTimeout = Math.round(diff / 1000)
              const sub = this.placeQuoteTimeoutValue / this.placeQuoteTimeout

              const placeQuoteTimeoutInterval = setInterval(() => {
                if (this.placeQuoteTimeout <= 0) {
                  if (this.$refs.form_quote_preview) this.$refs.form_quote_preview.reset()
                  clearInterval(placeQuoteTimeoutInterval)
                  return
                }
                this.placeQuoteTimeoutValue -= sub
                this.placeQuoteTimeout -= 1
                this.$refs.closeHack.focus()
              }, 1000)
              setTimeout(() => {
                if (this.$refs.form_quote_preview) this.$refs.form_quote_preview.reset()
                this.clear()
              }, 2000)
            }
          })
          .catch(() => {
            this.load = false
            this.$refs.form_quote_preview.reset()
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
      }
    },
    handleQuotePlace() {
      this.load = true
      const params = {
        accountID: this.current.accountID,
        expiresAt: this.quoteExpiresAt,
        quoteID: this.quotePreviewResult.quoteID,
        transactionType: this.quoteTransactionType,
        wdFee: this.quotePreviewResult.wdFee,
      }
      this.quotePlace(params)
        .then(() => {
          this.load = false
          this.$refs.form_quote_place.reset()
          this.quoteDialog = false
          this.placeQuotePage = false
          setTimeout(() => {
            this.clear()
          }, 2000)
          this.reload()
        })
        .catch(() => {
          this.load = false
          this.placeQuotePage = false
          this.$refs.form_quote_place.reset()
          setTimeout(() => {
            this.clear()
          }, 2000)
        })
    },
    clearQuoteDialog() {
      this.quoteDialog = false
      this.placeQuotePage = false
      this.$refs.form_quote_preview.reset()
    },
    handleTrade() {
      if (this.$refs.form_trade.validate()) {
        this.load = true
        const params = {
          initiator: {
            accountID: this.current.accountID,
            assetID: this.initiatorAssetID.split('__')[2],
            marketAmount: this.initiatorAmount,
            amount: this.initiatorAmount,
          },
          acceptor: {
            accountID: this.acceptorAccountID,
            currencyType: this.acceptorCurrencyType.split('__')[1].toUpperCase(),
            amount: this.acceptorAmount,
          },
        }
        this.executeTrade(params)
          .then(() => {
            this.load = false
            this.$refs.form_trade.reset()
            this.tradeDialog = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
          .catch(() => {
            this.load = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
      }
    },
    closeDialogConfirm() {
      this.sendDialog = false
      this.depositDialog = false
      this.instruction = ''
      this.amount = ''
      this.depositVia = null
      this.depositType = null
      this.sendType = null
      this.sendTo = null
      this.$refs.form_deposit.reset()
      this.assetsCurrency = 'US Dollar (USD)'
      this.checkType = 'personal'
      this.accountType = 'checking'
    },
    activateConfirmDepositDialog() {
      if (this.$refs.form_deposit.validate()) {
        const [type, currency] = this.depositType.split('__')
        if (type === 'fiat' && this.depositVia === 'new-wire') {
          this.handleDeposit()
          return
        }
        if (type === 'assets' && currency === 'bitcoin') {
          this.handleDeposit()
          return
        }
        this.eventConfirmDepositDialog = 'handleDeposit'
        this.$refs.confirmDepositDialogRef.open = true
      }
    },
    activateConfirmSendDialog() {
      if (this.$refs.form_send.validate()) {
        this.eventConfirmSendDialog = 'handleSend'
        this.$refs.confirmSendDialogRef.open = true
      }
    },
    handleDeposit() {
      if (this.$refs.form_deposit.validate()) {
        const depositTypeGroup = this.depositType.split('__')[0]
        if (depositTypeGroup === 'fiat') {
          if (this.depositVia === 'new-ach') {
            this.load = true
            const param = {
              label: this.label,
              accountName: this.accountName,
              accountNumber: this.accountNumber,
              routingNumber: this.routingNumber,
              checkType: this.checkType,
              accountType: this.accountType,
              amount: this.amount,
              instruction: this.instruction,
            }
            this.makeDepositMethod({
              ...param,
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then(() => {
                this.load = false
                this.$refs.form_deposit.reset()
                this.depositDialog = false
                setTimeout(() => {
                  this.resetDepositData()
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
              })
          } else if (this.depositVia === 'new-wire') {
            this.load = false
            this.$refs.form_deposit.reset()
            this.depositDialog = false
            setTimeout(() => {
              this.resetDepositData()
              this.clear()
            }, 2000)
          } else {
            this.load = true
            this.makeDeposit({
              amount: this.amount,
              instruction: this.instruction,
              transferID: this.depositVia,
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then(() => {
                this.load = false
                this.$refs.form_deposit.reset()
                this.depositDialog = false
                setTimeout(() => {
                  this.resetDepositData()
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.resetDepositData()
                  this.clear()
                }, 2000)
              })
          }
        } else {
          if (this.depositVia === 'new-wallet') {
            this.load = true
            this.makeAssetMethod({
              label: this.label,
              assetsCurrency: 'USD',
              ...(this.assetsCost && { assetsCost: this.assetsCost }),
              ...(this.assetsAcquisition && { assetsAcquisition: this.assetsAcquisition }),
              type: this.depositType.split('__')[1],
              typeID: this.depositType.split('__')[2],
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then((res) => {
                this.load = false
                this.$refs.form_deposit.reset()
                this.depositDialog = false
                this.qrcodeDialog = true
                this.walletAddress = res.walletAddress
                setTimeout(() => {
                  this.resetDepositData()
                  this.clear()
                }, 2000)
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.resetDepositData()
                  this.clear()
                }, 2000)
              })
          } else {
            this.depositDialog = false
            this.qrcodeDialog = true
            this.walletAddress = this.depositVia
          }
        }
      }
    },
    resetDepositData() {
      this.assetsCurrency = 'US Dollar (USD)'
      this.checkType = 'personal'
      this.accountType = 'checking'
    },
    handleSend() {
      if (this.$refs.form_send.validate()) {
        const sendTypeGroup = this.sendType.split('__')[0]
        if (sendTypeGroup === 'fiat') {
          if (this.sendTo === 'new-ach') {
            this.load = true
            const param = {
              label: this.label,
              accountName: this.accountName,
              accountNumber: this.accountNumber,
              routingNumber: this.routingNumber,
              checkType: this.checkType,
              accountType: this.accountType,
              amount: this.amount,
              instruction: this.instruction,
            }
            this.makeSendMethod({
              ...param,
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then(() => {
                this.load = false
                this.$refs.form_send.reset()
                this.sendDialog = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
              })
          } else {
            this.load = true
            this.makeSend({
              amount: this.amount,
              instruction: this.instruction,
              transferID: this.sendTo,
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then(() => {
                this.load = false
                this.$refs.form_send.reset()
                this.sendDialog = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
              })
          }
        } else {
          if (this.sendTo === 'new-wallet') {
            this.load = true
            this.makeAssetSendMethod({
              label: this.label,
              amount: this.amount,
              type: this.sendType.split('__')[1],
              typeID: this.sendType.split('__')[2],
              accountID: this.current.accountID,
              contactID: this.current.contactID,
              walletAddress: this.sendWalletAddress,
            })
              .then(() => {
                this.load = false
                this.$refs.form_send.reset()
                this.sendDialog = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
              })
          } else {
            this.load = true
            this.makeAssetSend({
              amount: this.amount,
              assetsID: this.sendTo.split('__')[1],
              transferID: this.sendTo.split('__')[0],
              accountID: this.current.accountID,
              contactID: this.current.contactID,
            })
              .then(() => {
                this.load = false
                this.$refs.form_send.reset()
                this.sendDialog = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
                this.reload()
              })
              .catch(() => {
                this.load = false
                setTimeout(() => {
                  this.clear()
                }, 2000)
              })
          }
        }
      }
    },
    handleSendTypeChange() {
      this.sendTo = null
      const sendTypeGroup = this.sendType.split('__')[0]
      if (sendTypeGroup === 'fiat') {
        this.sendToList = this.transfers.reduce(
          (list, trans) => [
            ...list,
            { value: trans.transferID, label: `${trans.label || ''} (ACH ****${trans.last4})` },
          ],
          [{ value: 'new-ach', label: 'New ACH' }]
        )
      } else {
        this.sendToList = this.assetsMethod
          .filter((method) => method.typeID === this.sendType.split('__')[2] && method.walletType === 'outgoing')
          .reduce(
            (list, method) => [
              ...list,
              {
                value: `${method.methodID}__${method.typeID}`,
                label: `${method.label} (${this.preAddress(method.walletAddress)})`,
              },
            ],
            [{ value: 'new-wallet', label: 'New Wallet Address' }]
          )
      }
    },
    handleWithdrawal() {
      if (this.$refs.form_send.validate()) {
        if (this.sendTo === 'new-ach') {
          this.load = true
          const param = {
            label: this.label,
            accountName: this.accountName,
            accountNumber: this.accountNumber,
            routingNumber: this.routingNumber,
            checkType: this.checkType,
            accountType: this.accountType,
            amount: this.amount,
            instruction: this.instruction,
          }
          this.makeSendMethod({
            ...param,
            accountID: this.current.accountID,
            contactID: this.current.contactID,
          })
            .then(() => {
              this.load = false
              this.$refs.form_send.reset()
              this.withdrawDialog = false
              setTimeout(() => {
                this.clear()
              }, 2000)
              this.reload()
            })
            .catch(() => {
              this.load = false
              setTimeout(() => {
                this.clear()
              }, 2000)
            })
        } else {
          this.load = true
          this.makeSend({
            amount: this.amount,
            instruction: this.instruction,
            transferID: this.sendTo,
            accountID: this.current.accountID,
            contactID: this.current.contactID,
          })
            .then(() => {
              this.load = false
              this.$refs.form_send.reset()
              this.withdrawDialog = false
              setTimeout(() => {
                this.clear()
              }, 2000)
              this.reload()
            })
            .catch(() => {
              this.load = false
              setTimeout(() => {
                this.clear()
              }, 2000)
            })
        }
      }
    },
    handleCopyAddress() {
      navigator.clipboard.writeText(this.walletAddress).then(() => {
        this.copyTp = true
        this.$refs.form_deposit.reset()
        this.assetsCurrency = 'US Dollar (USD)'
        setTimeout(() => {
          this.copyTp = false
        }, 2000)
      })
    },
    transactionTypeChanged(event) {
      if (event === 'sell') {
        this.quoteAmountTypesData = this.quoteAmountTypesSell
      } else {
        this.quoteAmountTypesData = this.quoteAmountTypes
      }
      this.quoteAmountType = null
    },
    reload() {
      // this.$router.go(this.$router.currentRoute)
      location.reload(true)
    },
    ...mapActions('accounts', [
      'getAccounts',
      'getCurrentAccount',
      'makeDepositMethod',
      'makeDeposit',
      'makeSendMethod',
      'makeSend',
      'makeAssetMethod',
      'makeAssetSendMethod',
      'makeAssetSend',
      'executeTrade',
      'quotePreview',
      'quotePlace',
    ]),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    totalBallance() {
      for (const item in this.totalBallance.assetsTotals) {
        if (item === 'BTC') {
          this.minBTC = this.totalBallance.assetsTotals[item].available
        }
      }
      this.assetsAmountRule = [
        (v) => !!v || 'This field is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
        (v) => v <= 5000 || 'The max asset amount is 5,000',
      ]
      this.assetsSellAmountRule = [
        (v) => !!v || 'This field is required',
        (v) => !Number.isNaN(Number(v)) || 'This filed should be number',
        () => !(this.minBTC <= 0) || 'You don\'t have any assets of this kind',
        (v) => v <= 5000 || 'The max asset amount is 5,000',
        (v) => v >= 0.000001 || 'The min value is 0.000001',
        (v) => {
          if (v <= 0 || v >= this.minBTC) return `The max asset amount is ${this.minBTC}`
          return true
        },
      ]
    },
    assetsList() {
      if (this.assetsList.length > 0) {
        this.assetsListSelectDS = this.assetsList
          .filter((list) => transferType.includes(list.attributes['asset-transfer-types'][0]) && (this.totalBallance.assetsTotals.BTC ? this.totalBallance.assetsTotals.BTC.available : 0) > 0)
          .reduce(
            (newArray, list) => [
              ...newArray,
              {
                text: `${list.attributes.label} (${list.attributes['unit-name']})`,
                value: `assets__${list.attributes['asset-transfer-types'][0]}__${list.id}__${list.attributes['unit-name']}`,
              },
            ],
            []
          )
        this.typeList = [...cashTypes, ...this.assetsListSelectDS]
      }
    },
    accounts() {
      this.accountsSelectDS = this.accounts
        .filter((acc) => acc.status === 'opened' && acc.accountID !== this.$route.params.id)
        .map((acc) => {
          return {
            text: `${acc.firstName} ${acc.lastName} (${acc.number})`,
            value: acc.accountID,
            disabled: acc.accountID === this.$route.params.id,
          }
        })
    },
    date() {
      this.assetsAcquisition = this.formatDate(this.date)
    },
  },
})
</script>
<style>
.color-red {
  color: red;
}
.copy-tp {
  background-color: rgba(0, 0, 0, 0.589);
  padding: 3px;
  border-radius: 5px;
  color: #fff;
}

.cursor {
  cursor: pointer;
}
.btnSticky {
  width: 100%;
  position: sticky;
  bottom: 0px;
  background: white;
  right: 43px;
}
</style>
