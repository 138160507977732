import { get, omitBy, isNil, pick } from 'lodash'
import authHeader from '../helpers/authHeader'
import axiosClient from 'axios'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'

const axios = applyRefreshTokenInteceptor(
  axiosClient.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = {
  editPortfolio: {},
  portfolios: [],
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
}

const getters = {
  portfolios: (state) => state.portfolios,
  pagination: (state) => state.pagination,
  getEditPortfolio: (state) => state.editPortfolio,
}

const actions = {
  getPortfolios({ commit, state }, { pagination }) {
    return axios
      .get('/v1/portfolio', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          ...pagination,
        },
      })
      .then((res) => {
        const { results: portfolios } = res.data
        const pagination = pick(res.data, 'page', 'limit', 'totalPages', 'totalResults', 'sortBy')
        commit('setPortfolios', { portfolios, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
      })
  },
  addPortfolio({ dispatch }, portfolio) {
    return axios
      .post('/v1/portfolio/', omitBy(portfolio, isNil), {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getPortfolios', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
        dispatch('alert/success', 'Portfolio has been created', { root: true })
      })
      .catch((err) => {
        let { message } = get(err, 'response.data')
        if (message.match(/E11000/)) {
          message = `Portfolio ${portfolio.name} already exists`
        }
        dispatch('alert/error', message, { root: true })
      })
  },
  updatePortfolio({ dispatch }, { portfolioId, portfolio }) {
    return axios
      .patch(`/v1/portfolio/${portfolioId}`, omitBy(portfolio, isNil), {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getPortfolios', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
        dispatch('alert/success', 'Portfolio has been updated', { root: true })
      })
      .catch((err) => {
        dispatch('alert/error', get(err, 'response.data'), { root: true })
      })
  },
  addSymbolToPortfolio({ commit, dispatch }, { portfolioId, symbolId }) {
    return axios
      .post(
        `/v1/portfolio/${portfolioId}/items`,
        [
          {
            symbol: symbolId,
          },
        ],
        {
          headers: {
            ...authHeader(),
          },
        }
      )
      .then((res) => {
        if (res.data) {
          commit('setEditPortfolio', res.data)
        }

        dispatch('alert/success', 'Symbol has been added', { root: true })
      })
      .catch((err) => {
        dispatch('alert/error', get(err, 'response.data'), { root: true })
      })
  },
  getPortfolioById({ commit }, portfolioId) {
    return axios
      .get(`/v1/portfolio/${portfolioId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        commit('setEditPortfolio', res.data)
      })
  },
  resetEditPortfolio({ commit }) {
    commit('setEditPortfolio', { items: [] })
  },
  removeSymbolItem({ commit, dispatch }, { portfolioId, symbolItemId }) {
    return axios
      .delete(`/v1/portfolio/${portfolioId}/items/${symbolItemId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        commit('setEditPortfolio', res.data)
        dispatch('alert/success', 'Symbol Item has been deleted', { root: true })
      })
      .catch((err) => {
        dispatch('alert/error', get(err, 'response.data'), { root: true })
      })
  },
  deletePortfolioById({ dispatch }, portfolioId) {
    return axios
      .delete(`/v1/portfolio/${portfolioId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getPortfolios', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
        dispatch('alert/success', 'Portfolio has been deleted', { root: true })
      })
      .catch((err) => {
        dispatch('alert/error', get(err, 'response.data'), { root: true })
      })
  },
}

const mutations = {
  setPortfolios(state, { portfolios, pagination }) {
    state.portfolios = portfolios
    state.pagination = pagination
  },
  setEditPortfolio(state, portfolio) {
    state.editPortfolio = Object.assign({}, portfolio)
  },
}

export const portfolios = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
