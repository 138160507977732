import axios from 'axios'
import { pick, get } from 'lodash'
import authHeader from '../helpers/authHeader'
import moment from 'moment'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'

const client = applyRefreshTokenInteceptor(
  axios.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = () => ({
  editUser: {},
  curPortfolioOrder: {},
  users: [],
  pagination: {
    page: 0,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
  roles: [],
})

const getters = {
  allUsers: (state) => state.users,
  getEditUser: (state) => state.editUser,
  getRoles: (state) => state.roles,
  totalResults: (state) => state.pagination.totalResults,
}

const actions = {
  getUsers({ commit, state }, pagination) {
    return client
      .get('/v1/users/admin', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          populate: 'application',
          ...pagination,
        },
      })
      .then((res) => {
        const { results: users } = res.data
        const pagination = pick(res.data, 'page', 'limit', 'totalPages', 'totalResults', 'sortBy')

        users.forEach((user) => {
          Object.defineProperty(user, 'fullName', {
            get: () => {
              if (user.personal && (user.personal.firstName || user.personal.lastName)) {
                return `${user.personal.firstName || ''} ${user.personal.lastName || ''}`
              }
              return ''
            },
          })
          user.apexFormStatus = get(user, 'application.status', '')
          user.testProp = 'test'
          user.apexFormId = get(user, 'applciation.id_apex', '123')
          Object.defineProperty(user, 'apexFormUid', {
            get: () => {
              return get(user, 'applciation.uid', '')
            },
          })
          Object.defineProperty(user, 'createdAtFormatted', {
            get: () => {
              return moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')
            },
          })
        })
        commit('setUsers', { users, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
      })
  },
  createNewUser({ dispatch }, user) {
    return client
      .post('/v1/users/admin', user, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getUsers')
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data.message'))
      })
  },
  updateUser({ commit }, { userId, user }) {
    return client
      .patch(`/v1/users/admin/${userId}`, user, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: user } = res

        commit('setEditUser', user)
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data.message'))
      })
  },
  getEditUser({ commit }, userId) {
    return client
      .get(`/v1/users/admin/${userId}?populate=application`, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: user } = res

        user.registrationDate = moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')
        user.latestActivityDate = moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')

        commit('setEditUser', user)
      })
  },
  deleteUser({ dispatch }, userId) {
    return client
      .delete(`/v1/users/admin/${userId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getUsers')
      })
  },
  deleteApplicationForm({ dispatch }, formId) {
    return client
      .delete(`/v1/apex/applications/${formId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', 'Application has been deleted', { root: true })
        dispatch('getEditUser', state.editUser.id)
      })
      .catch((err) => {
        const { message } = get(err, 'response.data')
        dispatch('alert/error', message, { root: true })
      })
  },
  syncApexApplication({ dispatch, state }) {
    const curUser = state.editUser
    if (!curUser.application) {
      dispatch('alert/warn', "User doesn't have application", { root: true })
      return
    }

    return client
      .post(`/v1/users/${curUser.id}/applications/${curUser.application.id}/update`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', 'Application has been synced', { root: true })
        dispatch('getEditUser', state.editUser.id)
      })
      .catch((err) => {
        const { message } = get(err, 'response.data')
        dispatch('alert/error', message, { root: true })
      })
  },
  setCurPortfolioOrder({ commit }, portfolioOrder) {
    commit('setCurPortfolioOrder', portfolioOrder)
  },
  getRoles({ commit }) {
    return client
      .get('/v1/users/roles', {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: roles } = res
        commit('setRoles', roles.data)
      })
  },
}

const mutations = {
  setUsers(state, { users, pagination }) {
    state.users = users
    state.pagination = pagination
  },
  setEditUser(state, user) {
    state.editUser = user
  },
  setCurPortfolioOrder(state, portfolioOrder) {
    state.curPortfolioOrder = portfolioOrder
  },
  setRoles(state, roles) {
    state.roles = roles
  },
}

export const capitalUsers = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
