<template>
  <div>
    <v-data-table :headers="headers" :items="contacts" class="elevation-1">
      <template v-slot:[`item.name`]="{ item }">
        <a>
          <div @click="editItem(item)">
            {{ item.name }}
          </div>
        </a>
      </template>
      <template v-slot:[`item.deactive`]="{ item }">
        <div class="badge status-deactive" v-if="item.deactive">
          {{ activeState(item.deactive) }}
        </div>
        <div class="badge status-active" v-else>
          {{ activeState(item.deactive) }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn color="error" x-small @click="deleteItem(item)" v-if="edit === 'true' && item.deactive !== true && item.relationID">
          DEACTIVE
        </v-btn>
        <v-icon @click="deleteItem(item)" v-if="edit !== 'true'"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <template>
      <v-dialog v-model="confirm" max-width="390">
        <v-card>
          <v-card-title class="text-h5 justify-center">
            <v-icon large color="blue darken-2"> mdi-exclamation-thick</v-icon>
          </v-card-title>
          <v-card-title class="text-h5 justify-center"> Are you sure? </v-card-title>
          <v-card-text class="text-center" v-if="edit === 'true'"> This account will be inactive. </v-card-text>
          <v-card-text class="text-center" v-else> This account will be remove. </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" outlined @click="handleCancelClick"> cancel </v-btn>
            <v-btn color="primary" :loading="load" :disabled="load" @click="handleAgreeClick"> yes, agree </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <template>
      <contact-edit :edit="edit" :visible="openEditContactDialog" @close="openEditContactDialog = false" />
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ContactEdit from './ContactEdit.vue'
import { contactTableHead } from '../../utils'
export default {
  components: { ContactEdit },
  data() {
    return {
      headers: contactTableHead,
      openEditContactDialog: false,
      confirm: false,
      load: false,
      id: '',
      contactID: '',
    }
  },
  props: ['edit'],
  computed: {
    ...mapGetters({
      contacts: 'accounts/contacts',
    }),
  },
  methods: {
    activeState(deactive) {
      if (deactive) {
        return 'Deactivated'
      } else {
        return 'Active'
      }
    },
    editItem(item) {
      if (!item.deactive) {
        this.setEditContact({ contact: item })
        this.openEditContactDialog = true
      }
    },
    deleteItem(item) {
      this.confirm = true
      this.id = item._id || item.index
      this.contactID = item.contactID
    },
    handleCancelClick() {
      this.confirm = false
      this.contactID = ''
      this.id = ''
    },
    handleAgreeClick() {
      if (this.id) {
        if (this.contactID) {
          this.load = true
          this.deactiveContact({ id: this.id, contactID: this.contactID })
            .then(() => {
              this.getCurrentAccount({ id: this.$route.params.id }).then(() => {
                this.load = false
                this.confirm = false
                this.contactID = ''
                this.id = ''
              }).catch(() => {
                this.load = false
                this.confirm = false
                this.contactID = ''
                this.id = ''
              })
            })
            .catch(() => {
              this.confirm = false
              this.load = false
            })
        } else {
          this.deleteCreatedContact({ index: this.id })
          this.confirm = false
        }
      }
    },
    ...mapActions('accounts', ['setEditContact', 'deactiveContact', 'deleteCreatedContact', 'getCurrentAccount']),
  },
}
</script>
<style scoped>
.badge {
  border: 1px solid;
  border-radius: 5px;
  width: 90px;
  text-align: center;
}
.status-active {
  color: #49cc90;
  border-color: #49cc90;
  background-color: #49cc901a;
}
.status-deactive {
  color: #f93e3e;
  border-color: #f93e3e;
  background-color: #f93e3e1a;
}
.status-denied {
  color: #f93e3e;
  border-color: #f93e3e;
  background-color: #f93e3e1a;
}
</style>
