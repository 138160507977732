
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

export default Vue.extend({
  name: 'PortfolioSymbols',

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    portfolioId: {
      type: String,
    },
  },
  data: () => ({
    items: [],
    quantity: '',
    symbolId: '',
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'symbol.id',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'symbol.name',
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'symbol.description',
      },
      {
        text: 'Company',
        align: 'start',
        sortable: false,
        value: 'symbol.company',
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
  }),
  methods: {
    uniqueSymbolRule(value) {
      if (this.portfolio && this.portfolio.items) {
        const result = this.portfolio.items.map((item) => item.symbol.id).includes(value)
        if (result) {
          return 'Symbol is already present in portfolio'
        } else {
          return true
        }
      }
      return true
    },
    deleteSymbolItem(item: any) {
      console.log(`delete symbol item=${JSON.stringify(item)}`)
    },
    async addSymbolToPortfolio() {
      if (this.$refs.form.validate()) {
        // const { id: portfolioId } = this.portfolio
        const { symbolId } = this
        await this.$store.dispatch('portfolios/addSymbolToPortfolio', { portfolioId: this.portfolioId, symbolId })
        this.clear()
      }
    },
    async removeSymbolItem({ id: symbolItemId }) {
      await this.$store.dispatch('portfolios/removeSymbolItem', { portfolioId: this.portfolio.id, symbolItemId })
      this.clear()
    },
    ...mapActions('alert', ['clear']),
  },
  computed: {
    ...mapState('portfolios', ['editPortfolio']),
    symbols() {
      const allSymbols = this.$store.state.symbols.symbols
      return allSymbols
    },
    symbolItems() {
      if (this.editPortfolio && this.editPortfolio.items) {
        return this.editPortfolio.items
      }
      return []
    },
    portfolio() {
      return this.editPortfolio || {}
    },
  },
  watch: {
    show(visible) {
      if (visible) {
        console.log('Dialog was opened!')
        // this.$store.dispatch('symbols/getSymbols')
        this.$store.dispatch('portfolios/getPortfolioById', this.portfolioId)
      } else {
        this.quantity = ''
        this.$refs.form.reset()
        this.$store.dispatch('portfolios/resetEditPortfolio')
        console.log('Dialog was closed!')
      }
    },
  },
  mounted() {
    // this.$store.dispatch('symbols/getSymbols')
    this.$store.dispatch('portfolios/getPortfolioById', this.portfolioId)
  },
})
