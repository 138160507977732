
import Vue from 'vue'
import AccountsList from '../components/accounts/AccountsList.vue'

export default Vue.extend({
  name: 'Accounts',
  components: {
    AccountsList,
  },
})
