import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import { permissions, checkPermission, getRole } from '../utils/permissions'
import Home from '../views/Home.vue'
import Portfolios from '../views/Portfolios.vue'
import ManageUsers from '../views/ManageUsers.vue'
import CapitalUsers from '../views/CapitalUsers.vue'
import CapitalUserEdit from '../views/CapitalUserEdit.vue'
import Login from '../views/Login.vue'
// import Register from '../views/Register.vue'
import OrdersView from '../views/OrdersView.vue'
import Accounts from '../views/Accounts.vue'
import AccountCreate from '../views/AccountCreate.vue'
import AccountEdit from '../views/AccountEdit.vue'
import Account from '../views/Account.vue'
import Layout from '../views/Layout.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import Management from '../views/Management.vue'
import ManagementEdit from '../views/ManagementEdit.vue'
import MFA from '../views/MFA.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: Register,
  // },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/mfa',
    name: 'MFA',
    component: MFA,
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
      },
      {
        path: '/manage-users',
        name: 'ManageUsers',
        component: ManageUsers,
      },
      {
        path: '/portfolios',
        name: 'Portfolios',
        component: Portfolios,
      },
      {
        path: '/orders',
        name: 'Orders',
        component: OrdersView,
      },
      {
        path: '/accounts',
        name: 'Accounts',
        component: Accounts,
        meta: {
          prime: true,
        },
      },
      {
        path: '/add-account',
        name: 'AccountAdd',
        component: AccountCreate,
        meta: {
          prime: true,
        },
      },
      {
        path: '/account/:type/:id',
        name: 'AccountEdit',
        component: AccountEdit,
        meta: {
          prime: true,
        },
      },
      {
        path: '/account/:id',
        name: 'Account',
        component: Account,
        meta: {
          prime: true,
        },
      },
      {
        path: '/capital-user/:id',
        name: 'CapitalUserEdit',
        component: CapitalUserEdit,
      },
      {
        path: '/capital-users',
        name: 'CapitalUsers',
        component: CapitalUsers,
      },
      {
        path: '/management',
        name: 'Management',
        component: Management,
      },
      {
        path: '/management/:id',
        name: 'ManagementEdit',
        component: ManagementEdit,
        meta: {
          show: checkPermission([permissions.manageFinancialReps.key]),
        },
      },
    ],
  },
  { path: '*', redirect: '/home' },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  if (
    (to.name === 'Accounts' &&
      !checkPermission([permissions.getAllPTAccounts.key, permissions.getOwnedPTAccounts.key])) ||
    (to.name === 'AccountAdd' && !checkPermission([permissions.managePTAccounts.key])) ||
    (to.name === 'AccountEdit' && !checkPermission([permissions.managePTAccounts.key])) ||
    (to.name === 'Account' &&
      !checkPermission([permissions.getAllPTAccounts.key, permissions.getOwnedPTAccounts.key])) ||
    (to.name === 'CapitalUserEdit' && !checkPermission([permissions.manageAdminUsers.key])) ||
    (to.name === 'CapitalUsers' && !checkPermission([permissions.getAdminUsers.key])) ||
    (to.name === 'Management' && !checkPermission([permissions.getFinancialReps.key])) ||
    (to.name === 'ManagementEdit' && !checkPermission([permissions.manageFinancialReps.key])) ||
    (to.name === 'ManageUsers' && getRole() !== 'super')
  ) {
    return router.push('/')
  }
  const isPrime = to?.matched[0]?.meta?.prime || to?.meta?.prime
  const user = store.getters['auth/user']
  if (isPrime && !user.user.primeTrust) router.push('/home')
  next()
})

export default router
