import { get } from 'lodash'
import authHeader from '../helpers/authHeader'
import axiosClient from 'axios'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'

const axios = applyRefreshTokenInteceptor(
  axiosClient.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = {}

// const getters = {}

const actions = {
  async syncPortfolioOrder({ dispatch }, portfolioOrderId) {
    await axios
      .post(`/portfolio-order/${portfolioOrderId}/sync`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', 'Portfolio has been created', { root: true })
      })
      .catch((err) => {
        const { message } = get(err, 'response.data')
        dispatch('alert/error', message, { root: true })
      })
  },
}

// const mutations = {}

export const common = {
  namespaced: true,
  state,
  actions,
  // getters,
  // mutations,
}
