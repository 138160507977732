export const passwordRules = [
  (v) => !!v || 'Password is required',
  (v) => !(v.length < 12) || 'Password should be at least 12 symbols length',
  (v) => !!v.match(/[a-z]/) || 'Password should contain at least one lower case letter',
  (v) => !!v.match(/[A-Z]/) || 'Password should contain at least one upper case letter',
  (v) => !!v.match(/\d+/) || 'Password should contain at least one number',
  (v) => /(?=.*[^a-zA-Z0-9])/.test(v) || 'Password must contain at least one special character',
  (v) => !(v.length > 15) || 'Password should be less then 16 symbols length',
  (v) => !/(?=.*\s)/.test(v) || 'Password can not contain empty spaces',
]

export const isFileTypeAllowed = (type) =>
  ['application/pdf', 'image/bmp', 'image/heif', 'image/heic', 'image/jpeg', 'image/jpg', 'image/png', 'image/tiff', 'image/webp']
    .includes(type)
