
import Vue from 'vue'
import UserPortfolioStocks from './CapitalUserPortfolioStocks.vue'

export default Vue.extend({
  components: { UserPortfolioStocks },
  name: 'UserPortfoliosV2',

  props: {
    portfolios: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: String,
    },
  },
  data: () => ({
    dialogShowBasketOrders: false,
    headers: [
      {
        text: 'User Portfolio ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Portfolio ID',
        align: 'start',
        sortable: false,
        value: 'portfolio',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Is Fully Acquired',
        align: 'start',
        sortable: false,
        value: 'isPartial',
      },
      {
        text: 'Amount',
        align: 'start',
        sortable: false,
        value: 'amount',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Updated At',
        align: 'start',
        sortable: false,
        value: 'updatedAt',
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
    curPortfolio: { stocks: [] },
  }),
  computed: {},
  methods: {
    showStocks(item: any) {
      this.curPortfolio = item
      this.dialogShowBasketOrders = true
    },
    showOrders(item) {
      this.$router.push({ name: 'orders', query: { userId: this.userId, userPortfolioId: item.id } })
    },
    formatCurrency(value) {
      return '$' + value
    },
    getStatusColor(status) {
      switch (status) {
        case 'Pending':
          return 'orange'
        case 'Rejected':
          return 'red'
        case 'Acquired':
        case 'Sold':
          return 'blue'
        default:
          return 'grey'
      }
    },
  },
})
