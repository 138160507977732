import axiosClient from 'axios'
import { get, omitBy, isNil, pick, keyBy, merge, values, } from 'lodash'
import authHeader from '../helpers/authHeader'

import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'

const axios = applyRefreshTokenInteceptor(
  axiosClient.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = {
  symbols: [],
  symbolsPrices: [],
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
}

const getters = {
  pagination: (state) => state.pagination,
}

const actions = {
  getSymbols({ commit, state }, { pagination }) {
    return axios
      .get('/v1/symbols', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          ...pagination,
        },
      })
      .then((res1) => {
        const { results: symbols } = res1.data
        return axios
          .get('/v1/symbols/latestPrices', {
            headers: {
              ...authHeader(),
            },
          })
          .then((res2) => {
            const symbolsPrices = res2.data

            const _symbolsPrices = symbolsPrices.map(item => ({
              symbol: item.symbol,
              expectedPx: item.expectedPx,
              priceUpdatedAt: item.updatedAt,
            }))

            const merged = merge(keyBy(symbols, 'name'), keyBy(_symbolsPrices, 'symbol'))
            const symbolsWithPrices = values(merged)

            commit('setSymbols', { symbols: symbolsWithPrices, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
          })
          .catch((err) => {
            throw new Error(get(err, 'response.data'))
          })
      })
  },
  deleteSymbol({ dispatch }, symbolId) {
    return axios
      .delete(`/v1/symbols/${symbolId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getSymbols', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data'))
      })
  },
  getSymbolsPrices({ commit }) {
    return axios
      .get('/v1/symbols/latestPrices', {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        commit('setSymbolsPrices', res.data)
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data'))
      })
  },
  addSymbol({ dispatch }, symbol) {
    return axios
      .post('/v1/symbols/', omitBy(symbol, isNil), {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getSymbols', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
      })
      .catch((err) => {
        let { message } = get(err, 'response.data')
        if (message.match(/E11000/)) {
          message = `Symbol ${symbol.name} already exists`
        }
        throw new Error(message)
      })
  },
  updateSymbol({ dispatch }, { symbolId, symbol }) {
    return axios
      .patch(`/v1/symbols/${symbolId}`, omitBy(symbol, isNil), {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getSymbols', {
          pagination: {
            limit: state.pagination.limit,
            page: 1,
          }
        })
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data'))
      })
  },
}

const mutations = {
  setSymbols(state, { symbols, pagination }) {
    state.symbols = symbols
    state.pagination = pagination
  },
  setSymbolsPrices(state, symbolsPrices) {
    state.symbolsPrices = symbolsPrices
  },
}

export const symbols = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
