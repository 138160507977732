
import Vue from 'vue'
import AccountIndividualEdit from '../components/accounts/AccountIndividualEdit.vue'
import AccountCompanyEdit from '../components/accounts/AccountCompanyEdit.vue'

export default Vue.extend({
  name: 'AccountEdit',
  components: { AccountIndividualEdit, AccountCompanyEdit },
  data() {
    return {
      accountType: null,
    }
  },
  created() {
    this.accountType = this.$route.params.type
  },
})
