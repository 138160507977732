export const colors = ['red', 'pink', 'purple', 'indigo', 'blue', 'cyan', 'teal', 'green', 'orange', 'brown']

export const navbarItems = [
  {
    title: 'Manage Users',
    icon: 'mdi-account-supervisor',
    route: '/manage-users',
    key: 'manage',
  },
  {
    title: 'Admin Users',
    icon: 'mdi-account',
    route: '/capital-users',
    key: 'admins',
  },
  {
    title: 'Financial Reps',
    icon: 'mdi-account-supervisor',
    route: '/management',
    key: 'reps',
    isAdmin: true,
  },
  {
    title: 'PrimeTrust Accounts',
    icon: 'mdi-bank',
    route: '/accounts',
    key: 'ptAccounts',
    isPrime: true,
  },
  {
    title: 'Portfolios',
    icon: 'mdi-chart-arc',
    route: '/portfolios',
    key: 'portafolios',
  },
  {
    title: 'Orders',
    icon: 'mdi-view-headline',
    route: '/orders',
    key: 'orders',
  },
]

export const accountTableHead = [
  { text: 'Account ID', value: 'number', sortable: false },
  { text: 'Type', value: 'type', sortable: false },
  { text: 'Name', value: 'name', sortable: false },
  { text: 'Email', value: 'email', sortable: false },
  { text: 'Balances', value: 'totalBallance', sortable: false },
  { text: 'Status', value: 'status', sortable: false },
  { text: 'Actions', value: 'actions', sortable: false },
]

export const subTypeList = [
  'Alternative Trading System',
  'Automated Teller Machine',
  'Bank',
  'Broker Dealer',
  'Cannabis Related Business',
  'Cash Intensive Business',
  'Centralized Exchange',
  'Crowdfunding Portal',
  'Decentralized Autonomous Organization',
  'Decentralized Exchange',
  'Digital Wallet',
  'Gaming',
  'Investment Company',
  'Issuer',
  'Lender',
  'Marketplace',
  'Merchant',
  'Miner or Validator',
  'Money Services Business',
  'Non Fungible Token',
  'Nonprofit',
  'Over the Counter Exchange',
  'Payment Intermediary',
  'Pooled Fund',
  'Registered Investment Advisor',
  'Stablecoin',
  'Staking and Yield',
]

export const contactTableHead = [
  { text: 'Full Name', value: 'name' },
  { text: 'Label', value: 'label' },
  { text: 'Email', value: 'email' },
  { text: 'Active', value: 'deactive' },
  { text: 'Actions', value: 'actions', sortable: false },
]

export const documentTypes = [
  {
    label: "Driver's License",
    value: 'drivers_license',
  },
  {
    label: 'Government ID',
    value: 'government_id',
  },
  {
    label: 'Passport',
    value: 'passport',
  },
  {
    label: 'Residence Permit',
    value: 'residence_permit',
  },
  {
    label: 'Utility Bill',
    value: 'utility_bill',
  },
  {
    label: 'Other',
    value: 'other',
  },
]

export const primeUserTableHeader = [
  {
    text: 'User ID',
    align: 'start',
    value: 'userID',
  },
  {
    text: 'User Name',
    align: 'start',
    value: 'fullname',
  },
  {
    text: 'Email',
    align: 'start',
    value: 'email',
  },
  {
    text: 'Date registered',
    align: 'start',
    value: 'createdAt',
  },
  {
    text: 'Actions',
    value: 'actions',
    sortable: false,
  },
]

export const tradeHistoryHeaders = [
  { text: 'Trade ID', value: 'tradeID', sortable: false },
  // { text: 'Initiator ID', value: 'initiatorID', sortable: false },
  { text: 'Acceptor ID', value: 'acceptorID', sortable: false },
  { text: 'Initiator Amount', value: 'initiatorAmount', sortable: false },
  { text: 'Acceptor Amount', value: 'acceptorAmount', sortable: false },
  { text: 'Status', value: 'status', sortable: true },
  { text: 'Expires At', value: 'expiresAt', sortable: false },
  { text: 'Issue Date', value: 'createdAt', sortable: true },
]

export const assetHeaders = [
  { text: 'Transfer ID', value: 'transferID', sortable: false },
  { text: 'Contribution ID', value: 'contributionID', sortable: false },
  { text: 'Contact ID', value: 'contactID', sortable: false },
  { text: 'Amount', value: 'amount', sortable: false },
  { text: 'Expected Amount', value: 'expectedAmount', sortable: false },
  { text: 'Status', value: 'status', sortable: true },
  { text: 'Created At', value: 'createdAt', sortable: true },
]

export const cashTypeList = [{ text: 'US Dollar (USD)', value: 'fiat__usd' }]

export const typeList = [
  { header: 'Fiat' },
  { text: 'US Dollar (USD)', value: 'fiat__usd' },
  { divider: true },
  { header: 'Assets' },
]

export const quoteAmountTypes = [
  { header: 'USD' },
  { text: 'Add Fee To Amount', value: 'amount' },
  { text: 'Include Fee In Amount', value: 'total-amount' },
  { divider: true },
  { header: 'Unit' },
  { text: 'Unit Count', value: 'unit-count' },
]

export const quoteAmountTypesSell = [
  { header: 'USD' },
  { text: 'Add Fee To Amount', value: 'amount' },
  { divider: true },
  { header: 'Unit' },
  { text: 'Unit Count', value: 'unit-count' },
]

export const transactionType = [
  { text: 'Buy', value: 'buy' },
  { text: 'Sell', value: 'sell' },
]

export const transferType = [
  'ethereum',
  'bitcoin',
  'bitcoin_cash',
  'bitcoin_sv',
  'litecoin',
  'dash',
  'xrp',
  'eos',
  'stellar',
  'terra',
  'digital_asset',
]
