<template>
  <v-container fluid>
    <div v-if="current.createdAt">
      <v-row class="header">
        <v-col class="d-flex flex-row justify-space-between" cols="12">
          <div class="d-flex align-center">
            <v-card-title>Edit Account (Company)</v-card-title>
            <span :class="'ml-2 px-1 badge status-' + current.status.toLowerCase()">
              {{ current.status }}
            </span>
          </div>
          <div>
            <v-btn @click="goBack" color="white" outlined :loading="load" :disabled="load" class="mr-10 primary--text">Cancel & Return To Accounts</v-btn>
            <v-btn class="mx-2" color="primary" :loading="load" :disabled="load" @click="onSave"> Save </v-btn>
            <v-btn class="mx-2" color="error" outlined @click="handleCloseAccount" v-if="current.status === 'opened'">
              Close
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="py-10">
        <v-form ref="form">
          <v-row>
            <v-col class="px-10 d-flex flex-row justify-sm-start justify-space-between" cols="12">
              <div class="mx-4">
                <div class="font-weight-bold">Account Number:</div>
                <div>{{ current.number }}</div>
              </div>
              <div class="mx-4">
                <div class="font-weight-bold">Registration date:</div>
                <div>{{ current.createdAt.slice(0, 10) }}</div>
              </div>
              <div class="mx-4 d-sm-block d-none">
                <div class="font-weight-bold">CIP:</div>
                <span :class="'ml-2 px-1 badge status-' + current.cip.toLowerCase()">
                  {{ current.cip }}
                </span>
              </div>
              <div class="mx-4 d-sm-block d-none">
                <div class="font-weight-bold">AML:</div>
                <span :class="'ml-2 px-1 badge status-' + current.aml.toLowerCase()">
                  {{ current.aml }}
                </span>
              </div>
            </v-col>
            <v-col class="px-10 d-flex flex-row d-sm-none justify-space-between" cols="12">
              <div class="mx-4">
                <div class="font-weight-bold">CIP:</div>
                <span :class="'ml-2 px-1 badge status-' + current.cip.toLowerCase()">
                  {{ current.cip }}
                </span>
              </div>
              <div class="mx-4">
                <div class="font-weight-bold">AML:</div>
                <span :class="'ml-2 px-1 badge status-' + current.aml.toLowerCase()">
                  {{ current.aml }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-8 mt-4" />
          <v-row class="mt-10 px-5">
            <v-col class="px-5 py-0" cols="12" sm="6">
              <v-text-field
                v-model="name"
                class="my-1"
                dense
                outlined
                label="Name *"
                :rules="requireRule"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="px-5 py-0" cols="12" sm="6">
              <v-autocomplete
                v-model="subType"
                :items="subTypeList"
                label="Entity Sub Type"
                dense
                outlined
                disabled
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col class="px-5 py-0" cols="12" sm="6">
              <v-text-field
                v-model="email"
                class="my-1"
                dense
                outlined
                label="Email *"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="px-5 py-0" cols="12" sm="6">
              <v-text-field
                v-model="phone"
                class="my-1"
                dense
                outlined
                type="tel"
                label="Phone *"
                hint="+19424633336"
                :rules="phoneRule"
                @keyup="validateString"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-8" />
          <v-row class="mt-10 px-5">
            <v-col class="px-5" cols="12" sm="6">
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Tax ID Number/SSN *"
                v-model="taxID"
                :rules="taxIDRules"
                disabled
              ></v-text-field>
              <v-autocomplete
                :items="countryList"
                @change="setTaxCountry"
                item-text="label"
                item-value="value"
                label="Tax Country *"
                dense
                outlined
                v-model="taxCountry"
                :rules="requireRule"
                disabled
              ></v-autocomplete>
              <v-autocomplete
                :items="taxRegionList"
                v-if="taxCountry === 'US'"
                item-text="label"
                item-value="value"
                label="Tax State *"
                dense
                outlined
                v-model="taxRegion"
                :rules="requireRule"
              ></v-autocomplete>
              <v-autocomplete
                v-model="regionOfFormation"
                :items="regionOfFormationList"
                item-text="label"
                item-value="value"
                label="Region of formation *"
                dense
                outlined
                :rules="requireRule"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-5" cols="12" sm="6">
              <v-autocomplete
                :items="countryList"
                @change="setCountry"
                item-text="label"
                item-value="value"
                label="Country *"
                dense
                outlined
                v-model="country"
                :rules="requireRule"
              ></v-autocomplete>
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Street Address *"
                v-model="street1"
                :rules="requireRule"
              ></v-text-field>
              <v-text-field class="my-1" dense outlined label="Street Address 2" v-model="street2"></v-text-field>
              <v-text-field
                v-model="city"
                class="my-1"
                dense
                outlined
                label="City *"
                :rules="requireRule"
              ></v-text-field>
              <v-autocomplete
                :items="regionList"
                item-text="label"
                item-value="value"
                label="State/Province/Region"
                dense
                outlined
                v-model="region"
              ></v-autocomplete>
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Postal Code *"
                v-model="postalCode"
                :rules="postalRules"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-8" />
          <v-row class="mx-5">
            <v-col>
              <v-btn color="primary" @click="contactDialog = true"> + Add Related Contact </v-btn>
              <contact-list edit="true" />
              <contact-add
                edit="true"
                :accountID="current.accountID"
                :visible="contactDialog"
                @close="contactDialog = false"
              />
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-divider class="mb-8" />
      <div class="subtitle-1">Uploaded Documents</div>
      <v-row class="mb-5 mx-3">
        <v-col class="mt-5" cols="12" md="6">
          <v-file-input counter show-size outlined @change="onUploadDocument" label="Upload Document"
            accept="application/pdf, image/bmp, image/heif, image/heic, image/jpeg, image/jpg, image/png, image/tiff, image/webp"
            multiple
            :rules="fileRules"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <v-data-table
            :headers="headers"
            :items="documents"
            :options.sync="pagination"
            :server-items-length="totalResults"
            class="elevation-1 pt-list"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider class="mb-8" />
      <div class="subtitle-1">Document Checks</div>
      <v-form ref="kycform">
        <v-row class="mb-15 mx-3 mt-5">
          <v-col cols="12" md="4">
            <v-select
              :items="documentTypes"
              item-text="label"
              item-value="value"
              label="Document Type *"
              dense
              outlined
              v-model="docType"
              :rules="requireRule"
            ></v-select>
            <v-text-field
              dense
              outlined
              label="Specify Document Type*"
              v-if="docType === 'other'"
              v-model="otherDocType"
              :rules="requireRule"
            ></v-text-field>
            <v-select
              :items="countryList"
              item-text="label"
              item-value="value"
              label="Document Country *"
              dense
              outlined
              v-model="docCountry"
              :rules="requireRule"
            ></v-select>
            <v-select
              :items="allDocuments"
              item-text="fileName"
              item-value="documentID"
              label="Select Document *"
              no-data-text="Upload documents to get them listed here"
              dense
              outlined
              v-model="docID"
              :rules="requireRule"
            ></v-select>
            <v-select
              :items="allDocuments"
              item-text="fileName"
              item-value="documentID"
              no-data-text="Upload documents to get them listed here"
              label="Select Back of Document *"
              dense
              outlined
              v-if="docType === 'drivers_license' || docType === 'residence_permit' || docType === 'government_id'"
              v-model="docBackID"
              :rules="requireRule"
            ></v-select>
            <v-btn class="mt-5" color="primary" @click="onCheckDocument" :loading="loading" :disabled="loading" block>
              Document Check
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Document Type</th>
                    <th class="text-left">File Name</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Identity</th>
                    <th class="text-left">Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in processedChecks" :key="item.name">
                    <td>{{ item.documentType }}</td>
                    <td>
                      <a :href="item.fileUrl" target="_black">
                        {{ item.fileName }}
                      </a>
                      <br />
                      <span v-if="item.backFileID">
                        <a :href="item.backFileUrl" target="_black">
                          {{ item.backFileName }}
                        </a>
                      </span>
                    </td>
                    <td>{{ item.country }}</td>
                    <td>
                      <div class="badge status-pending" v-if="item.status === 'pending'">
                        {{ item.status }}
                      </div>
                      <div class="badge status-opened" v-else-if="item.status === 'approved'">
                        {{ item.status }}
                      </div>
                      <div class="badge status-closed" v-else>
                        {{ item.status }}
                      </div>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox v-model="item.proofIdentity" disabled></v-simple-checkbox>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox v-model="item.proofAddress" disabled></v-simple-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-form>
      <v-dialog v-model="uploading" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Uploading ...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex justify-center align-center vh-100" v-else>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-dialog v-model="confirmDialog" max-width="390">
      <v-card>
        <v-card-title class="text-h5 justify-center">
          <v-icon large color="blue darken-2"> mdi-exclamation-thick</v-icon>
        </v-card-title>
        <v-card-title class="text-h5 justify-center"> Are you sure? </v-card-title>
        <v-card-text class="text-center"> This account will be closed. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="handleCancelClick"> cancel </v-btn>
          <v-btn color="primary" :loading="load" :disabled="load" @click="handleAgreeClick"> yes, agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { validate } from 'postal-codes-js'
import ContactList from './ContactList.vue'
import ContactAdd from './ContactAdd.vue'
import countries from '../../utils/country'
import { subTypeList, documentTypes } from '../../utils'
import { isFileTypeAllowed } from '../../config/validationRules'
export default Vue.extend({
  name: 'AccountCompanyEdit',
  components: { ContactList, ContactAdd },
  data() {
    return {
      headers: [
        { text: 'File Type', value: 'fileType', sortable: false },
        { text: 'File Name', value: 'fileName', sortable: false },
        { text: 'Uploaded Date', value: 'updatedAt', sortable: false },
      ],
      search: '',
      pagination: {
        itemsPerPage: 5,
        page: 1,
      },
      contactDialog: false,
      confirmDialog: false,
      load: false,
      current: [],
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      taxID: '',
      taxCountry: '',
      taxRegion: '',
      regionOfFormation: '',
      country: '',
      street1: '',
      street2: '',
      city: '',
      region: '',
      postalCode: '',
      docType: '',
      otherDocType: '',
      docCountry: '',
      docID: '',
      docBackID: '',
      uploading: false,
      loading: false,
      documentTypes: documentTypes,
      countryList: [],
      taxRegionList: [],
      regionList: [],
      subTypeList: [],
      regionOfFormationList: [],
      requireRule: [(v) => !!v || 'This field is required'],
      fileRules: [
        value => !value || value.every(file => file.size <= 5242880) || 'File size should be less than 5 MB',
        value => !value || value.every(file => isFileTypeAllowed(file.type)) || 'Only images and PDF documents are allowed',
        value => !value || value.length <= 5 || 'Only 5 documents upload at once are allowed',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      phoneRule: [
        (v) => !!v || 'Phone is required',
        (v) => (/^(\+?\d{1,3})?(-)?\d{9}$/.test(v) || /^(\+?\d{1,2})?(-)?\d{10}$/.test(v)) || 'Phone must be valid: +19424633336',
      ],
      taxIDRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          this.taxCountry !== 'US' ||
          (this.taxCountry === 'US' && v.length === 9) ||
          'Tax ID Number is not a valid US SSN or ITIN',
      ],
      postalRules: [(v) => this.country === '' || validate(this.country, v) || 'Postal Code must be valid'],
    }
  },
  created() {
    this.getCurrentAccount({ id: this.$route.params.id }).then(() => {
      this.current = this.currentAccount
      this.setCountry(this.current.country)
      this.setTaxCountry(this.current.taxCountry)
      this.setInitial(this.current)
    })
    this.getCurrentAccountDocuments({
      id: this.$route.params.id,
      pagination: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.page,
      }
    })
    this.getCurrentAccountAllDocuments({
      id: this.$route.params.id,
    })
  },
  mounted() {
    this.subTypeList = subTypeList
    this.countryList = countries.map((country) => {
      return {
        label: `${country.countryName} (${country.countryShortCode})`,
        value: country.countryShortCode,
      }
    })
    let USRegions = []
    countries.map((country) => {
      if (country.countryShortCode === 'US') {
        USRegions = country.regions
      }
    })
    this.regionOfFormationList = USRegions.map((USRegion) => {
      return {
        label: `${USRegion.name} (${USRegion.shortCode})`,
        value: USRegion.shortCode,
      }
    })
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      allDocuments: 'accounts/allDocuments',
      documents: 'accounts/documents',
      checks: 'accounts/checks',
    }),
    processedChecks() {
      return this.checks.map((check) => {
        return {
          ...check,
          documentType: (documentTypes.find(doc => doc.value === check.documentType) || {}).label,
        }
      })
    },
    totalResults() {
      return this.$store.state.accounts.documentsPagination.totalResults
    },
    params() {
      return {
        ...this.pagination,
        query: this.search
      }
    },
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    validateString() {
      const regex = new RegExp('^[0-9+-]*$')
      if (regex.test(this.phone)) {
        if (this.phone.length > 14) {
          this.phone = this.phone.substring(0, this.phone.length - 1)
        }
      } else {
        this.phone = this.phone.substring(0, this.phone.length - 1)
      }
    },
    setTaxCountry(value) {
      if (value === 'US') {
        let taxRegions = []
        countries.map((country) => {
          if (country.countryShortCode === value) {
            taxRegions = country.regions
          }
        })
        this.taxRegionList = taxRegions.map((taxRegion) => {
          return {
            label: `${taxRegion.name} (${taxRegion.shortCode})`,
            value: taxRegion.shortCode,
          }
        })
      }
    },
    setCountry(value) {
      let regions = []
      countries.map((country) => {
        if (country.countryShortCode === value) {
          regions = country.regions
        }
      })
      this.regionList = regions.map((region) => {
        return {
          label: `${region.name} (${region.shortCode})`,
          value: region.shortCode,
        }
      })
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    setInitial(current) {
      this.name = current.name
      this.subType = current.subType
      this.email = current.email
      this.phone = current.phone
      this.taxID = current.taxID
      this.taxCountry = current.taxCountry
      this.taxRegion = current.taxRegion
      this.regionOfFormation = current.regionOfFormation
      this.country = current.country
      this.street1 = current.street1
      this.street2 = current.street2
      this.city = current.city
      this.region = current.region
      this.postalCode = current.postalCode
    },
    setRefresh() {
      this.name = ''
      this.subType = ''
      this.email = ''
      this.phone = ''
      this.taxID = ''
      this.taxCountry = ''
      this.taxRegion = ''
      this.regionOfFormation = ''
      this.country = ''
      this.street1 = ''
      this.street2 = ''
      this.city = ''
      this.region = ''
      this.postalCode = ''
      this.$refs.form.reset()
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.load = true
        const param = {
          name: this.name,
          subType: this.subType,
          email: this.email,
          phone: this.phone,
          taxID: this.taxID,
          taxCountry: this.taxCountry,
          taxRegion: this.taxRegion,
          regionOfFormation: this.regionOfFormation,
          country: this.country,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          region: this.region,
          postalCode: this.postalCode,
        }
        this.updateCompanyAccount({
          ...param,
          id: this.current._id,
          contactID: this.current.contactID,
        })
          .then(() => {
            this.load = false
            setTimeout(() => {
              this.clear()
            }, 2000)
            this.$router.push({ name: 'Accounts' })
          })
          .catch(() => {
            this.load = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
      }
    },
    onUploadDocument(files) {
      if (files.length) {
        if (files.some(file => file.size > 5242880) ||
            files.some(file => !isFileTypeAllowed(file.type)) ||
            files.length > 5) {
          return
        }
        const { current, uploadDocument } = this
        this.uploading = true
        uploadDocument({
          files,
          contactID: current.contactID,
        })
          .then(() => {
            this.getCurrentAccountDocuments({
              id: this.$route.params.id,
              pagination: {
                limit: this.pagination.itemsPerPage,
                page: this.pagination.page,
              }
            }).then(() => {
              this.uploading = false
            }).catch(() => {
              this.uploading = false
            })
          })
          .catch(() => {
            this.uploading = false
          })
      }
    },
    onCheckDocument() {
      if (this.$refs.kycform.validate()) {
        this.loading = true
        const param = {
          docType: this.docType,
          docCountry: this.docCountry,
          otherDocType: this.otherDocType,
          docID: this.docID,
          docBackID: this.docBackID,
        }
        this.documentCheck({
          ...param,
          contactID: this.current.contactID,
        })
          .then(() => {
            this.loading = false
            this.docType = null
            this.otherDocType = null
            this.docCountry = null
            this.docID = null
            this.docBackID = null
            this.$refs.kycform.reset()
            this.clear()
          })
          .catch(() => {
            this.loading = false
            this.clear()
          })
      }
    },
    getDate(date) {
      return date.replace('T', ' ').slice(0, 19)
    },
    handleCloseAccount() {
      this.confirmDialog = true
    },
    handleCancelClick() {
      this.confirmDialog = false
    },
    handleAgreeClick() {
      this.load = true
      this.deleteAccount({ id: this.current._id })
        .then(() => {
          this.confirmDialog = false
          this.load = false
          this.$router.push({ name: 'AccountList' })
        })
        .catch(() => {
          this.confirmDialog = false
          this.load = false
        })
    },
    ...mapActions('accounts', [
      'getCurrentAccount',
      'getCurrentAccountDocuments',
      'getCurrentAccountAllDocuments',
      'updateCompanyAccount',
      'deleteAccount',
      'uploadDocument',
      'documentCheck',
    ]),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    params: {
      handler() {
        this.getCurrentAccountDocuments({
          id: this.$route.params.id,
          pagination: {
            limit: this.pagination.itemsPerPage,
            page: this.pagination.page,
          }
        })
      },
      deep: true
    }
  },
})
</script>
