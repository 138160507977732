
import Vue from 'vue'
import PortfolioSymbols from './PortfolioSymbols.vue'
import { mapActions } from 'vuex'
import { pick } from 'lodash'
import moment from 'moment'

export default Vue.extend({
  name: 'PortfolioList',
  components: { PortfolioSymbols },
  data: () => ({
    pagination: {},
    editDialogTitle: 'New Portfolio',

    isAllocationFormValid: true,
    allocations: [],
    newAllocationItemName: '',
    newAllocationItemValue: '',

    editPortfolio: {
      allocations: [],
    } as any,

    disabledSave: false,

    editDialog: false,
    canEditPortfolio: false,
    portfolioTypes: ['EqualWeightingCash'],
    headersBase: [
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code',
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Min Amount',
        align: 'end',
        sortable: false,
        value: 'minAmount',
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Created At',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Is Active',
        value: 'isActive',
        align: 'start',
        sortable: false,
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
    dialogPortfolioSymbols: false,
    portfolioSymbolItems: [],

    showIds: false,

    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) => (v && v.length <= 100) || 'Field must be less than 100 characters',
    ],
    codeRules: [
      (v: string) => !!v || 'Code is required',
      (v: string) => (v && v.length <= 100) || 'Field must be less than 100 characters',
    ],

    amountRules: [
      (v: number) => !!v || 'Amount is required',
      (v: number) => (v && v > 0) || 'Amount should be greater than 0',
    ],

    items: [
      { name: 'Active', value: true },
      { name: 'Inactive', value: false },
    ],

    editedIndex: -1,
    deleteDialog: false,
    deletePortfolioDialogMessage: 'Are you sure you want to delete portfolio',
  }),
  methods: {
    inputItemValueRule(v) {
      const sum = this.editPortfolio.allocations.reduce((sum, item) => {
        return (sum += item.value)
      }, 0)

      if (v > 100 - sum) {
        return `The allocation value should be less then ${100 - sum}`
      }
      return true
    },
    getRule(index, v) {
      const sum = this.editPortfolio.allocations.reduce((sum, item, _index) => {
        if (_index === index) {
          return sum
        }
        return (sum += item.value)
      }, 0)

      if (v > 100 - sum) {
        return 'The allocations should not be greater then 100%'
      }
      return true
    },
    addNewAllocationItem() {
      if (this.$refs.allocationForm.validate()) {
        this.editPortfolio.allocations.push({
          name: this.newAllocationItemName,
          value: this.newAllocationItemValue,
        })
      }
    },
    deleteAllocationItem(index) {
      this.editPortfolio.allocations.splice(index, 1)
    },
    showDeletePortfolioDialog(item: any) {
      this.editedIndex = this.portfolios.indexOf(item)
      this.editPortfolio = Object.assign(this.editPortfolio, item)
      this.deletePortfolioDialogMessage = `Are you sure you want to delete portfolio "${item.name}"?`
      this.deleteDialog = true
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    showPortfolioSymbols(portfolio: any) {
      this.portfolioSymbolItems = portfolio.items
      this.editPortfolio = Object.assign(this.editPortfolio, portfolio)
      this.dialogPortfolioSymbols = true
    },
    async deletePortfolio() {
      await this.$store.dispatch('portfolios/deletePortfolioById', this.editPortfolio.id)
      this.deleteDialog = false
      this.clear()
    },
    showNewPortfolioDialog() {
      this.editDialogTitle = 'New Portfolio'
      this.canEditPortfolio = true
      this.editDialog = true
      try {
        this.$refs.form.reset()
      } catch (e) {}
      this.editPortfolio = {
        allocations: []
      }
    },
    showEditPortfolioDialog(item: any) {
      this.editDialogTitle = 'Edit Portfolio'
      this.canEditPortfolio = false
      this.editPortfolio = Object.assign(this.editPortfolio, item)
      this.editDialog = true
    },
    closeEditDialog() {
      this.canEditPortfolio = true
      this.editDialog = false
      this.$refs.form.reset()
    },
    formatDate(dateString: string) {
      if (dateString) {
        return moment(String(dateString)).format('DD/MM/YYYY hh:mm')
      }
      return ''
    },
    async updateState({ portfolioId, isActive }) {
      await this.$store.dispatch('portfolios/updatePortfolio', {
        portfolioId,
        portfolio: { isActive },
      })
      this.clear()
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editPortfolio.id) {
          await this.$store.dispatch('portfolios/updatePortfolio', {
            portfolioId: this.editPortfolio.id,
            portfolio: {
              ...pick(
                this.editPortfolio,
                'name',
                'code',
                'minAmount',
                'description',
                'details',
                'auditory',
                'type',
                'purchaseAnyAmount',
                'allocations'
              ),
              // allocations: this.allocations,
            },
          })
        } else {
          await this.$store.dispatch('portfolios/addPortfolio', this.editPortfolio)
        }

        this.editDialog = false
        this.$refs.form.reset()
        this.clear()
      }
    },
    getColor(value) {
      return value ? 'green' : 'orange'
    },
    ...mapActions('alert', ['clear']),
  },
  computed: {
    portfolios() {
      return this.$store.state.portfolios.portfolios
    },
    totalResults() {
      return this.$store.state.portfolios.pagination.totalResults
    },
    params() {
      return {
        ...this.pagination,
        query: this.search
      }
    },
    maxLargeCapValue() {
      const result =
        100 -
        (this.editPortfolio.allocation.largeCapCore +
          this.editPortfolio.allocation.largeCapGrowth +
          this.editPortfolio.allocation.internationLargeCapBlend +
          this.editPortfolio.allocation.other)
      return result
    },
    maxLargeCapCore() {
      const result =
        100 -
        (this.editPortfolio.allocation.largeCapValue +
          this.editPortfolio.allocation.largeCapGrowth +
          this.editPortfolio.allocation.internationLargeCapBlend +
          this.editPortfolio.allocation.other)
      return result
    },
    maxLargeCapGrowth() {
      const result =
        100 -
        (this.editPortfolio.allocation.largeCapValue +
          this.editPortfolio.allocation.largeCapCore +
          this.editPortfolio.allocation.internationLargeCapBlend +
          this.editPortfolio.allocation.other)
      return result
    },
    maxInternationLargeCapBlend() {
      const result =
        100 -
        (this.editPortfolio.allocation.largeCapValue +
          this.editPortfolio.allocation.largeCapCore +
          this.editPortfolio.allocation.largeCapGrowth +
          this.editPortfolio.allocation.other)
      return result
    },
    maxOther() {
      const result =
        100 -
        (this.editPortfolio.allocation.largeCapValue +
          this.editPortfolio.allocation.largeCapCore +
          this.editPortfolio.allocation.largeCapGrowth +
          this.editPortfolio.allocation.internationLargeCapBlend)
      return result
    },
    headers() {
      if (this.showIds) {
        return [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
        ].concat(this.headersBase)
      }
      return this.headersBase
    },
  },
  watch: {
    params: {
      handler() {
        this.$store.dispatch('portfolios/getPortfolios', {
          pagination: {
            limit: this.pagination.itemsPerPage,
            page: this.pagination.page,
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('portfolios/getPortfolios', {
      pagination: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.page,
      }
    })
  },
})
