import { pick } from 'lodash'
import axios from 'axios'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'
import authHeader from '../helpers/authHeader'

const client = applyRefreshTokenInteceptor(
  axios.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = {
  orders: [],
  pagination: {
    page: 1,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
}

const mutations = {
  setOrders: (state, { orders, pagination }) => {
    state.orders = orders
    state.pagination = pagination
  },
  setPagination: (state, { pagination }) => {
    state.pagination = {
      ...state.pagination,
      ...pagination
    }
  },
}

const actions = {
  getOrders({ commit, state }, { userId, userPortfolioId, pagination }) {
    // console.log(
    //   `GET ORDERS | userId ${userId}, userPortfolioId=${userPortfolioId}, pagination=${JSON.stringify(pagination)}`
    // )
    return client
      .get('/v1/orders', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          populate: 'user',
          userId,
          userPortfolioId,
          ...pagination,
        },
      })
      .then((res) => {
        const { results: orders } = res.data
        const pagination = pick(res.data, 'page', 'limit', 'totalPages', 'totalResults', 'sortBy')

        orders.forEach((order) => {
          const { user } = order
          if (user) {
            Object.defineProperty(order, 'fullName', {
              get: () => {
                let fullName = user.name || ''
                if (user.personal) {
                  fullName = `${user.personal.firstName || user.name || ''} ${user.personal.lastName || ''}`
                }
                return fullName
              },
            })

            Object.defineProperty(order, 'userEmail', {
              get: () => {
                return user.email
              },
            })
          }
        })

        commit('setOrders', { orders, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
      })
  },
  updatePagination({ commit }, { pagination }) {
    commit('setPagination', { pagination })
  },
}

const getters = {
  orders: (state) => state.orders,
  pagination: (state) => state.pagination,
}

export const orders = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
