import _ from 'lodash'
import { primeApi, initAuthInterceptor } from '../helpers/primeApi'
import { getUserData } from '../helpers/primeAuth'
import {
  GET_ACCOUNTS_ENDPOINT,
  GET_ACCOUNTS_TRANSFERS_ENDPOINT,
  CREATE_INDIVIDUAL_ACCOUNT_ENDPOINT,
  CREATE_COMPANY_ACCOUNT_ENDPOINT,
  UPDATE_INDIVIDUAL_ACCOUNT_ENDPOINT,
  UPDATE_COMPANY_ACCOUNT_ENDPOINT,
  DELETE_ACCOUNTS_ENDPOINT,
  CREATE_CONTACT_ENDPOINT,
  DEACTIVE_CONTACT_ENDPOINT,
  UPDATE_CONTACT_ENDPOINT,
  SEARCH_ACCOUNTS_ENDPOINT,
  DOCUMENT_UPLOAD_ENDPOINT,
  DOCUMENT_CHECK_ENDPOINT,
  GET_DOCUMENT_DATA_ENDPOINT,
  GET_ACCOUNTS_DOCUMENT_DATA_ENDPOINT,
  DEPOSIT_METHOD_ENDPOINT,
  MAKE_DEPOSIT_ENDPOINT,
  SEND_METHOD_ENDPOINT,
  MAKE_SEND_ENDPOINT,
  ASSETS_METHOD_ENDPOINT,
  ASSETS_SEND_METHOD_ENDPOINT,
  MAKE_ASSETS_SEND_ENDPOINT,
  EXEC_TRADE_ENDPOINT,
  EXEC_QUOTE_PREVIEW_ENDPOINT,
  EXEC_QUOTE_PLACE_ENDPOINT,
  EXEC_WIRE_INSTRUCTIONS_ENDPOINT,
  EXEC_STATEMENT_DOWNLOAD,
  RESEND_AGREEMENT_EMAIL_ENDPOINT,
} from '../config/endpoints'
import {
  ERROR_MESSAGE,
  CONTACT_ERROR_MESSAGE,
  CREATE_ACCOUNT_SUCCESS_MESSAGE,
  UPDATE_ACCOUNT_SUCCESS_MESSAGE,
  UPLOAD_DOCUMENT_SUCCESS_MESSAGE,
  CHECK_DOCUMENT_SUCCESS_MESSAGE,
  MAKE_DEPOSIT_SUCCESS_MESSAGE,
  MAKE_TRADE_SUCCESS_MESSAGE,
  SEND_SUCCESS_MESSAGE,
  MAKE_QUOTE_PLACE_SUCCESS_MESSAGE,
  GET_STATEMENT_LINK_ERROR_MESSAGESSAGE,
  RESEND_AGREEMENT_EMAIL_SUCCESS_MESSAGE,
} from '../config/message'

const state = {
  loading: false,
  accounts: [],
  total: 0,
  currentAccount: {},
  allDocuments: [],
  documents: [],
  documentsPagination: {
    page: 1,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
    // sortBy: 'createdAt:desc',
    search: null,
  },
  checks: [],
  edit_documents: [],
  editDocumentsPagination: {
    page: 1,
    limit: 5,
    totalPages: 0,
    totalResults: 0,
    // sortBy: 'createdAt:desc',
    search: null,
  },
  edit_checks: [],
  contacts: [],
  editContact: {},
  transfers: [],
  contributions: [],
  totalContributions: 0,
  tradeContributions: [],
  assetContributions: [],
  assetsList: [],
  assetsMethod: [],
  quotePreviewResult: {},
  wireInstructions: {},
  wireInstructionsIntl: {},
  totalBallance: {},
}

const getters = {
  loading: (state) => state.loading,
  accounts: (state) => state.accounts,
  total: (state) => state.total,
  currentAccount: (state) => state.currentAccount,
  allDocuments: (state) => state.allDocuments,
  documents: (state) => state.documents,
  documentsPagination: (state) => state.documentsPagination,
  checks: (state) => state.checks,
  contacts: (state) => state.contacts,
  editContact: (state) => state.editContact,
  edit_documents: (state) => state.edit_documents,
  editDocumentsPagination: (state) => state.editDocumentsPagination,
  edit_checks: (state) => state.edit_checks,
  transfers: (state) => state.transfers,
  contributions: (state) => state.contributions,
  totalContributions: (state) => state.totalContributions,
  tradeContributions: (state) => state.tradeContributions,
  assetContributions: (state) => state.assetContributions,
  assetsList: (state) => state.assetsList,
  assetsMethod: (state) => state.assetsMethod,
  quotePreviewResult: (state) => state.quotePreviewResult,
  getWireInstructions: (state) => state.wireInstructions,
  getWireInstructionsIntl: (state) => state.wireInstructionsIntl,
  totalBallance: (state) => state.totalBallance,
}

const actions = {
  async getAccounts({ dispatch, commit }, { pagination, accountID, accountName, email }) {
    try {
      const user = getUserData()
      commit('accountRequestProcess')
      initAuthInterceptor()
      const accounts = await primeApi('post', GET_ACCOUNTS_ENDPOINT, {
        owner: user.email,
        perPage: pagination.itemsPerPage,
        pageNum: pagination.page,
        ...(accountID && { accountID }),
        ...(accountName && { accountName }),
        ...(email && { email }),
      })
      commit('accountRequestSuccess', { accounts: accounts.data, total: accounts.total })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      commit('accountRequestFailure')
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async createIndividualAccount({ dispatch }, param) {
    try {
      const user = getUserData()
      initAuthInterceptor()
      await primeApi('post', CREATE_INDIVIDUAL_ACCOUNT_ENDPOINT, {
        ...param,
        owner: user.email,
      })
      dispatch('alert/success', CREATE_ACCOUNT_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async createCompanyAccount({ dispatch, state }, param) {
    try {
      const user = getUserData()
      const data = state.contacts
      if (data.length === 0) {
        dispatch('alert/error', CONTACT_ERROR_MESSAGE, { root: true })
        return false
      }
      initAuthInterceptor()
      await primeApi('post', CREATE_COMPANY_ACCOUNT_ENDPOINT, {
        ...param,
        contacts: data,
        owner: user.email,
      })
      dispatch('alert/success', CREATE_ACCOUNT_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  addNewContact({ commit, state }, { newContact }) {
    const data = state.contacts
    const newContacts = [...data, newContact]
    commit('setRelatedContact', newContacts)
  },
  async getCurrentAccount({ commit, dispatch }, { id }) {
    try {
      initAuthInterceptor()
      const res = await primeApi('get', `${GET_ACCOUNTS_ENDPOINT}/${id}`)
      if (res.contacts) {
        commit('currentAccountRequestSuccess', res.account)
        commit('setRelatedContact', res.contacts)
        commit('setChecks', res.checks)
        commit(
          'setTransfer',
          res.transfer.filter((item) => item.accountName !== 'Collector Account')
        )
        commit('setContribution', res.contribution)
        commit('setAssetsList', res.assetsList)
        commit('setAssetsMethod', res.assetsMethod)
      } else {
        commit('currentAccountRequestSuccess', res.account)
        commit('setChecks', res.checks)
        commit(
          'setTransfer',
          res.transfer.filter((item) => item.accountName !== 'Collector Account')
        )
        commit('setContribution', res.contribution)
        commit('setAssetsList', res.assetsList)
        commit('setAssetsMethod', res.assetsMethod)
      }
      commit('setAssetContributions', res.assetContributions)
      commit('setTradeContributions', res.tradeContributions)
      commit('setTotalBallance', res.totalBallance)
      const wireInstructions = await primeApi('get', `${EXEC_WIRE_INSTRUCTIONS_ENDPOINT}/${id}`)
      commit('setWireInstructions', wireInstructions)
      return true
    } catch (error) {
      const msg = _.get(error, 'response.data.detail')
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async getCurrentAccountDocuments({ commit, dispatch }, { id, pagination }) {
    try {
      initAuthInterceptor()
      const res = await primeApi(
        'get',
        `${GET_ACCOUNTS_DOCUMENT_DATA_ENDPOINT}?id=${id}&search=${pagination.search}&page=${pagination.page}&size=${pagination.limit}`
      )
      commit('setDocumentsPagination', {
        documents: res.documents,
        pagination: Object.assign(pagination, { totalResults: res.total, totalPages: res.pages }),
      })
    } catch (error) {
      const msg = _.get(error, 'response.data.detail')
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async getCurrentAccountAllDocuments({ commit, dispatch }, { id }) {
    try {
      initAuthInterceptor()
      const res = await primeApi(
        'get',
        `${GET_ACCOUNTS_DOCUMENT_DATA_ENDPOINT}?id=${id}`
      )
      commit('setAllDocumentsPagination', {
        documents: res.documents,
      })
    } catch (error) {
      const msg = _.get(error, 'response.data.detail')
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async getCurrentAccountTransfers({ commit, dispatch }, { id, search, page, itemsPerPage }) {
    try {
      initAuthInterceptor()
      const params = new URLSearchParams({ search, page, itemsPerPage })
      const res = await primeApi('get', `${GET_ACCOUNTS_TRANSFERS_ENDPOINT}/${id}?${params}`)
      commit('setContribution', res.contribution)
      commit('setTotalContribution', res.total)
      return true
    } catch (error) {
      const msg = _.get(error, 'response.data.detail')
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async updateIndividualAccount({ dispatch }, param) {
    try {
      initAuthInterceptor()
      await primeApi('put', UPDATE_INDIVIDUAL_ACCOUNT_ENDPOINT, { ...param })
      dispatch('alert/success', UPDATE_ACCOUNT_SUCCESS_MESSAGE, { root: true })
    } catch (error) {
      const msg = error.response.data.detail
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async updateCompanyAccount({ dispatch }, param) {
    try {
      initAuthInterceptor()
      await primeApi('put', UPDATE_COMPANY_ACCOUNT_ENDPOINT, { ...param })
      dispatch('alert/success', UPDATE_ACCOUNT_SUCCESS_MESSAGE, { root: true })
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async deleteAccount({ dispatch, commit }, { id }) {
    try {
      initAuthInterceptor()
      await primeApi('delete', `${DELETE_ACCOUNTS_ENDPOINT}/${id}`)
      commit('accountRequestSuccess', accounts)
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async addContacts({ dispatch, commit }, { newContact }) {
    try {
      initAuthInterceptor()
      const contact = await primeApi('post', CREATE_CONTACT_ENDPOINT, { ...newContact })
      const data = state.contacts
      const newContacts = [...data, contact]
      commit('setRelatedContact', newContacts)
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async deactiveContact({ commit, state, dispatch }, { id, contactID }) {
    try {
      initAuthInterceptor()
      const res = await primeApi('patch', `${DEACTIVE_CONTACT_ENDPOINT}/${contactID}`)
      const data = state.contacts
      const newContacts = [...data.reduce((arr, list) => (list._id !== id ? [...arr, list] : [...arr, res]), [])]
      commit('setRelatedContact', newContacts)
      return true
    } catch (error) {
      const msg = error.response.data.detail || error.response.data.message
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  deleteCreatedContact({ commit, state }, { index }) {
    const data = state.contacts
    const newContacts = data.filter((list) => list.index !== index)
    commit('setRelatedContact', newContacts)
  },
  async updateContact({ dispatch, state, commit }, { index, contactID, ...param }) {
    try {
      if (contactID) {
        initAuthInterceptor()
        await primeApi('put', UPDATE_CONTACT_ENDPOINT, {
          contactID,
          ...param,
        })
        dispatch('alert/success', UPDATE_ACCOUNT_SUCCESS_MESSAGE, { root: true })
      } else {
        const contact = {
          index,
          ...param,
        }
        const contacts = state.contacts.reduce(
          (array, current) => (current.index === index ? [...array, contact] : [...array, current]),
          []
        )
        commit('setRelatedContact', contacts)
      }
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  clearContact({ commit }) {
    commit('clearContact')
  },
  setEditContact({ commit }, { contact }) {
    commit('editContact', contact)
  },
  async searchAccount({ dispatch, commit }, { accountID, accountName, email }) {
    try {
      const user = getUserData()
      commit('accountRequestProcess')
      initAuthInterceptor()
      const accounts = await primeApi('post', SEARCH_ACCOUNTS_ENDPOINT, {
        number: accountID,
        name: accountName,
        email,
        owner: user.email,
      })
      commit('accountRequestSuccess', accounts)
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async uploadDocument({ dispatch, state, commit }, { files, contactID, edit }) {
    try {
      const formData = new FormData()
      for (const file of files) {
        formData.append('file', file)
      }
      formData.append('contactID', contactID)
      initAuthInterceptor()
      const newDocs = await primeApi('post', DOCUMENT_UPLOAD_ENDPOINT, formData)
      if (edit) {
        const data = state.edit_documents
        const documents = [...newDocs.uploadedFiles, ...data]
        commit('setEditDocuments', documents)
      } else {
        const data = state.documents
        const allDocumentsData = state.allDocuments
        const documents = [...newDocs.uploadedFiles, ...data]
        const allDocuments = [...newDocs.uploadedFiles, ...allDocumentsData]
        commit('setDocuments', documents)
        commit('setAllDocumentsPagination', { documents: allDocuments })
      }
      if (newDocs.errors.length) {
        dispatch('alert/error', newDocs.errors.join('\n'), { root: true })
      } else {
        dispatch('alert/success', UPLOAD_DOCUMENT_SUCCESS_MESSAGE, { root: true })
      }
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async documentCheck(
    { dispatch, state, commit },
    { docType, docCountry, otherDocType, proofIdentity, proofAddress, docID, docBackID, contactID, edit }
  ) {
    try {
      if (edit) {
        const data = {
          contactID,
          documentID: state.edit_documents.filter((document) => document.documentID === docID)[0].documentID,
          documentName: state.edit_documents.filter((document) => document.documentID === docID)[0].fileName,
          documentURL: state.edit_documents.filter((document) => document.documentID === docID)[0].url,
          ...(docBackID && {
            documentBackID: state.edit_documents.filter((document) => document.documentID === docBackID)[0].documentID,
          }),
          ...(docBackID && {
            documentBackName: state.edit_documents.filter((document) => document.documentID === docBackID)[0].fileName,
          }),
          ...(docBackID && {
            documentBackURL: state.edit_documents.filter((document) => document.documentID === docBackID)[0].url,
          }),
          proofIdentity,
          proofAddress,
          docCountry,
          docType,
          ...(docType === 'other' ? { otherDocType: otherDocType } : {}),
        }
        initAuthInterceptor()
        const newCheck = await primeApi('post', DOCUMENT_CHECK_ENDPOINT, data)
        const checks = state.edit_checks
        const res = [...checks, newCheck]
        commit('setEditChecks', res)
      } else {
        const data = {
          contactID,
          documentID: state.documents.filter((document) => document.documentID === docID)[0].documentID,
          documentName: state.documents.filter((document) => document.documentID === docID)[0].fileName,
          documentURL: state.documents.filter((document) => document.documentID === docID)[0].url,
          ...(docBackID && {
            documentBackID: state.documents.filter((document) => document.documentID === docBackID)[0].documentID,
          }),
          ...(docBackID && {
            documentBackName: state.documents.filter((document) => document.documentID === docBackID)[0].fileName,
          }),
          ...(docBackID && {
            documentBackURL: state.documents.filter((document) => document.documentID === docBackID)[0].url,
          }),
          proofIdentity,
          proofAddress,
          docCountry,
          docType,
          ...(docType === 'other' ? { otherDocType: otherDocType } : {}),
        }
        initAuthInterceptor()
        const newCheck = await primeApi('post', DOCUMENT_CHECK_ENDPOINT, data)
        const checks = state.checks
        const res = [...checks, newCheck]
        commit('setChecks', res)
      }
      dispatch('alert/success', CHECK_DOCUMENT_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async getDocumentsData({ dispatch, commit }, { contactID, pagination }) {
    try {
      initAuthInterceptor()
      const res = await primeApi(
        'get',
        `${GET_DOCUMENT_DATA_ENDPOINT}?id=${contactID}&search=${pagination.search}&page=${pagination.page}&size=${pagination.limit}`
      )
      commit('setEditDocumentsWithPagination', {
        documents: res.documents,
        pagination: Object.assign(pagination, { totalResults: res.total, totalPages: res.pages }),
      })
      commit('setEditChecks', res.checks)
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeDepositMethod({ dispatch, state, commit }, param) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', DEPOSIT_METHOD_ENDPOINT, param)
      const data = state.contributions
      const newDatas = [...data, newContribution]
      commit('setContribution', newDatas)
      dispatch('alert/success', MAKE_DEPOSIT_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeDeposit({ dispatch, state, commit }, param) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', MAKE_DEPOSIT_ENDPOINT, param)
      const data = state.contributions
      const newDatas = [...data, newContribution]
      commit('setContribution', newDatas)
      dispatch('alert/success', MAKE_DEPOSIT_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeSendMethod({ dispatch, state, commit }, param) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', SEND_METHOD_ENDPOINT, param)
      const data = state.contributions
      const newDatas = [...data, newContribution]
      commit('setContribution', newDatas)
      dispatch('alert/success', SEND_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeSend({ dispatch, state, commit }, param) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', MAKE_SEND_ENDPOINT, param)
      const data = state.contributions
      const newDatas = [...data, newContribution]
      commit('setContribution', newDatas)
      dispatch('alert/success', SEND_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeAssetSendMethod({ dispatch }, param) {
    try {
      initAuthInterceptor()
      return await primeApi('post', ASSETS_SEND_METHOD_ENDPOINT, param)
    } catch (error) {
      let msg = error.response.data.detail
      if (error.response.data && error.response.data.message && error.response.data.message[0]) {
        msg = error.response.data.message[0]
      }
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeAssetSend({ dispatch }, param) {
    try {
      initAuthInterceptor()
      await primeApi('post', MAKE_ASSETS_SEND_ENDPOINT, param)
      dispatch('alert/success', SEND_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      let msg = error.response.data.detail
      if (error.response.data && error.response.data.message && error.response.data.message[0]) {
        msg = error.response.data.message[0]
      }
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async makeAssetMethod({ dispatch }, param) {
    try {
      initAuthInterceptor()
      return await primeApi('post', ASSETS_METHOD_ENDPOINT, param)
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async executeTrade({ dispatch, commit, state }, param) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', EXEC_TRADE_ENDPOINT, { ...param, expiresInSeconds: 60 })
      const data = state.contributions
      const newDatas = [...data, newContribution]
      commit('setContribution', newDatas)
      dispatch('alert/success', MAKE_TRADE_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
  async quotePreview({ dispatch, commit }, params) {
    try {
      initAuthInterceptor()
      const quotePreviewResult = await primeApi('post', EXEC_QUOTE_PREVIEW_ENDPOINT, params)
      commit('setQuotePreviewResult', quotePreviewResult)
      return quotePreviewResult
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      throw error
    }
  },
  async quotePlace({ dispatch, commit, state }, params) {
    try {
      initAuthInterceptor()
      const newContribution = await primeApi('post', `${EXEC_QUOTE_PLACE_ENDPOINT}/${params.quoteID}`, {
        accountID: params.accountID,
        expiresAt: params.expiresAt,
        transactionType: params.transactionType,
        wdFee: params.wdFee,
      })
      const curTradeContributions = state.contributions
      const newTradeContributions = [...curTradeContributions, newContribution]
      commit('setContribution', newTradeContributions)
      dispatch('alert/success', MAKE_QUOTE_PLACE_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      console.log(error)
      const msg = error.response.data.detail
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      throw error
    }
  },
  async getStatementLink({ dispatch }, params) {
    try {
      console.log(`>> ACTION | GET STATEMENT LINK | params=${JSON.stringify(params)}`)
      const response = await primeApi(
        'post',
        `${EXEC_STATEMENT_DOWNLOAD}`,
        _.pick(params, 'accountID', 'month', 'year', 'format')
      )
      console.log(`<< ACTION | GET STATEMENT LINK | response=${JSON.stringify(response)}`)
      return response
    } catch (error) {
      const msg = error.response.data.detail
      dispatch('alert/error', msg || GET_STATEMENT_LINK_ERROR_MESSAGESSAGE, { root: true })
      throw error
    }
  },
  async resendAgreementEmail({ dispatch }, { contactID }) {
    try {
      initAuthInterceptor()
      await primeApi('post', `${RESEND_AGREEMENT_EMAIL_ENDPOINT}/${contactID}`, { param: {} })
      dispatch('alert/success', RESEND_AGREEMENT_EMAIL_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      const msg = error.response.data.detail
      console.log(error)
      dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
      return false
    }
  },
}

const mutations = {
  accountRequestProcess(state) {
    state.loading = true
  },
  accountRequestSuccess(state, { accounts, total }) {
    state.loading = false
    state.accounts = accounts
    state.total = total
  },
  accountRequestFailure(state) {
    state.loading = false
  },
  currentAccountRequestProcess(state) {
    state.loading = true
  },
  currentAccountRequestSuccess(state, currentAccount) {
    state.loading = false
    state.currentAccount = currentAccount
  },
  currentAccountRequestFailure(state) {
    state.loading = false
  },
  setDocuments(state, documents) {
    state.documents = documents
  },
  setDocumentsPagination(state, { documents, pagination }) {
    state.documents = documents
    state.documentsPagination = pagination
  },
  setAllDocumentsPagination(state, { documents }) {
    state.allDocuments = documents
  },
  setChecks(state, checks) {
    state.checks = checks
  },
  setTransfer(state, transfers) {
    state.transfers = transfers
  },
  setContribution(state, contributions) {
    state.contributions = contributions
  },
  setTotalContribution(state, totalContributions) {
    state.totalContributions = totalContributions
  },
  setTradeContributions(state, tradeContributions) {
    state.tradeContributions = tradeContributions
  },
  setAssetContributions(state, assetContributions) {
    state.assetContributions = assetContributions
  },
  setAssetsList(state, assetsList) {
    state.assetsList = assetsList
  },
  setAssetsMethod(state, assetsMethod) {
    state.assetsMethod = assetsMethod
  },
  setTotalBallance(state, totalBallance) {
    state.totalBallance = totalBallance
  },
  setEditDocuments(state, documents) {
    state.edit_documents = documents
  },
  setEditDocumentsWithPagination(state, { documents, pagination }) {
    state.edit_documents = documents
    state.editDocumentsPagination = pagination
  },
  setEditChecks(state, checks) {
    state.edit_checks = checks
  },
  setRelatedContact(state, contacts) {
    state.contacts = contacts
  },
  editContact(state, contact) {
    state.editContact = contact
  },
  clearContact(state) {
    state.contacts = []
  },
  setQuotePreviewResult(state, quotePreviewResult) {
    state.quotePreviewResult = quotePreviewResult
  },
  setWireInstructions(state, wireInstructions) {
    state.wireInstructions = wireInstructions['wire-instructions']
    state.wireInstructionsIntl = wireInstructions['wire-instructions-intl']
  },
}

export const accounts = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
