
import Vue from 'vue'

export default Vue.extend({
  name: 'UserPortfolioStocks',

  props: {
    userPortfolio: {
      type: Object,
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Symbol',
        align: 'start',
        sortable: false,
        value: 'symbol',
      },
      {
        text: 'Company',
        align: 'start',
        sortable: false,
        value: 'company',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Share',
        align: 'start',
        sortable: false,
        value: 'quantity',
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Cost Value',
        align: 'start',
        sortable: false,
        value: 'costValue',
      },
      {
        text: 'Cost Price',
        align: 'start',
        sortable: false,
        value: 'costPrice',
      },
    ],
    loading: false,
  }),
  computed: {
    stocks() {
      return this.userPortfolio.stocks
    },
  },
  methods: {},
})
