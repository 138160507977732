import { get } from 'lodash'
import { primeApi, primeLoginApi } from '../helpers/primeApi'
import authHeader from '../helpers/authHeader'
import router from '../router'

import {
  CREATE_USER_ENDPOINT,
  RESET_PASSWORD_ENDPOINT,
} from '../config/endpoints'

const capitalLogin = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, password }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (get(user, 'tokens.access')) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

const capital2faLogin = (data) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.user.token}`,
    },
    body: JSON.stringify({ otp: data.otp, email: data.user.user.email, password: data.user.user.password }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/2fa/otp`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (get(user, 'tokens.access')) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user
    })
}

const capitalRegister = (avatarId, name, avatarName, email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ avatarId, name, avatarName, email, password }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/register`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      return user
    })
}

const capitalForgotPassword = (email) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, isConsole: true }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/forgot-password`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res
    })
}

const changePassword = (password, token) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ password }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/reset-password?token=${token}`, requestOptions)
    .then(handleResponse)
    .then((res) => {
      return res
    })
}

const getCurrentUser = () => {
  const jsonUser = localStorage.getItem('user')
  if (jsonUser) {
    const curUser = JSON.parse(jsonUser)

    Object.defineProperty(curUser, 'initials', {
      get: () => {
        let initials = get(curUser, 'personal.firstName[0]', '') + get(curUser, 'personal.lastName[0]', '')
        if (initials.length === 0) {
          initials = get(curUser, 'user.name[0]') || get(curUser, 'user.avatarName[0]') || get(curUser, 'user.email[0]')
        }
        return initials
      },
    })
    Object.defineProperty(curUser, 'fullName', {
      get: () => {
        let fullName = curUser.name || ''
        if (curUser.personal) {
          fullName = `${curUser.personal.firstName || curUser.name || ''} ${curUser.personal.lastName || ''}`
        }
        return fullName
      },
    })
    return curUser
  }

  return {}
}

export const refreshToken = () => {
  let user = localStorage.getItem('user')
  if (!user) {
    return {}
  }
  user = JSON.parse(user)

  const refreshToken = user.tokens.refresh.token

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ refreshToken }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/refresh-tokens`, requestOptions)
    .then(handleResponse)
    .then(({ access, refresh }) => {
      if (access && refresh) {
        user.tokens.access = access
        user.tokens.refresh = refresh
        localStorage.setItem('user', JSON.stringify(user))
      }

      return user.tokens.access.token
    })
    .catch((err) => {
      logout()
      router.push({ name: 'Login' })
      throw err
    })
}

const primeRegister = (fullName, email, password) => {
  return primeLoginApi('post', CREATE_USER_ENDPOINT, { fullName, email, password })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.log(error.response.data.message)
      throw new Error(error.response.data.message)
    })
}

const updatePassword = (email, password, newPassword) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', ...authHeader() },
    body: JSON.stringify({ email, password, newPassword }),
  }

  return fetch(`${process.env.VUE_APP_CG_URL}/v1/auth/update-password`, requestOptions)
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.log(error)
      throw new Error(error)
    })
}

const primeForgotPassword = (email) => {
  return primeApi('post', RESET_PASSWORD_ENDPOINT, { email }).then((res) => {
    return res
  })
}

const logout = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('primeToken')
}

const handleResponse = (response) => {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)
    if (!response.ok) {
      if (response.status === 401) {
        logout()
      }
      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}

export default {
  capitalLogin,
  capital2faLogin,
  capitalRegister,
  capitalForgotPassword,
  changePassword,
  getCurrentUser,
  // primeLogin,
  primeRegister,
  updatePassword,
  primeForgotPassword,
  logout,
}
