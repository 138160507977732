
import Vue from 'vue'
import Portfolios from '../components/portfolios/Portfolios.vue'
import Symbols from '../components/symbols/Symbols.vue'

export default Vue.extend({
  name: 'PortfoliosView',

  components: {
    Portfolios,
    Symbols,
  },
})
