import axios from 'axios'
import { pick, get } from 'lodash'
import authHeader from '../helpers/authHeader'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'

const client = applyRefreshTokenInteceptor(
  axios.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = () => ({
  users: [],
  pagination: {
    page: 0,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
})

const getters = {
  allUsers: (state) => state.users,
  totalResults: (state) => state.pagination.totalResults,
}

const actions = {
  getUsers({ commit, state }, pagination) {
    return client
      .get('/v1/users/all', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          populate: 'application',
          ...pagination,
        },
      })
      .then((res) => {
        const { results: users } = res.data
        const pagination = pick(res.data, 'page', 'limit', 'totalPages', 'totalResults', 'sortBy')

        commit('setUsers', { users, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
      })
  },
  updateUser({ commit }, { userId, role }) {
    return client
      .patch(`/v1/user/role/${userId}`, { role }, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: user } = res

        commit('setUpdatedUser', user)
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data.message'))
      })
  },
}

const mutations = {
  setUsers(state, { users, pagination }) {
    state.users = users
    state.pagination = pagination
  },
  setUpdatedUser(state, user) {
    const _user = state.users.find(u => u.id === user.id)
    _user.role = user.role
  },
}

export const manageUsers = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
