import decode from 'jwt-decode'

export const getPrimeToken = () => {
  const data = localStorage.getItem('user')
  return data ? JSON.parse(data).primeToken : null
}

export const getUserData = () => {
  const data = localStorage.getItem('user')
  return data ? JSON.parse(data).user : null
}

export const checkTokenExpire = (token) => {
  try {
    const { exp } = decode(token)
    const now = Date.now() / 1000
    if (now > exp) {
      return false
    }
    return true
  } catch (error) {
    return false
  }
}
