
import Vue from 'vue'
import { pick } from 'lodash'
import moment from 'moment'

export default Vue.extend({
  name: 'Symbols',
  data: () => ({
    search: '',
    pagination: {},
    headersBase: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Company',
        align: 'start',
        sortable: true,
        value: 'company',
      },
      {
        text: 'Latest Price',
        align: 'start',
        sortable: true,
        value: 'expectedPx',
      },
      {
        text: 'Price Updated At',
        align: 'start',
        sortable: false,
        value: 'priceUpdatedAt',
      },
      {
        text: 'Description',
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
    showIds: false,

    editDialogTitle: 'New Symbol',
    editDialog: false,
    deleteDialog: false,

    deleteMessage: 'Are you sure you want to delete the symbol?',

    editedIndex: -1,
    editSymbol: {} as any,
    canEditSymbolName: false,

    // snack bar
    color: 'primary',
    snackbar: false,
    timeout: 3000,
    text: 'text',
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],
    descriptionRules: [
      (v: string) => (v && v.length <= 500) || 'Description must be less than 500 characters',
    ],
  }),
  methods: {
    showEditSymbolDialog(item: any) {
      this.editDialogTitle = 'Edit Symbol'
      this.canEditSymbolName = false
      this.editSymbol = Object.assign({}, item)
      this.editDialog = true
    },
    async wrapSnackBar(promise: Promise<unknown>, successMessage: string) {
      let result
      try {
        result = await promise
        this.color = 'primary'
        this.text = successMessage
      } catch (err) {
        console.log(err)
        this.color = 'error'
        this.text = err.message
      }
      this.snackbar = true

      return result
    },
    async deleteSymbol() {
      console.log(`Delete Symbol ${this.editSymbol.name}`)
      this.deleteDialog = false
      try {
        await this.$store.dispatch('symbols/deleteSymbol', this.editSymbol.id)
        this.color = 'primary'
        this.text = `Symbol ${this.editSymbol.name} has been deleted`
      } catch (err) {
        console.log(err)
        this.color = 'error'
        this.text = err.message
      }

      this.editDialog = false
      this.snackbar = true
    },
    showNewSymbolDialog() {
      this.editDialogTitle = 'New Symbol'
      this.canEditSymbolName = true
      this.editDialog = true
    },
    closeDeleteDialog() {
      this.deleteDialog = false
    },
    closeEditDialog() {
      this.canEditSymbolName = true
      this.editDialog = false
      this.editSymbol = {}
      this.$refs.form.reset()
    },
    showDeleteSymbolDialog(item: any) {
      this.editedIndex = this.symbols.indexOf(item)
      this.editSymbol = Object.assign({}, item)
      this.deleteMessage = `Are you sure you want to delete the symbol ${item.name}?`
      this.deleteDialog = true
    },

    formatDate(dateString: string) {
      return moment(String(dateString)).format('DD/MM/YYYY hh:mm')
    },
    async save() {
      if (this.$refs.form.validate()) {
        if (this.editSymbol.id) {
          const promise = this.$store.dispatch('symbols/updateSymbol', {
            symbolId: this.editSymbol.id,
            symbol: pick(this.editSymbol, 'company', 'description'),
          })

          await this.wrapSnackBar(promise, 'Symbol has been patched successfully')
        } else {
          const promise = this.$store.dispatch('symbols/addSymbol', this.editSymbol)
          await this.wrapSnackBar(promise, 'Symbol has been added successfully')
        }

        this.editDialog = false
        this.editSymbol = {}
        this.$refs.form.reset()
      }
    },
  },
  computed: {
    symbols() {
      return this.$store.state.symbols.symbols
    },
    totalResults() {
      return this.$store.state.symbols.pagination.totalResults
    },
    params() {
      return {
        ...this.pagination,
        query: this.search
      }
    },
    headers() {
      if (this.showIds) {
        return [
          {
            text: 'ID',
            align: 'start',
            sortable: false,
            value: 'id',
          },
        ].concat(this.headersBase)
      }
      return this.headersBase
    },
  },
  watch: {
    params: {
      handler() {
        this.$store.dispatch('symbols/getSymbols', {
          pagination: {
            limit: this.pagination.itemsPerPage,
            page: this.pagination.page,
            search: this.search,
          }
        })
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch('symbols/getSymbols', {
      pagination: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.page,
      }
    })
  },
})
