<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="accounts"
      :server-items-length="total"
      class="elevation-1 pt-list"
      :page="pageNum"
      :loading="loading"
      :items-per-page="perPage"
      @update:page="getPageNumber"
      @update:items-per-page="getItemPerPage"
      loading-text="Loading... Please wait"
      @click:row="handleRowClick"
    >
      <template v-slot:top>
        <v-card-title>
          PrimeTrust Accounts
          <v-spacer></v-spacer>
          <v-btn v-if="checkPermission([permissions.managePTAccounts.key])" color="primary" @click="gotoCreateAccount">Create</v-btn>
        </v-card-title>
        <template>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="9">
                <v-row>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="accountID"
                      append-icon="mdi-magnify"
                      label="Account ID"
                      dense
                      outlined
                      clearable
                      hide-details
                      class="px-2"
                      @click:append="getSearchData"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="accountName"
                      append-icon="mdi-magnify"
                      label="Account Name"
                      dense
                      outlined
                      clearable
                      hide-details
                      class="px-2"
                      @click:append="getSearchData"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <v-text-field
                      v-model="email"
                      append-icon="mdi-magnify"
                      label="Email"
                      dense
                      outlined
                      clearable
                      hide-details
                      class="px-2"
                      @click:append="getSearchData"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3" class="d-flex align-end">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-btn color="primary" @click="getSearchData" block> Search </v-btn>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-btn color="primary" @click="clear" block> Clear </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </template>

      <template v-slot:[`item.totalBallance`]="{ item }">
        <div class="text-right px-2 py-2">
          <div v-for="(value, currency) in (item.totalBallance ? item.totalBallance.cashTotals : [])" :key="currency">
            {{ value | toCurrency(currency)}} {{currency}}
          </div>
          <div v-if="Object.keys(item.totalBallance ? item.totalBallance.assetsTotals : []).length > 0">
            <hr class="mt-2 hr_grey"/>
            <div class="pt-1" v-for="(value, key) in item.totalBallance.assetsTotals" :key="key">
              {{ value | toCurrency }} {{key}}
            </div>
          </div>
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="badge status-pending" v-if="item.status === 'pending'">
          {{ item.status }}
        </div>
        <div class="badge status-opened" v-else-if="item.status === 'opened'">
          {{ item.status }}
        </div>
        <div class="badge status-closed" v-else>{{ item.status }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon v-if="checkPermission([permissions.managePTAccounts.key])" small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon v-if="checkPermission([permissions.managePTAccounts.key])" small @click.native.stop @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="390">
      <v-card>
        <v-card-title class="text-h5 justify-center">
          <v-icon large color="blue darken-2"> mdi-exclamation-thick</v-icon>
        </v-card-title>
        <v-card-title class="text-h5 justify-center"> Are you sure? </v-card-title>
        <v-card-text class="text-center"> This account will be closed. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="handleCancelClick"> cancel </v-btn>
          <v-btn color="primary" :loading="load" :disabled="load" @click="handleAgreeClick"> yes, agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { accountTableHead } from '../../utils'
import { permissions, checkPermission } from '../../utils/permissions'

Vue.filter('toCurrency', (value, currency) => {
  if (typeof value !== 'number') {
    return value
  }
  let options
  if (currency) {
    options = {
      style: 'currency',
      currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  } else {
    options = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 5,
    }
  }
  var formatter = new Intl.NumberFormat('en-US', options)
  return formatter.format(value)
})

export default Vue.extend({
  name: 'AccountList',
  data() {
    return {
      permissions,
      checkPermission,
      headers: accountTableHead,
      dialog: false,
      pageNum: 1,
      perPage: 10,
      id: '',
      accountID: '',
      accountName: '',
      email: '',
      load: false,
    }
  },
  created() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' && (this.accountID || this.accountName || this.email)) {
        this.getSearchData()
      }
    })
  },
  mounted() {
    this.$vuetify.theme.dark = this.user.user.darkmode
    this.$router.push({ query: { ...this.$route.query, perPage: this.perPage, pageNum: this.pageNum } }).catch(() => {
      return null
    })
    this.getAccounts({
      pagination: { itemsPerPage: this.perPage, page: this.pageNum },
      accountID: this.accountID,
      accountName: this.accountName,
      email: this.email,
    })
  },
  computed: {
    ...mapGetters({
      loading: 'accounts/loading',
      accounts: 'accounts/accounts',
      total: 'accounts/total',
      user: 'auth/user',
    }),
  },
  methods: {
    gotoCreateAccount() {
      this.$router.push({ name: 'AccountAdd' })
    },
    editItem(account) {
      this.$router.push(`/account/${account.type}/${account.accountID}`)
    },
    deleteItem(item) {
      this.id = item.accountID
      this.dialog = true
    },
    handleCancelClick() {
      this.dialog = false
      this.id = ''
    },
    handleAgreeClick() {
      this.load = true
      this.deleteAccount({ id: this.id })
        .then(() => {
          window.location.reload()
          this.dialog = false
          this.load = false
        })
        .catch(() => {
          this.dialog = false
          this.load = false
        })
    },
    clear() {
      this.accountID = ''
      this.accountName = ''
      this.email = ''
      this.pageNum = 1
      this.$router.push({ query: { ...this.$route.query, perPage: this.perPage, pageNum: 1 } }).catch(() => {
        return null
      })
      this.getAccounts({
        pagination: { itemsPerPage: this.perPage, page: 1 },
      })
    },
    getSearchData() {
      this.pageNum = 1
      this.$router.push({ query: { ...this.$route.query, perPage: this.perPage, pageNum: 1 } }).catch(() => {
        return null
      })
      this.getAccounts({
        pagination: { itemsPerPage: this.perPage, page: 1 },
        accountID: this.accountID,
        accountName: this.accountName,
        email: this.email,
      })
    },
    getPageNumber(param) {
      this.pageNum = param
      this.$router.push({ query: { ...this.$route.query, perPage: this.perPage, pageNum: param } }).catch(() => {
        return null
      })
      this.getAccounts({
        pagination: { itemsPerPage: this.perPage, page: param },
        accountID: this.accountID,
        accountName: this.accountName,
        email: this.email,
      })
    },
    getItemPerPage(param) {
      this.perPage = param
      this.$router.push({ query: { ...this.$route.query, perPage: param, pageNum: this.pageNum } }).catch(() => {
        return null
      })
      this.getAccounts({
        pagination: { itemsPerPage: param, page: this.pageNum },
        accountID: this.accountID,
        accountName: this.accountName,
        email: this.email,
      })
    },
    handleRowClick(account) {
      this.$router.push(`/account/${account.accountID}`)
    },
    ...mapActions('accounts', ['getAccounts', 'deleteAccount']),
  },
})
</script>
<style>
  .hr_grey {
    border-top: 1px solid rgb(250 250 250)
  }
  .pt-list table tr:hover {
    cursor:pointer;
  }
</style>
