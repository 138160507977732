
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import StockOrdersV2 from './StockOrdersV2.vue'

export default Vue.extend({
  components: { StockOrdersV2 },
  name: 'OrdersV2',

  data: () => ({
    rowsPerPageItems: [10, 25, 50],
    actualRowsPerPage: 10,
    totalVisible: 7,
    showOrderDetailsDialog: false,
    loading: true,
    options: {} as {
      itemsPerPage: number;
      page: number;
      sortBy: string;
      sortDesc: [boolean]
    },
    headers: [
      {
        text: 'Order ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Portfolio Name',
        align: 'start',
        sortable: false,
        value: 'portfolioName',
      },
      {
        text: 'User Full Name',
        align: 'start',
        sortable: false,
        value: 'fullName',
      },
      {
        text: 'User Email',
        align: 'start',
        sortable: false,
        value: 'userEmail',
      },
      {
        text: 'type',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Action',
        align: 'start',
        sortable: false,
        value: 'action',
      },
      {
        text: 'Amount',
        align: 'start',
        sortable: false,
        value: 'amount',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Created At',
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
      {
        text: 'Actions',
        value: 'actions',
        align: 'end',
        sortable: false,
      },
    ],
    curOrder: { stockOrders: [] },
  }),
  methods: {
    async getOrders() {
      this.loading = true
      const { sortBy, sortDesc } = this.options

      await this.$store.dispatch('orders/getOrders', {
        userId: this.$route.query.userId,
        userPortfolioId: this.$route.query.userPortfolioId,
        pagination: {
          limit: this.actualRowsPerPage,
          page: this.pagination.page,
          sortBy: `${isEmpty(sortBy) ? 'createdAt' : sortBy}:${sortDesc && sortDesc[0] ? 'asc' : 'desc'}`,
        },
      })
      this.loading = false
    },
    async onRowsPerPageChange() {
      this.options.itemsPerPage = this.actualRowsPerPage
      await this.$store.dispatch('orders/updatePagination', {
        pagination: {
          limit: this.actualRowsPerPage,
          page: 1,
        },
      })
    },
    onInputPage() {
      this.getOrders()
    },
    showStockOrders(item: any) {
      this.curOrder = item
      this.showOrderDetailsDialog = true
    },
    formatCurrency(value) {
      return '$' + value
    },
    getStatusColor(status) {
      switch (status) {
        case 'Pending':
          return 'orange'
        case 'Rejected':
          return 'red'
        case 'Completed':
          return 'blue'
        default:
          return 'grey'
      }
    },
  },
  watch: {
    options: {
      handler() {
        this.getOrders()
      },
      deep: true,
    },
  },
  computed: {
    showingFrom() {
      return this.orders.length > 0 ? (this.pagination.page - 1) * this.actualRowsPerPage + 1 : 0
    },
    showingTo() {
      return (this.pagination.page - 1) * this.actualRowsPerPage + this.orders.length
    },
    ...mapGetters({
      orders: 'orders/orders',
      pagination: 'orders/pagination'
    })
  },
})
