
import Vue from 'vue'
import { mapGetters } from 'vuex'
import authService from './services/auth.service'

export default Vue.extend({
  name: 'App',
  data() {
    return {
      AUTOMATIC_LOGOUT_MINUTES: 20,
    }
  },
  computed: {
    ...mapGetters({
      color: 'alert/color',
      message: 'alert/message',
    }),
    snackbar: {
      get: function() {
        return this.$store.state.alert.snackbar
      },
      set: function() {
        return null
      },
    },
    timeout: {
      get: function() {
        return this.$store.state.alert.timeout
      },
      set: function() {
        return 3000
      },
    }
  },
  methods: {
    countDownTimer() {
      setTimeout(() => {
        const user = localStorage.getItem('user')
        if (user) {
          localStorage.clear()
          authService.logout()
          window.location.reload()
        }
        this.countDownTimer()
      }, this.AUTOMATIC_LOGOUT_MINUTES * 60000)
    }
  },
  created() {
    this.countDownTimer()
  }
})
