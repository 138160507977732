<template>
  <div class="container">
    <div class="d-flex flex-column justify-center align-items-center">
      <v-img :src="logo" width="120px" height="120px" :contain="true"></v-img>
      <div class="auth-container">
        <v-form ref="form">
          <p class="mb-0 subtitle-2">New password</p>
          <v-text-field
            class="pt-0"
            prepend-icon="mdi-lock"
            :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="newPassword"
            :type="showNewPass ? 'password' : 'text'"
            :rules="passwordRules"
            @click:append="toggleShowNewPass"
            placeholder="Input Password"
          ></v-text-field>
          <p class="mb-0 subtitle-2 d-flex flex-row justify-space-between">
            <span>Confirm password</span>
          </p>
          <v-text-field
            class="pt-0"
            prepend-icon="mdi-lock"
            :append-icon="showConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="confirmPassword"
            :type="showConfirmPass ? 'password' : 'text'"
            :rules=[requiredRule,matchingPasswordsRule]
            @click:append="toggleShowConfirmPass"
            placeholder="Input Password"
          ></v-text-field>
        </v-form>
        <v-card-actions>
          <a class="text-center subtitle-2 text-sm-left" href="javascript:void(0)" @click="gotoLogin">
            &lt; Back to Login
          </a>
          <v-spacer></v-spacer>
          <v-btn mt-auto color="primary" :loading="loading" :disabled="loading" @click="onChangePassword"> Change </v-btn>
        </v-card-actions>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import logo from '../assets/logo/logo.svg'
import {
  ERROR_MESSAGE,
} from '../config/message'

import { passwordRules } from '../config/validationRules'

export default {
  data() {
    return {
      confirmPassword: '',
      showConfirmPass: true,
      newPassword: '',
      showNewPass: true,
      logo,
      loading: false,
      passwordRules,
    }
  },
  methods: {
    async onChangePassword() {
      if (this.$refs.form.validate()) {
        try {
          this.load = true
          await this.changePassword({ password: this.newPassword, token: this.$route.query.token })
          this.load = false
          this.clear()
          this.$router.push('/')
        } catch (err) {
          const msg = err.response ? err.response.data.detail : err
          console.log(err)
          await this.$store.dispatch('alert/error', msg || ERROR_MESSAGE, { root: true })
          this.load = false
          this.clear()
        }
      }
    },
    requiredRule(v) {
      return !!v || 'Password is required'
    },
    gotoLogin() {
      this.$router.push('/')
    },
    matchingPasswordsRule() {
      return this.newPassword === this.confirmPassword || 'The password confirmation does not match.'
    },
    toggleShowNewPass() {
      this.showNewPass = !this.showNewPass
    },
    toggleShowConfirmPass() {
      this.showConfirmPass = !this.showConfirmPass
    },
    ...mapActions('auth', ['changePassword']),
    ...mapActions('alert', ['clear']),
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}

.align-items-center {
  align-items: center;
}

.auth-container {
  max-width: 430px;
  width: 80vw;
}
</style>
