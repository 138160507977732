export const API_BASE_URL = process.env.VUE_APP_PRIMETRUST_URL

export const AUTH_PREFIX = 'auth'
export const API_PREFIX = 'api'

export const CREATE_USER_ENDPOINT = `${AUTH_PREFIX}/signup`
export const RESET_PASSWORD_ENDPOINT = `${AUTH_PREFIX}/password-reset`
export const GET_AUTH_USER_ENDPOINT = `${AUTH_PREFIX}/current`

export const GET_USERS_ENDPOINT = `${API_PREFIX}/users`

export const GET_ACCOUNTS_ENDPOINT = `${API_PREFIX}/accounts`
export const GET_ACCOUNTS_TRANSFERS_ENDPOINT = `${API_PREFIX}/accounts/transfers`
export const CREATE_INDIVIDUAL_ACCOUNT_ENDPOINT = `${API_PREFIX}/accounts/individual`
export const CREATE_COMPANY_ACCOUNT_ENDPOINT = `${API_PREFIX}/accounts/company`
export const UPDATE_INDIVIDUAL_ACCOUNT_ENDPOINT = `${API_PREFIX}/accounts/individual`
export const UPDATE_COMPANY_ACCOUNT_ENDPOINT = `${API_PREFIX}/accounts/company`
export const DELETE_ACCOUNTS_ENDPOINT = `${API_PREFIX}/accounts`
export const CREATE_CONTACT_ENDPOINT = `${API_PREFIX}/accounts/contact`
export const DEACTIVE_CONTACT_ENDPOINT = `${API_PREFIX}/accounts/contact`
export const UPDATE_CONTACT_ENDPOINT = `${API_PREFIX}/accounts/contact`
export const SEARCH_ACCOUNTS_ENDPOINT = `${API_PREFIX}/accounts/search`
export const RESEND_AGREEMENT_EMAIL_ENDPOINT = `${API_PREFIX}/accounts/agreement/preview`

export const DOCUMENT_UPLOAD_ENDPOINT = `${API_PREFIX}/accounts/uploaded-documents`
export const DOCUMENT_CHECK_ENDPOINT = `${API_PREFIX}/accounts/kyc-document-checks`
export const GET_DOCUMENT_DATA_ENDPOINT = `${API_PREFIX}/accounts/contacts/documents`
export const GET_ACCOUNTS_DOCUMENT_DATA_ENDPOINT = `${API_PREFIX}/accounts/accounts/documents`

export const DEPOSIT_METHOD_ENDPOINT = `${API_PREFIX}/accounts/deposit-method`
export const MAKE_DEPOSIT_ENDPOINT = `${API_PREFIX}/accounts/make-deposit`
export const SEND_METHOD_ENDPOINT = `${API_PREFIX}/accounts/send-method`
export const MAKE_SEND_ENDPOINT = `${API_PREFIX}/accounts/make-send`
export const ASSETS_METHOD_ENDPOINT = `${API_PREFIX}/accounts/assets-method`
export const ASSETS_SEND_METHOD_ENDPOINT = `${API_PREFIX}/accounts/assets-send-method`
export const MAKE_ASSETS_SEND_ENDPOINT = `${API_PREFIX}/accounts/make-assets-send`

export const EXEC_TRADE_ENDPOINT = `${API_PREFIX}/accounts/make-trade`

export const EXEC_QUOTE_PREVIEW_ENDPOINT = `${API_PREFIX}/accounts/make-quote`
export const EXEC_QUOTE_PLACE_ENDPOINT = `${API_PREFIX}/accounts/make-quote`
export const EXEC_STATEMENT_DOWNLOAD = `${API_PREFIX}/accounts/statements`

export const EXEC_WIRE_INSTRUCTIONS_ENDPOINT = `${API_PREFIX}/accounts/wire`
