import { getPrimeToken } from '../helpers/primeAuth'

export default function authHeader() {
  // console.log(`<<< !!! AuthHeader user=${localStorage.getItem('user')}`)
  // return authorization header with jwt token
  let user = localStorage.getItem('user')
  if (!user) {
    return {}
  }
  user = JSON.parse(user)

  const primeToken = getPrimeToken()

  // console.log(`<<< !!! fetched User from local storage = ${JSON.stringify(user)}`)

  if (user && user.tokens.access) {
    return { Authorization: 'Bearer ' + user.tokens.access.token, ProxyToken: 'Bearer ' + primeToken }
  } else {
    return {}
  }
}
