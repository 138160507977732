<template>
  <v-card>
    <v-card-title>
      Account History
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        v-on:keyup.enter="onSearch"
      ></v-text-field>
      <v-spacer></v-spacer>
    </v-card-title>
    <v-data-table
      :headers="transferHistoryHeader"
      :items="contributions"
      :items-per-page="rowsPerPageItems[rowsPerPageItems.length - 1]"
      :options.sync="options"
      :loading="loading"
      class="elevation-1"
      loading-text="Loading... Please wait"
      hide-default-footer
    >
      <template v-slot:[`item.tradeID`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ formatTradeId(item) }}
            </span>
          </template>
          <span>{{ formatTradeId(item, false) }}</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.info`]="{ item }">
        <span class="font-weight-bold">
          {{ formatTransactionType(item) }}
        </span>
      </template>
      <template v-slot:[`item.asset`]="{ item }">
        <div class="nobr" v-if="formatAsset(item).length > 1">
            <span :style="{ color: formatAsset(item)[0] === 'USD' ? '#49cc90': '#FF9500'}">
              {{ formatAsset(item)[0] }}
          </span>
          -
          <span :style="{ color: formatAsset(item)[1] === 'USD' ? '#49cc90': '#FF9500'}">
              {{ formatAsset(item)[1] }}
          </span>
        </div>
        <span v-else class="nobr" :style="{ color: formatAsset(item)[0] === 'USD' ? '#49cc90': '#FF9500'}">
          {{ formatAsset(item)[0] }}
        </span>
      </template>
      <template v-slot:[`item.sent`]="{ item }">
        <span class="nobr" :style="{ color: formatSentAmount(item) === 'N/A' ? '#000' : '#EA3624' }">
          {{ formatSentAmount(item) }}
        </span>
      </template>
      <template v-slot:[`item.received`]="{ item }">
        <span class="nobr">
          {{ formatReceivedAmount(item) }}
        </span>
      </template>
      <template v-slot:[`item.fee`]="{ item }">
        <span class="nobr" :style="{ color: '#EA3624' }">
          {{ formatFee(item) }}
        </span>
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        <div class="color-red" v-if="item.amount < 0">{{ getCurrency(item.amount) }} {{ item.currencyType }}</div>
        <div v-else>{{ getCurrency(item.amount) }} {{ item.currencyType }}</div>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span :class="'ml-2 px-1 badge status-' + item.status.toLowerCase()">
          {{ item.status === 'executed_pending_settlement' ? 'settled' : item.status }}
        </span>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ getDate(item.createdAt) }}
      </template>
      <template v-slot:[`item.expiresAt`]="{ item }">
        {{ item.expiresAt ? getDate(item.expiresAt) : '-' }}
      </template>
      <template v-slot:footer>
        <div class="text-center">
          <v-row>
            <v-col class="pl-10 align-center justify-center col-2">
              <v-select
                v-model="actualRowsPerPage"
                :items="rowsPerPageItems"
                label="Rows per page"
                @change="onRowsPerPageChange"
              ></v-select>
            </v-col>
            <v-col class="align-center justify-center pt-5 col-8">
              <v-pagination
                v-model="actualPage"
                :length="pagesContributions"
                :total-visible="totalVisible"
                @input="onInputPage"
              ></v-pagination>
            </v-col>
            <v-col class="pr-10 align-center justify-center pt-4 col-2">
              Showing {{showingFrom}}-{{showingTo}} <v-spacer></v-spacer> of {{totalContributions}}
            </v-col>
          </v-row>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

const positive = (input) => {
  const strNumber = input.toString()
  return strNumber.substr(0, 1) === '-' ? strNumber.substr(1) : strNumber
}

export default Vue.extend({
  name: 'TransfersTable',
  data() {
    return {
      transferHistoryHeader: [
        { text: 'Transaction ID', value: 'tradeID', sortable: false },
        { text: 'Transaction Type', value: 'info', sortable: false },
        { text: 'Asset Type', value: 'asset', sortable: false },
        { text: 'Sent', value: 'sent', sortable: false },
        { text: 'Received', value: 'received', sortable: false },
        { text: 'Fee', value: 'fee', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Issue Date', value: 'createdAt', sortable: false },
        { text: 'Expires At', value: 'expiresAt', sortable: false },
      ],
      availableTotal: 0,
      unAvailableTotal: 0,
      total: 0,
      loading: false,
      options: {},
      search: '',
      actualPage: 1,
      totalVisible: 7,
      actualRowsPerPage: 10,
      rowsPerPageItems: [10, 25, 50, 100],
      showingFrom: 0,
      showingTo: 0,
      pagesContributions: 0,
    }
  },
  computed: {
    ...mapGetters({
      contributions: 'accounts/contributions',
      totalContributions: 'accounts/totalContributions',
    }),
  },
  methods: {
    formatFee(item) {
      if (item.cashFee) {
        return item.cashFee.amount + ' ' + item.cashFee.currencyType
      } else if (item.assetFee) {
        return item.assetFee.amount + ' ' + item.assetFee.currencyType
      } else if (item.expectedAmount) {
        return ''
      } else {
        return ''
      }
    },
    formatReceivedAmount(item) {
      if (item.info === 'trade') {
        return item.acceptorAmount + ' ' + item.acceptorCurrency
      } else if (item.info === 'trade fee') {
        return Math.abs(item.amount) + ' ' + item.currencyType
      } else if (item.info === 'buy') {
        return item.acceptorAmount + ' ' + item.acceptorCurrency
      } else if (item.info === 'send') {
        return 'N/A'
      } else if (item.tradeID && item.acceptorAmount && item.initiatorAmount) {
        return item.acceptorAmount + ' ' + item.acceptorCurrency
      } else if (item.expectedAmount) {
        return item.expectedAmount + ' ' + item.currencyType
      } else {
        return 'N/A'
      }
    },
    formatSentAmount(item) {
      if (item.info === 'trade') {
        return positive(item.initiatorAmount) + ' ' + item.initiatorCurrency
      } else if (item.info === 'trade fee') {
        return 'N/A'
      } else if (item.info === 'buy') {
        return Math.abs(item.initiatorAmount) + ' ' + item.initiatorCurrency
      } else if (item.info === 'send') {
        return positive(item.amount) + ' ' + item.currencyType
      } else if (item.tradeID && item.acceptorAmount && item.initiatorAmount) {
        return positive(item.initiatorAmount) + ' ' + item.initiatorCurrency
      } else if (item.expectedAmount) {
        return 'N/A'
      } else {
        return item.amount + ' ' + item.currencyType
      }
    },
    formatAsset(item) {
      if (item.info === 'trade') {
        return [item.initiatorCurrency, item.acceptorCurrency]
      } else if (item.info === 'buy') {
        return [item.initiatorCurrency, item.acceptorCurrency]
      } else if (item.tradeID && item.acceptorAmount && item.initiatorAmount) {
        return [item.initiatorCurrency, item.acceptorCurrency]
      } else {
        return [item.currencyType]
      }
    },
    formatTransactionType(item) {
      if (item.info) {
        return item.info.charAt(0).toUpperCase() + item.info.slice(1)
      } else if (!item.reference && item.transferID === 'internal') {
        return 'Fee'
      } else if (item.transferID && item.amount && item.feeContributionID) {
        return 'Send'
      } else if (item.tradeID && item.acceptorAmount && item.initiatorAmount) {
        return 'Buy'
      } else if (item.expectedAmount) {
        return 'Deposit'
      } else {
        return ''
      }
    },
    formatTradeId(item, format = true) {
      if (item.transferID === 'internal') {
        return 'internal'
      } else if (item.transferID) {
        return format ? item.transferID.substr(0, 5) + '...' + item.transferID.substr(-5) : item.transferID
      } else if (item.tradeID) {
        return format ? item.tradeID.substr(0, 5) + '...' + item.tradeID.substr(-5) : item.tradeID
      } else {
        return ''
      }
    },
    getDate(date) {
      return new Date(date).toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
    },
    getCurrency(value) {
      if (value < 0) {
        const absValue = Math.abs(value)
        const multiplier = Math.pow(10, 2)
        const round = (Math.round((+absValue + Number.EPSILON) * multiplier) / multiplier).toFixed(2)
        return `-$${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2 }).format(round)}`
      } else {
        const multiplier = Math.pow(10, 2)
        const round = (Math.round((+value + Number.EPSILON) * multiplier) / multiplier).toFixed(2)
        return `$${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2 }).format(round)}`
      }
    },
    onSearch() {
      this.fetchData()
    },
    onInputPage() {
      this.fetchData()
    },
    onRowsPerPageChange() {
      this.fetchData()
    },
    fetchData() {
      this.loading = true
      this.getCurrentAccountTransfers({
        id: this.$route.params.id,
        search: this.search,
        page: this.actualPage,
        itemsPerPage: this.actualRowsPerPage,
      }).then(() => {
        this.showingFrom = this.contributions.length > 0 ? (this.actualPage - 1) * this.actualRowsPerPage + 1 : 0
        this.showingTo = (this.actualPage - 1) * this.actualRowsPerPage + this.contributions.length
        this.pagesContributions = this.totalContributions > 0 ? Math.ceil(this.totalContributions / this.actualRowsPerPage) : 0
        this.loading = false
      })
    },
    ...mapActions('accounts', ['getCurrentAccountTransfers']),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    options: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    contributions() {
      if (this.contributions.length > 0) {
        let total = 0
        let unAvailableTotal = 0
        this.contributions.forEach((contribution) => {
          if (contribution.status === 'cleared' || contribution.status === 'settled') {
            total = +total + +contribution.amount
          } else if (contribution.status !== 'pending' || +contribution.amount < 0) {
            unAvailableTotal = +unAvailableTotal + +contribution.amount
          }
        })
        this.total = total
        this.unAvailableTotal = Math.abs(unAvailableTotal)
        this.availableTotal = +total + +unAvailableTotal
      }
    },
    date() {
      this.assetsAcquisition = this.formatDate(this.date)
    },
  },
})
</script>

<style scoped>
.nobr { white-space: nowrap }
.header {
  padding: 4px 16px;
}
.header-title {
  font-size: 1.25rem;
  line-height: 1.5;
}
</style>
