const checkPermission = (permissions: string []) => {
  const jsonUser = localStorage.getItem('user')
  if (jsonUser) {
    const user = JSON.parse(jsonUser)
    return user.user.permissions?.some(element => permissions.includes(element))
  }
  return false
}

const getRole = () => {
  const jsonUser = localStorage.getItem('user')
  if (jsonUser) {
    const user = JSON.parse(jsonUser)
    return user.user.role
  }
  return false
}

const permissions = {
  getUsers: {
    key: 'getUsers',
    owner: 'cg-mobile',
  },
  manageUsers: {
    key: 'manageUsers',
    owner: 'cg-mobile',
  },
  getOrders: {
    key: 'getOrders',
    owner: 'cg-mobile',
  },
  managePortfolios: {
    key: 'managePortfolios',
    owner: 'cg-mobile',
  },
  manageSymbols: {
    key: 'manageSymbols',
    owner: 'cg-mobile',
  },
  manageApplication: {
    key: 'manageApplication',
    owner: 'cg-mobile',
  },
  manageQuestionnaire: {
    key: 'manageQuestionnaire',
    owner: 'cg-mobile',
  },
  manageGroups: {
    key: 'manageGroups',
    owner: 'cg-mobile',
  },
  getAdminUsers: {
    key: 'getAdminUsers',
    owner: 'cg-web',
  },
  manageAdminUsers: {
    key: 'manageAdminUsers',
    owner: 'cg-web',
  },
  getFinancialReps: {
    key: 'getFinancialReps',
    owner: 'cg-web',
  },
  manageFinancialReps: {
    key: 'manageFinancialReps',
    owner: 'cg-web',
  },
  getOwnedPTAccounts: {
    key: 'getOwnedPTAccounts',
    owner: 'cg-web',
  },
  getAllPTAccounts: {
    key: 'getAllPTAccounts',
    owner: 'cg-web',
  },
  managePTAccounts: {
    key: 'managePTAccounts',
    owner: 'cg-web',
  },
  trigerPasswordReset: {
    key: 'trigerPasswordReset',
    owner: 'cg-web',
  },
  trigerPasswordResetOthers: {
    key: 'trigerPasswordResetOthers',
    owner: 'cg-web',
  },
  changePassword: {
    key: 'changePassword',
    owner: 'cg-web',
  },
  changePasswordOthers: {
    key: 'changePasswordOthers',
    owner: 'cg-web',
  },
}

const roles = ['user', 'admin', 'super', 'preAdmin', 'rep1', 'rep2', 'customer', 'dev1', 'preDev1', 'adminRep']

export {
  checkPermission,
  getRole,
  permissions,
  roles,
}
