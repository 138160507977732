<template>
  <v-app>
    <v-app-bar app elevation="2">
      <v-app-bar-nav-icon @click="drawer = !drawer"> </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn icon x-large rounded @click="openSetting = true">
        <v-avatar :color="getColor" size="38">
          <span class="white--text text-h5">{{ displayName }}</span>
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-navigation-drawer v-if="this.$router.currentRoute.name !== 'Login'" app v-model="drawer">
      <div class="d-flex justify-center my-0 mx-2">
          <v-img style="border: 0px solid blue" :src="logoTopLeft" width="auto" height="60px" :contain="true" @click="goHome()"></v-img>
      </div>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item-group v-model="selectedMenuItem" color="primary">
          <v-list-item v-for="item in navbarItems" :key="item.route" @click="onClick(item.route)" link>
            <v-list-item-icon>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-container>
      <router-view />
    </v-container>
    <settings :visible="openSetting" @close="openSetting = false"></settings>
    <!-- <change-password-dialog :userEmail="userEmail" :isOpen="isPasswordChangeDialogOpen" v-on:closeChangePasswordDialog="closeChangePasswordDialog"></change-password-dialog> -->
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Settings from '../components/Settings.vue'
// import ChangePasswordDialog from '../components/dialogs/ChangePasswordDialog.vue'
import { colors, navbarItems } from '@/utils'
import logo from '../assets/logo/logo.svg'
import logoTopLeft from '../assets/logo/logoTopLeft.svg'
import { permissions, checkPermission, getRole } from '../utils/permissions'

export default {
  components: {
    Settings,
    // ChangePasswordDialog,
  },
  data: () => ({
    permissions,
    checkPermission,
    logo,
    logoTopLeft,
    navbarItems: [],
    openSetting: false,
    // isPasswordChangeDialogOpen: false,
    userEmail: '',
    drawer: true,
    selectedMenuItem: null,
  }),
  mounted() {
    this.navbarItems = navbarItems.filter((item) => {
      if (item.key === 'manage' && getRole() !== 'super') return false
      if (item.key === 'admins' && !checkPermission([permissions.getAdminUsers.key])) return false
      if (item.key === 'reps' && !checkPermission([permissions.getFinancialReps.key])) return false
      if (item.key === 'ptAccounts' && !checkPermission([permissions.getAllPTAccounts.key, permissions.getOwnedPTAccounts.key])) return false
      if (!item.isPrime) return true
      return this.user.user.primeTrust && item.isPrime
    })
    this.userEmail = this.user ? this.user.user.email : undefined
    this.selectedMenuItem = this.navbarItems.findIndex(item => item.title === this.$router.currentRoute.name)
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    displayName() {
      if (this.user) {
        if (this.user.user.name) return [...this.user.user.name][0]
        else if (this.user.user.email) return [...this.user.user.email][0]
      }
      this.logout()
      return null
    },
    getColor() {
      if (this.user) {
        return colors[this.user.user.email.length % 10]
      }
      return colors[0]
    },
  },
  methods: {
    ...mapActions('auth', ['logout']),
    // closeChangePasswordDialog() {
    //   this.isPasswordChangeDialogOpen = false
    // },
    onClick(route) {
      if (route === this.$router.currentRoute.path) {
        this.$router.go()
      }
      this.$router.push(route).catch(() => {
        return null
      })
    },
    goHome() {
      this.$router.push('/home')
    },
  },
}
</script>
<style scoped>
.email {
  width: 200px;
  padding: 5px;
}
</style>
