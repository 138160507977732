export const ERROR_MESSAGE = 'Error occurred, try again later.'
export const CONTACT_ERROR_MESSAGE = 'To create company account, please add related contact.'
export const CREATE_ACCOUNT_SUCCESS_MESSAGE = 'New account successfully added.'
export const LOGIN_SUCCESS_MESSAGE = 'Login successful.'
export const UPDATE_PASSWORD_CHANGE_MESSAGE = 'Your password was successfully updated. You will be redirected to login page in 10 seconds'
export const REGISTER_SUCCESS_MESSAGE = 'Register successful.'
export const SETTINGS_SUCCESS_MESSAGE = 'Settings saved successfully.'
export const MFA_SUCCESS_MESSAGE = '2FA activated successfully.'
export const REGISTER_ERROR_MESSAGE =
  'Prime trust email is already exist. please reset the password as capital gain user'
export const RESET_PASSWORD_SUCCESS_MESSAGE = 'Password successfully reset.'
export const CHANGE_PASSWORD_SUCCESS_MESSAGE = 'Password successfully changed.'
export const UPDATE_ACCOUNT_SUCCESS_MESSAGE = 'Account successfully updated.'
export const UPLOAD_DOCUMENT_SUCCESS_MESSAGE = 'Document successfully uploaded.'
export const CHECK_DOCUMENT_SUCCESS_MESSAGE = 'Please wait, checking your information'
export const MAKE_DEPOSIT_SUCCESS_MESSAGE = 'Deposit successful made.'
export const MAKE_TRADE_SUCCESS_MESSAGE = 'Trade successful made.'
export const SEND_SUCCESS_MESSAGE = 'Funds successfully withdrawn.'

export const MAKE_QUOTE_PLACE_SUCCESS_MESSAGE = 'Place Quote has been successfully executed.'

export const GET_STATEMENT_LINK_ERROR_MESSAGESSAGE = 'Could not obtain statement download link from PT.'

export const RESEND_AGREEMENT_EMAIL_SUCCESS_MESSAGE = 'Customers agreement email was sent'
