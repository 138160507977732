<template>
    <v-dialog v-model="open" @keydown.esc="closeModal" persistent scrollable max-width="800">
      <v-form class="confirm-modal">
        <v-card>
          <v-card-title class="d-flex justify-space-between">
            <span class="text-h5">Confirm</span>
            <v-btn depressed small fab @click="closeModal"><v-icon> mdi-close </v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <p class="mb-0 mt-5">Are you sure you want to proceed?</p>
            <v-divider />
            <v-container>
            <v-row v-if="data.sendType !== null && event === 'handleSend'">
              <v-col cols="3" class="text-right">Type:</v-col>
              <v-col cols="9">
                <span>{{ data.sendType.split('__')[0].toUpperCase() }}</span>
              </v-col>
            </v-row>

            <v-row v-if="data.sendTo === 'new-ach'">
              <v-col cols="3" class="text-right">To:</v-col>
              <v-col cols="9">
                <span>{{ data.newAch.label }} ({{data.newAch.accountNumber}})</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo === 'new-wallet'">
              <v-col cols="3" class="text-right">To:</v-col>
              <v-col cols="9">
                <span>{{ data.newWallet.label }} ({{data.newWallet.sendWalletAddress}})</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo === 'new-ach'">
              <v-col cols="3" class="text-right">Bank name:</v-col>
              <v-col cols="9">
                <span>{{ data.newAch.accountName }}</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo === 'new-ach'">
              <v-col cols="3" class="text-right">Check type:</v-col>
              <v-col cols="9">
                <span>{{ data.newAch.checkType }}</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo === 'new-ach'">
              <v-col cols="3" class="text-right">Account type:</v-col>
              <v-col cols="9">
                <span>{{ data.newAch.accountType }}</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo === 'new-ach'">
              <v-col cols="3" class="text-right">Routing number:</v-col>
              <v-col cols="9">
                <span>{{ data.newAch.routingNumber }}</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendTo !== null && data.sendTo !== 'new-ach' && data.sendTo !== 'new-wallet'">
              <v-col cols="3" class="text-right">To:</v-col>
              <v-col cols="9">
                <span>{{ data.sendToList.find(e => e.value === data.sendTo).label }}</span>
              </v-col>
            </v-row>
            <v-row v-if="data.sendType !== null && event === 'handleSend'">
              <v-col cols="3" class="text-right">Amount:</v-col>
              <v-col cols="9">
                {{ data.amount }} {{ data.sendType ? data.sendType.split('__')[1].toUpperCase(): '' }}
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="text-right">Reference:</v-col>
              <v-col cols="9">
                {{ data.instruction ? data.instruction : ' ' }}
              </v-col>
            </v-row>
          </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="closeModal"> No </v-btn>
            <v-btn color="primary" text @click="handleSubmit" :loading="loading"> Yes </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </template>

<script>
import Vue from 'vue'
export default Vue.extend({
  props: {
    loading: Boolean,
    event: String,
    data: Object,
  },
  emits: ['handleSend'],
  data() {
    return {
      open: false,
    }
  },
  methods: {
    closeModal() {
      this.open = false
    },
    openModal() {
      this.open = true
    },
    handleSubmit() {
      this.$emit('handleSend')
      this.closeModal()
    },
  },
})
</script>
  <style>
    .confirm-modal .col-9 {
      color: #666;
      font-weight: bold;
    }
  </style>
