<template>
  <div class="container">
    <div class="d-flex flex-column justify-center align-items-center">
      <v-img :src="logo" width="120px" height="120px" :contain="true"></v-img>
      <div class="auth-container">
        <v-form ref="form" @submit.prevent="onLogin">
          <p class="mb-0 subtitle-2">Email</p>
          <v-text-field
            class="pt-0 mb-2"
            prepend-icon="mdi-email"
            v-model="email"
            type="text"
            :rules="emailRules"
            placeholder="Input Email"
            autocomplete="email"
          ></v-text-field>
          <p class="mb-0 subtitle-2 d-flex flex-row justify-space-between">
            <span>Password</span>
            <a href="javascript:void(0)" @click="onForgotPassword">Forgot Password?</a>
          </p>
          <v-text-field
            class="pt-0"
            prepend-icon="mdi-lock"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            v-model="password"
            :type="showPass ? 'password' : 'text'"
            :rules="passwordRules"
            @click:append="toggleShowPass"
            placeholder="Input Password"
          ></v-text-field>
          <div class="px-3 d-flex flex-sm-row flex-column-reverse justify-end">
            <!-- <div class="mt-3 text-center subtitle-2" @click.stop="">
              Don't have an account?
              <a href="javascript:void(0)" @click="gotoRegister">Register</a>
            </div> -->
            <v-btn type="submit" mt-auto color="primary" :loading="loading" :disabled="loading" @click="onLogin"> Login </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import logo from '../assets/logo/logo.svg'
// import { useGtm } from '@gtm-support/vue2-gtm'

export default {
  // setup() {
  //   const gtm = useGtm()
  //   function triggerEvent() {
  //     gtm.trackEvent({
  //       event: 'login',
  //       category: 'login',
  //       action: 'click',
  //       label: 'login',
  //       value: 'login',
  //       noninteraction: false,
  //     })
  //   }
  //   return {
  //     triggerEvent,
  //   }
  // },
  data() {
    return {
      logo,
      email: '',
      password: '',
      showPass: true,
      loading: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[\w]{2,3}$/.test(v) || 'Email must be valid',
      ],
      passwordRules: [(v) => !!v || 'Password is required'],
    }
  },
  mounted() {
    if (this.user) {
      this.$router.push('home')
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    async onLogin() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const { email, password, login } = this
          await login({ email, password })
          this.loading = false
          this.clear()
          // this.triggerEvent()
        } catch (err) {
          this.loading = false
          this.clear()
        }
      }
    },
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    gotoRegister() {
      this.$router.push('/register')
    },
    onForgotPassword() {
      this.$router.push('/forgot-password')
    },
    ...mapActions('auth', ['login', 'logout']),
    ...mapActions('alert', ['clear']),
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}

.align-items-center {
  align-items: center;
}

.auth-container {
  max-width: 430px;
  width: 80vw;
}
</style>
