import axios from 'axios'
import moment from 'moment'
import { pick, get } from 'lodash'
import authHeader from '../helpers/authHeader'
import authService from '@/services/auth.service'
import { applyRefreshTokenInteceptor } from '../helpers/refreshTokenInterceptor'
import {
  SETTINGS_SUCCESS_MESSAGE,
  MFA_SUCCESS_MESSAGE,
} from '@/config/message'

const client = applyRefreshTokenInteceptor(
  axios.create({
    baseURL: process.env.VUE_APP_CG_URL,
    timeout: 10000,
  })
)

const state = () => ({
  editUser: {},
  curPortfolioOrder: {},
  users: [],
  pagination: {
    page: 0,
    limit: 10,
    totalPages: 0,
    totalResults: 0,
    sortBy: 'createdAt:desc',
    search: null,
  },
  mfa: {},
  roles: [],
})

const getters = {
  allUsers: (state) => state.users,
  getEditUser: (state) => state.editUser,
  totalResults: (state) => state.pagination.totalResults,
  getMFA: (state) => state.mfa,
  getRoles: (state) => state.roles,
}

const actions = {
  getUsers({ commit, state }, pagination) {
    return client
      .get('/v1/users', {
        headers: {
          ...authHeader(),
        },
        params: {
          ...pick(state.pagination, 'limit', 'page', 'sortBy', 'search'),
          ...pagination,
        },
      })
      .then((res) => {
        const { results: users } = res.data
        const pagination = pick(res.data, 'page', 'limit', 'totalPages', 'totalResults', 'sortBy')

        users.forEach((user) => {
          Object.defineProperty(user, 'fullName', {
            get: () => {
              if (user.personal && (user.personal.firstName || user.personal.lastName)) {
                return `${user.personal.firstName || ''} ${user.personal.lastName || ''}`
              }
              return ''
            },
          })
          Object.defineProperty(user, 'createdAtFormatted', {
            get: () => {
              return moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')
            },
          })
        })
        commit('setUsers', { users, pagination: Object.assign(pagination, { sortBy: state.pagination.sortBy }) })
      })
  },
  async createNewUser({ dispatch }, user) {
    try {
      await client
        .post('/v1/users/', user, {
          headers: {
            ...authHeader(),
          },
        })
        .then(() => {
          return true
        })
        .catch((error) => {
          throw new Error(error.response.data.message)
        })
      dispatch('getUsers')
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  },
  async settings({ dispatch }, { darkmode }) {
    await client
      .post('/v1/user/settings', { darkmode }, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', SETTINGS_SUCCESS_MESSAGE, { root: true })
        dispatch('auth/updateLoggedUser', { darkmode }, { root: true })
        return true
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true })
        throw new Error(error.response.data.message)
      })
  },
  async activate2FA({ dispatch, commit }) {
    await client
      .post('v1/auth/2fa/enroll', {}, {
        headers: {
          ...authHeader(),
        },
      })
      .then(async res => {
        commit('setMFA', res.data)
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true })
        throw new Error(error.response.data.message)
      })
  },
  async deactivate2FA({ dispatch, commit }) {
    await client
      .post('v1/auth/2fa/cancel', {}, {
        headers: {
          ...authHeader(),
        },
      })
      .then(async() => {
        commit('setMFA', null)
        dispatch('auth/updateLoggedUser', { _2fa: false }, { root: true })
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true })
        throw new Error(error.response.data.message)
      })
  },
  async confirm2FA({ dispatch }, { otp }) {
    await client
      .post('v1/auth/2fa/confirm', { otp }, {
        headers: {
          ...authHeader(),
        },
      })
      .then(async() => {
        dispatch('alert/success', MFA_SUCCESS_MESSAGE, { root: true })
        dispatch('auth/updateLoggedUser', { _2fa: true }, { root: true })
      })
      .catch((error) => {
        dispatch('alert/error', error.response.data.message, { root: true })
        throw new Error(error.response.data.message)
      })
  },
  async updateUser({ commit }, { userId, user }) {
    return client
      .patch(`/v1/users/${userId}`, user, {
        headers: {
          ...authHeader(),
        },
      })
      .then(async res => {
        const { data: user } = res
        commit('setEditUser', user)
      })
      .catch((err) => {
        throw new Error(get(err, 'response.data.message'))
      })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async updatePassword({ dispatch }, { email, password, newPassword }) {
    const response = await authService.updatePassword(email, password, newPassword)
    if (response.status > 300) {
      if (response.status === 401) {
        throw new Error('Your current password is incorret')
      }
      const responseData = await response.json()
      throw new Error(responseData.message)
    }
  },
  getEditUser({ commit }, userId) {
    return client
      .get(`/v1/users/${userId}?populate=application`, {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: user } = res

        user.registrationDate = moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')
        user.latestActivityDate = moment(String(user.createdAt)).format('DD/MM/YYYY hh:mm')

        commit('setEditUser', user)
      })
  },
  deleteUser({ dispatch }, userId) {
    return client
      .delete(`/v1/users/${userId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('getUsers')
      })
  },
  deleteApplicationForm({ dispatch }, formId) {
    return client
      .delete(`/v1/apex/applications/${formId}`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', 'Application has been deleted', { root: true })
        dispatch('getEditUser', state.editUser.id)
      })
      .catch((err) => {
        const { message } = get(err, 'response.data')
        dispatch('alert/error', message, { root: true })
      })
  },
  syncApexApplication({ dispatch, state }) {
    const curUser = state.editUser
    if (!curUser.application) {
      dispatch('alert/warn', "User doesn't have application", { root: true })
      return
    }

    return client
      .post(`/v1/users/${curUser.id}/applications/${curUser.application.id}/update`, {
        headers: {
          ...authHeader(),
        },
      })
      .then(() => {
        dispatch('alert/success', 'Application has been synced', { root: true })
        dispatch('getEditUser', state.editUser.id)
      })
      .catch((err) => {
        const { message } = get(err, 'response.data')
        dispatch('alert/error', message, { root: true })
      })
  },
  setCurPortfolioOrder({ commit }, portfolioOrder) {
    commit('setCurPortfolioOrder', portfolioOrder)
  },
  getRoles({ commit }) {
    return client
      .get('/v1/users/roles', {
        headers: {
          ...authHeader(),
        },
      })
      .then((res) => {
        const { data: roles } = res
        commit('setRoles', roles.data.filter(role => role.toLowerCase().includes('rep')))
      })
  },
}

const mutations = {
  setUsers(state, { users, pagination }) {
    state.users = users
    state.pagination = pagination
  },
  setEditUser(state, user) {
    state.editUser = user
  },
  setCurPortfolioOrder(state, portfolioOrder) {
    state.curPortfolioOrder = portfolioOrder
  },
  setMFA(state, mfa) {
    state.mfa = mfa
  },
  setRoles(state, roles) {
    state.roles = roles
  },
}

export const management = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
