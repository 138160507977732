const state = {
  color: 'primary',
  type: null,
  message: null,
  snackbar: false,
  timeout: 3500,
}

const getters = {
  color: (state) => state.color,
  type: (state) => state.type,
  message: (state) => state.message,
  snackbar: (state) => state.snackbar,
}

const actions = {
  success({ commit }, message) {
    commit('success', message)
    setTimeout(() => {
      commit('clear')
    }, state.timeout)
  },
  warn({ commit }, message) {
    commit('warn', message)
    setTimeout(() => {
      commit('clear')
    }, state.timeout)
  },
  error({ commit }, message) {
    commit('error', message)
    setTimeout(() => {
      commit('clear')
    }, state.timeout)
  },
  clear({ commit }) {
    setTimeout(() => {
      commit('clear')
    }, state.timeout)
  },
}

const mutations = {
  success(state, message) {
    state.color = 'primary'
    state.type = 'alert-success'
    state.message = message
    state.snackbar = true
  },
  warn(state, message) {
    state.color = 'warning'
    state.type = 'alert-success'
    state.message = message
    state.snackbar = true
  },
  error(state, message) {
    state.color = 'error'
    state.type = 'alert-danger'
    state.message = message
    state.snackbar = true
  },
  clear(state) {
    state.color = 'primary'
    state.type = null
    state.message = null
    state.snackbar = false
  },
}

export const alert = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
