
import { debounce, isEmpty, has, startCase } from 'lodash'
import { permissions, checkPermission } from '../../utils/permissions'
import Vue from 'vue'

import { passwordRules } from '../../config/validationRules'

export default Vue.extend({
  name: 'UsersList',
  data: () => ({
    permissions,
    checkPermission,
    search: '',
    headers: [
      {
        text: 'User ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Account',
        align: 'start',
        sortable: false,
        value: 'apex.account',
      },
      {
        text: 'Apex Application UID',
        align: 'start',
        sortable: false,
        value: 'application.uid',
      },
      {
        text: 'Apex Application Status',
        align: 'start',
        sortable: false,
        value: 'apexFormStatus',
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Date registered',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    loading: true,
    options: {} as { itemsPerPage: number; page: number; sortBy: string; sortDesc: [boolean] },
    createDialog: false,
    deleteDialog: false,

    name: '',
    email: '',
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
    ],
    password: '',
    passwordRules,
    confirmPassword: '',
    avatarName: '',
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) => (v && v.length <= 40) || 'Name must be less than 40 characters',
    ],
    role: null,
    roleRules: [
      (v: string) => !!v || 'Role is required',
    ],
    avatarId: null,

    editUser: {},

    // snack bar
    snackbar: false,
    timeout: 2000,
  }),
  methods: {
    triggerSearch() {
      const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options
      const bounced = debounce(() => {
        return this.$store.dispatch('capitalUsers/getUsers', {
          limit,
          page,
          sortBy: `${sortBy}:${sortDesc && sortDesc[0] ? 'desc' : 'asc'}`,
          search: this.search,
        })
      }, 300)
      bounced()
    },
    async getUsers() {
      this.loading = true
      // console.log(`<<< sortBy = ${JSON.stringify(this.options, null, 2)}`)
      const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options

      await this.$store.dispatch('capitalUsers/getUsers', {
        limit: limit === -1 ? this.totalResults : limit,
        page,
        sortBy: `${isEmpty(sortBy) ? 'createdAt' : sortBy}:${sortDesc && sortDesc[0] ? 'asc' : 'desc'}`,
        search: this.search,
      })

      this.loading = false
    },
    editItem(item: { id: string }) {
      this.$router.push({ name: 'CapitalUserEdit', params: { id: item.id } })
    },
    deleteItem(item: { id: string }) {
      // console.log(`DELETE USER item=${JSON.stringify(item)}`)
      this.editUser = item
      this.deleteDialog = true
    },
    async deleteUserConfirm() {
      await this.$store.dispatch('capitalUsers/deleteUser', this.editUser.id)
      this.closeDelete()
      this.snackbar = true
    },
    closeDelete() {
      this.deleteDialog = false
    },

    validateField() {
      this.$refs.form.validate()
    },
    async addNewUser() {
      if (this.$refs.form.validate()) {
        try {
          await this.$store.dispatch('capitalUsers/createNewUser', {
            name: this.name,
            email: this.email,
            password: this.password,
            avatarName: this.avatarName,
            avatarId: this.avatarId,
            role: this.role,
          })
          this.$store.dispatch('alert/success', 'User has been added')
        } catch (err) {
          this.$store.dispatch('alert/error', err.message)
        }
        this.createDialog = false
        this.snackbar = true
      }
    },
    closeAddUserDialog() {
      this.avatarId = null
      this.$refs.form.reset()
      this.createDialog = false
    },
    hasBrokarageAccount(user) {
      return has(user, 'apex.account')
    },
    formatApexFormStatus(statusStr) {
      return startCase(statusStr)
    },
    getApexFormStatusColor(item) {
      switch (item.apexFormStatus) {
        case 'submitted':
          return 'blue'
        case 'custodian_complete':
          return 'green'
        case 'in_progress':
          return 'orange'
        default:
          return 'grey'
      }
    },
  },
  computed: {
    users() {
      return this.$store.state.capitalUsers.users
    },
    rolesList() {
      let roles = {}
      roles = this.$store.getters['capitalUsers/getRoles']
      console.log(roles)
      return roles
    },
    totalResults() {
      return this.$store.getters['capitalUsers/totalResults']
    },
  },
  watch: {
    options: {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
    search: {
      handler() {
        this.triggerSearch()
      },
    },
  },
  mounted() {
    this.$store.dispatch('capitalUsers/getRoles')
  }
})
