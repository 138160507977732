<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Edit Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-15">
          <v-form ref="form">
            <v-row class="mt-10" v-if="this.cip">
              <v-col class="px-10 d-flex flex-row justify-sm-start justify-space-between" cols="12">
                <div class="mx-4">
                  <div class="font-weight-bold">Registration date:</div>
                  <div>{{ this.createdAt.slice(0, 10) }}</div>
                </div>
                <div class="mx-4 d-sm-block d-none">
                  <div class="font-weight-bold">CIP:</div>
                  <span :class="'ml-2 px-1 badge status-' + this.cip.toLowerCase()">
                    {{ this.cip }}
                  </span>
                </div>
                <div class="mx-4 d-sm-block d-none">
                  <div class="font-weight-bold">AML:</div>
                  <span :class="'ml-2 px-1 badge status-' + this.aml.toLowerCase()">
                    {{ this.aml }}
                  </span>
                </div>
              </v-col>
              <v-col class="px-10 d-flex flex-row d-sm-none justify-space-between" cols="12">
                <div class="mx-4">
                  <div class="font-weight-bold">CIP:</div>
                  <span :class="'ml-2 px-1 badge status-' + this.cip.toLowerCase()">
                    {{ this.cip }}
                  </span>
                </div>
                <div class="mx-4">
                  <div class="font-weight-bold">AML:</div>
                  <span :class="'ml-2 px-1 badge status-' + this.aml.toLowerCase()">
                    {{ this.aml }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-divider class="mb-8 mt-4" />
            <v-row class="mt-10 px-5">
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="First Name *"
                  v-model="firstName"
                  :rules="requireRule.concat(nameRule)"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Middle Name *"
                  v-model="middleName"
                  :rules="requireRule.concat(nameRule)"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Last Name *"
                  v-model="lastName"
                  :rules="requireRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-5">
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Email *"
                  v-model="email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  v-model="phone"
                  class="my-1"
                  dense
                  outlined
                  type="tel"
                  label="Phone *"
                  hint="+19424633336"
                  :rules="phoneRule"
                  @keyup="validateString"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field v-model="label" class="my-1" dense outlined label="Label"></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="my-8" />
            <v-row class="mt-10">
              <v-col class="px-10" cols="12" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthday"
                      label="Date of Birth *"
                      persistent-hint
                      dense
                      outlined
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      @blur="date = parseDate(birthday)"
                      :rules="birthRules"
                      @click:append="menu = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
                </v-menu>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Tax ID Number/SSN *"
                  v-model="taxID"
                  :rules="taxIDRules"
                  disabled
                ></v-text-field>
                <v-autocomplete
                  :items="countryList"
                  @change="setTaxCountry"
                  item-text="label"
                  item-value="value"
                  label="Tax Country *"
                  dense
                  outlined
                  v-model="taxCountry"
                  :rules="requireRule"
                  disabled
                ></v-autocomplete>
                <v-autocomplete
                  :items="taxRegionList"
                  v-if="taxCountry === 'US'"
                  item-text="label"
                  item-value="value"
                  label="Tax State *"
                  dense
                  outlined
                  v-model="taxRegion"
                  :rules="requireRule"
                ></v-autocomplete>
              </v-col>
              <v-col class="px-10" cols="12" sm="6">
                <v-autocomplete
                  :items="countryList"
                  @change="setCountry"
                  item-text="label"
                  item-value="value"
                  label="Country *"
                  dense
                  outlined
                  v-model="country"
                  :rules="requireRule"
                ></v-autocomplete>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Street Address *"
                  v-model="street1"
                  :rules="requireRule"
                ></v-text-field>
                <v-text-field class="my-1" dense outlined label="Street Address 2" v-model="street2"></v-text-field>
                <v-text-field
                  v-model="city"
                  class="my-1"
                  dense
                  outlined
                  label="City *"
                  :rules="requireRule"
                ></v-text-field>
                <v-autocomplete
                  :items="regionList"
                  item-text="label"
                  item-value="value"
                  label="State/Province/Region"
                  dense
                  outlined
                  v-model="region"
                ></v-autocomplete>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Postal Code *"
                  v-model="postalCode"
                  :rules="postalRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-10 d-flex justify-center justify-sm-end">
                <v-btn color="primary" :loading="load" :disabled="load" @click="onSave"> Save </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
        <div class="mx-10 mt-5 pb-10" v-if="edit === 'true'">
          <v-divider class="mb-8" />
          <div class="subtitle-1">Uploaded Documents</div>
          <v-row class="mb-5 mx-3">
            <v-col class="mt-5" cols="12" md="6">
              <v-file-input
                counter
                show-size
                outlined
                @change="onUploadDocument"
                label="Upload Document"
                accept="application/pdf, image/bmp, image/heif, image/heic, image/jpeg, image/jpg, image/png, image/tiff, image/webp"
                multiple
                :rules="fileRules"
              ></v-file-input>
            </v-col>
            <v-col cols="12" md="6">
              <v-data-table
                :headers="headers"
                :items="edit_documents"
                :options.sync="pagination"
                :server-items-length="totalResults"
                class="elevation-1 pt-list"
                :loading="loading"
                loading-text="Loading... Please wait"
              >
              </v-data-table>
            </v-col>
          </v-row>
          <v-divider class="mb-8" />
          <div class="subtitle-1">Document Checks</div>
          <v-form ref="kycform">
            <v-row class="mx-3 mt-5">
              <v-col cols="12" md="4">
                <v-select
                  :items="documentTypes"
                  item-text="label"
                  item-value="value"
                  label="Document Type *"
                  dense
                  outlined
                  v-model="docType"
                  :rules="requireRule"
                ></v-select>
                <v-select
                  :items="countryList"
                  item-text="label"
                  item-value="value"
                  label="Document Country *"
                  dense
                  outlined
                  v-model="docCountry"
                  :rules="requireRule"
                ></v-select>
                <v-select
                  :items="edit_documents"
                  item-text="fileName"
                  item-value="documentID"
                  label="Select Document *"
                  dense
                  no-data-text="Upload documents to get them listed here"
                  outlined
                  v-model="docID"
                  :rules="requireRule"
                ></v-select>
                <v-select
                  :items="edit_documents"
                  item-text="fileName"
                  item-value="documentID"
                  label="Select Back of Document *"
                  no-data-text="Upload documents to get them listed here"
                  dense
                  outlined
                  v-if="docType === 'drivers_license' || docType === 'residence_permit' || docType === 'government_id'"
                  v-model="docBackID"
                  :rules="requireRule"
                ></v-select>
                <v-btn
                  class="mt-5"
                  color="primary"
                  @click="onCheckDocument"
                  :loading="loading"
                  :disabled="loading"
                  block
                >
                  Document Check
                </v-btn>
              </v-col>
              <v-col cols="12" md="8">
                <v-simple-table fixed-header>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Document Type</th>
                        <th class="text-left">File Name</th>
                        <th class="text-left">Country</th>
                        <th class="text-left">Status</th>
                        <th class="text-left">Identity</th>
                        <th class="text-left">Address</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in edit_checks" :key="item.name">
                        <td>{{ item.documentType }}</td>
                        <td>
                          <a :href="item.fileUrl" target="_black">
                            {{ item.fileName }}
                          </a>
                          <br />
                          <span v-if="item.backFileID">
                            <a :href="item.backFileUrl" target="_black">
                              {{ item.backFileName }}
                            </a>
                          </span>
                        </td>
                        <td>{{ item.country }}</td>
                        <td>{{ item.status }}</td>
                        <td class="text-center">
                          <v-simple-checkbox v-model="item.proofIdentity" disabled></v-simple-checkbox>
                        </td>
                        <td class="text-center">
                          <v-simple-checkbox v-model="item.proofAddress" disabled></v-simple-checkbox>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="uploading" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Uploading ...
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validate } from 'postal-codes-js'
import countries from '@/utils/country'
import { documentTypes } from '@/utils'
import { isFileTypeAllowed } from '../../config/validationRules'
export default {
  data() {
    return {
      headers: [
        { text: 'File Type', value: 'fileType', sortable: false },
        { text: 'File Name', value: 'fileName', sortable: false },
        { text: 'Uploaded Date', value: 'updatedAt', sortable: false },
      ],
      search: '',
      pagination: {
        itemsPerPage: 5,
        page: 1,
      },
      load: false,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      label: '',
      taxID: '',
      taxCountry: '',
      taxRegion: '',
      country: '',
      street1: '',
      street2: '',
      city: '',
      region: '',
      postalCode: '',
      aml: null,
      cip: null,
      createdAt: null,
      docType: '',
      docCountry: '',
      docID: '',
      docBackID: '',
      uploading: false,
      loading: false,
      documentTypes: documentTypes,
      contactID: '',
      index: '',
      countryList: [],
      taxRegionList: [],
      regionList: [],
      requireRule: [(v) => !!v || 'This field is required'],
      nameRule: [
        (v) => /^(?!.*[^a-zA-Z0-9 -])/.test(v) || 'This field should not contain special characters',
        (v) => (v && v.length <= 40) || 'Name must be less than 40 characters',
      ],
      fileRules: [
        (value) => !value || value.every((file) => file.size <= 5242880) || 'File size should be less than 5 MB',
        (value) =>
          !value || value.every((file) => isFileTypeAllowed(file.type)) || 'Only images and PDF documents are allowed',
        (value) => !value || value.length <= 5 || 'Only 5 documents upload at once are allowed',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      phoneRule: [
        (v) => !!v || 'Phone is required',
        (v) =>
          /^(\+?\d{1,3})?(-)?\d{9}$/.test(v) ||
          /^(\+?\d{1,2})?(-)?\d{10}$/.test(v) ||
          'Phone must be valid: +19424633336',
      ],
      taxIDRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          this.taxCountry !== 'US' ||
          (this.taxCountry === 'US' && v.length === 9) ||
          'Tax ID Number is not a valid US SSN or ITIN',
      ],
      postalRule: [(v) => this.country === '' || validate(this.country, v) || 'Postal Code must be valid'],
      birthRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          (this.calculateAge(new Date(v)) >= 18 && this.calculateAge(new Date(v)) <= 99) ||
          'Must be between the ages of 18 and 99',
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      birthday: '',
      menu: false,
    }
  },
  props: ['visible', 'edit'],
  mounted() {
    this.countryList = countries.map((country) => {
      return {
        label: `${country.countryName} (${country.countryShortCode})`,
        value: country.countryShortCode,
      }
    })
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
    ...mapGetters({
      editContact: 'accounts/editContact',
      edit_documents: 'accounts/edit_documents',
      edit_checks: 'accounts/edit_checks',
    }),
    params() {
      return {
        ...this.pagination,
        query: this.search
      }
    },
    totalResults() {
      return this.$store.state.accounts.editDocumentsPagination.totalResults
    },
  },
  methods: {
    validateString() {
      const regex = new RegExp('^[0-9+-]*$')
      if (regex.test(this.phone)) {
        if (this.phone.length > 14) {
          this.phone = this.phone.substring(0, this.phone.length - 1)
        }
      } else {
        this.phone = this.phone.substring(0, this.phone.length - 1)
      }
    },
    setTaxCountry(value) {
      if (value === 'US') {
        let taxRegions = []
        countries.map((country) => {
          if (country.countryShortCode === value) {
            taxRegions = country.regions
          }
        })
        this.taxRegionList = taxRegions.map((taxRegion) => {
          return {
            label: `${taxRegion.name} (${taxRegion.shortCode})`,
            value: taxRegion.shortCode,
          }
        })
      }
    },
    setCountry(value) {
      let regions = []
      countries.map((country) => {
        if (country.countryShortCode === value) {
          regions = country.regions
        }
      })
      this.regionList = regions.map((region) => {
        return {
          label: `${region.name} (${region.shortCode})`,
          value: region.shortCode,
        }
      })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    calculateAge(birthday) {
      const ageDifMs = Date.now() - birthday.getTime()
      const ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.load = true
        const name = `${this.firstName} ${this.middleName} ${this.lastName}`
        const param = {
          name,
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          email: this.email,
          label: this.label,
          phone: this.phone,
          birthday: this.birthday,
          taxID: this.taxID,
          taxCountry: this.taxCountry,
          taxRegion: this.taxRegion,
          country: this.country,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          region: this.region,
          postalCode: this.postalCode,
          contactID: this.contactID,
          index: this.index,
        }
        this.updateContact(param)
          .then(() => {
            this.load = false
            this.dialog = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
          .catch(() => {
            this.load = false
            this.dialog = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
      }
    },
    onUploadDocument(files) {
      if (files.length) {
        if (
          files.some((file) => file.size > 5242880) ||
          files.some((file) => !isFileTypeAllowed(file.type)) ||
          files.length > 5
        ) {
          return
        }
        const { contactID, uploadDocument } = this
        this.uploading = true
        uploadDocument({
          files,
          contactID,
          edit: true,
        })
          .then(() => {
            this.getCurrentAccountDocuments({
              id: this.$route.params.id,
              pagination: {
                limit: this.pagination.itemsPerPage,
                page: this.pagination.page,
              }
            }).then(() => {
              this.uploading = false
            }).catch(() => {
              this.uploading = false
            })
          })
          .catch(() => {
            this.uploading = false
          })
      }
    },
    onCheckDocument() {
      if (this.$refs.kycform.validate()) {
        this.loading = true
        const param = {
          docType: this.docType,
          docCountry: this.docCountry,
          docID: this.docID,
          docBackID: this.docBackID,
          contactID: this.contactID,
        }
        this.documentCheck({ ...param, edit: true })
          .then(() => {
            this.loading = false
            this.docType = null
            this.docCountry = null
            this.docID = null
            this.docBackID = null
            this.$refs.kycform.reset()
            this.clear()
          })
          .catch(() => {
            this.loading = false
            this.clear()
          })
      }
    },
    getDate(date) {
      return date.replace('T', ' ').slice(0, 19)
    },
    ...mapActions('accounts', ['getDocumentsData', 'updateContact', 'uploadDocument', 'documentCheck']),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    editContact() {
      if (this.editContact) {
        this.setCountry(this.editContact.country)
        this.setTaxCountry(this.editContact.taxCountry)
        this.firstName = this.editContact.firstName
        this.middleName = this.editContact.middleName
        this.lastName = this.editContact.lastName
        this.email = this.editContact.email
        this.phone = this.editContact.phone
        this.label = this.editContact.label
        this.birthday = this.editContact.birthday
        this.taxID = this.editContact.taxID
        this.taxCountry = this.editContact.taxCountry
        this.taxRegion = this.editContact.taxRegion
        this.country = this.editContact.country
        this.street1 = this.editContact.street1
        this.street2 = this.editContact.street2
        this.city = this.editContact.city
        this.region = this.editContact.region
        this.postalCode = this.editContact.postalCode
        this.contactID = this.editContact.contactID
        this.createdAt = this.editContact.createdAt
        this.cip = this.editContact.cip
        this.aml = this.editContact.aml
        this.index = this.editContact.index
        if (!this.editContact.index) {
          this.getDocumentsData({
            contactID: this.editContact.contactID,
            pagination: {
              limit: this.pagination.itemsPerPage,
              page: this.pagination.page,
            },
          })
        }
      }
    },
    date() {
      this.birthday = this.formatDate(this.date)
    },
    params: {
      handler() {
        this.getDocumentsData({
          contactID: this.editContact.contactID,
          pagination: {
            limit: this.pagination.itemsPerPage,
            page: this.pagination.page,
          }
        })
      },
      deep: true
    }
  },
}
</script>
