import axios from 'axios'
import { refreshToken } from '../services/auth.service'

export const applyRefreshTokenInteceptor = (apexClient) => {
  apexClient.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      console.log(error)
      const {
        config: originalRequest,
        response: { status },
      } = error
      if (status === 401) {
        return refreshToken().then((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`
          return axios(originalRequest)
            .then((result) => {
              return result
            })
            .catch((err) => {
              throw err
            })
        })
      }
      return Promise.reject(error)
    }
  )
  return apexClient
}
