
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { pick, isEmpty, get, startCase } from 'lodash'
import UserPortfoliosV2 from '../components/capitalUser/CapitalUserPortfoliosV2.vue'
import moment from 'moment'

export default Vue.extend({
  components: { UserPortfoliosV2 },
  name: 'UserEditView',
  data: () => ({
    color: 'primary',
    snackbar: false,
    timeout: 2000,
    text: 'Saved',
    valid: true,
    name: '',
    avatarName: '',
    email: '',
    role: '',
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) => (v && v.length <= 40) || 'Name must be less than 40 characters',
    ],
    roleRules: [
      (v: string) => !!v || 'Role is required',
    ],
  }),
  computed: {
    portfolios() {
      const portfolios = this.user.portfolios || []
      return portfolios.filter((p: { name: string }) => p.name)
    },
    apexAccount() {
      return get(this.user, 'apex.account', '')
    },
    apexUserId() {
      return get(this.user, 'apexId', '')
    },
    apexFormId() {
      return get(this.user, 'application.id_apex', '')
    },
    lastActivityDate() {
      if (this.user.experience && !isEmpty(this.user.experience)) {
        const exp: { timestamp: string } = this.user.experience[this.user.experience.length - 1]
        return moment(String(exp.timestamp)).format('DD/MM/YYYY hh:mm')
      }

      return moment(this.user.createdAt).format('DD/MM/YYYY hh:mm')
    },
    applicationStatus() {
      if (this.user && this.user.application) {
        return startCase(this.user.application.status)
      }
      return ''
    },
    user() {
      return this.$store.getters['capitalUsers/getEditUser']
    },
    rolesList() {
      return this.$store.getters['capitalUsers/getRoles']
    },
    totalExperience() {
      const { experience } = this.$store.getters['capitalUsers/getEditUser']
      if (experience) {
        return experience.reduce((acc: number, exp: { amount: number }) => (acc += exp.amount), 0)
      }
      return undefined
    },
  },
  watch: {
    // $route(to, from) {
    //   // console.log(`Path has changed from=${from} to ${to}`)
    // },
    user(user) {
      this.name = user.name
      this.avatarName = get(user, 'avatarName', '')
      this.email = get(user, 'email', '')
      this.role = get(user, 'role', '')
    },
  },
  methods: {
    ...mapGetters('capitalUsers', ['getEditUser']),
    async save() {
      const userId = this.$route.params.id
      const user = {
        name: this.name,
        role: this.role,
        ...(pick(this.user, 'email') !== this.email && { email: this.email }),
        ...pick(this.user, 'avatarId'),
        ...pick(this.user, 'avarName') !== this.avatarName && { avatarName: this.avatarName },
      }
      console.log(user)
      try {
        await this.$store.dispatch('capitalUsers/updateUser', { userId, user })
        this.color = 'primary'
        this.text = 'User has been updated'
        this.$store.dispatch('alert/success', 'User has been updated')
        this.goBack()
      } catch (err) {
        this.color = 'error'
        this.text = err.message
        this.$store.dispatch('alert/error', err.message)
      }
    },
    goBack() {
      this.$router.back()
    },
  },
  mounted() {
    this.$store.dispatch('capitalUsers/getEditUser', this.$route.params.id)
    this.$store.dispatch('capitalUsers/getRoles')
  },
})
