
import Vue from 'vue'
import UsersList from '../components/manageUsers/UsersList.vue'

export default Vue.extend({
  name: 'ManageUsers',
  components: {
    UsersList,
  },
})
