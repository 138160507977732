<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img :src="logo" class="my-3" contain height="200" />
      </v-col>
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">Capital Gain Admin Console</h1>
        <h2 class="headline font-weight-bold mb-3">Links</h2>
        <v-row justify="center">
          <router-link v-for="(link, i) in importantLinks" :key="i" :to="link.href" class="subheading mx-3">{{
            link.text
          }}</router-link>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import logo from '../assets/logo/logo.svg'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Home',

  data: () => ({
    logo,
    importantLinks: [
      {
        text: 'Users',
        href: '/users',
      },
      {
        text: 'Portfolios',
        href: '/portfolios',
      },
      {
        text: 'Orders',
        href: '/orders',
      },
    ],
  }),
  mounted() {
    this.$vuetify.theme.dark = this.user.user.darkmode
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
})
</script>
