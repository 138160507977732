<template>
  <div>
    <management-list class="ma-2"></management-list>
  </div>
</template>

<script>
import Vue from 'vue'
import ManagementList from '../components/management/ManagementList.vue'

export default Vue.extend({
  name: 'PrimeUsers',
  components: {
    ManagementList,
  },
})
</script>
