<template>
  <div class="container">
    <div class="d-flex flex-column justify-center align-items-center">
      <v-img :src="logo" width="120px" height="120px" :contain="true"></v-img>
      <div class="auth-container">
        <v-form ref="form">
          <p class="mb-0 subtitle-2">Email</p>
          <v-text-field
            class="pt-0 mb-2"
            prepend-icon="mdi-email"
            v-model="email"
            type="text"
            :rules="emailRules"
            placeholder="Input Email"
          ></v-text-field>
          <div class="px-3 d-flex flex-sm-row flex-column-reverse justify-space-between">
            <a class="text-center subtitle-2 mt-3 text-sm-left" href="javascript:void(0)" @click="gotoLogin">
              &lt; Back to Login
            </a>
            <v-btn mt-auto color="primary" :loading="loading" :disabled="loading" @click="onForgot">
              Send verification code
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import logo from '../assets/logo/logo.svg'

export default {
  data() {
    return {
      logo,
      email: '',
      loading: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      passwordRules: [(v) => !!v || 'Password is required'],
    }
  },
  methods: {
    async onForgot() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const { email, forgotPassword } = this
          await forgotPassword({ email })
          this.loading = false
          this.clear()
        } catch (err) {
          this.loading = false
          this.clear()
        }
      }
    },
    toggleShowPass() {
      this.showPass = !this.showPass
    },
    gotoLogin() {
      this.$router.push('/')
    },
    ...mapActions('auth', ['forgotPassword', 'logout']),
    ...mapActions('alert', ['clear']),
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}

.align-items-center {
  align-items: center;
}

.auth-container {
  max-width: 430px;
  width: 80vw;
}
</style>
