<template>
  <v-container fluid>
    <v-row class="header">
      <v-col cols="12" sm="6">
        <v-card-title class="header-title">Create Account</v-card-title>
      </v-col>
      <v-col class="d-flex justify-center justify-sm-end" cols="12" sm="6">
        <v-btn class="mx-2" color="primary" :loading="load" :disabled="load" @click="onSave"> Save </v-btn>
        <v-btn class="mx-2" color="primary" @click="onRefresh"> Refresh </v-btn>
        <v-btn class="mx-2" color="primary" @click="gotoBack"> Return </v-btn>
      </v-col>
    </v-row>
    <div class="pt-15 pb-5">
      <v-row class="px-5">
        <v-col class="px-5 py-0" cols="12" sm="4">
          <v-select
            :items="types"
            :value="accountType"
            @change="setType"
            return-object
            dense
            outlined
            label="Select Account type"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider class="my-8" />
      <account-individual-add v-if="accountType === 'Individual'" ref="refresh" @changeLoad="load = $event" />
      <account-company-add v-else ref="refresh" @changeLoad="load = $event" />
    </div>
  </v-container>
</template>

<script>
import Vue from 'vue'
import AccountIndividualAdd from '../components/accounts/AccountIndividualAdd.vue'
import AccountCompanyAdd from '../components/accounts/AccountCompanyAdd.vue'

export default Vue.extend({
  name: 'CreateAccount',
  components: { AccountIndividualAdd, AccountCompanyAdd },
  data() {
    return {
      types: ['Individual', 'Company'],
      accountType: null,
      load: false,
    }
  },
  mounted() {
    this.accountType = this.types[0]
  },
  methods: {
    setType(value) {
      this.accountType = value
    },
    onSave() {
      this.$refs.refresh.onCreate()
    },
    gotoBack() {
      this.$router.push({ name: 'Accounts' })
    },
    onRefresh() {
      this.$refs.refresh.setRefresh()
    },
  },
})
</script>

<style scoped>
.header {
  padding: 4px 16px;
}
.header-title {
  font-size: 1.25rem;
  line-height: 1.5;
}
</style>
