<template>
  <div class="container">
    <div class="d-flex flex-column justify-center align-items-center">
      <v-img :src="logo" width="120px" height="120px" :contain="true"></v-img>
      <div class="auth-container">
        <v-form ref="form">
          <p class="mb-0 subtitle-2">Please Enter Verification Code</p>
          <v-otp-input v-model="otp" @finish="onFinish" length="6" type="number"></v-otp-input>
          <!-- <div class="px-3 d-flex flex-sm-row flex-column-reverse justify-space-between">
            <a class="text-center subtitle-2 mt-3 text-sm-left" href="javascript:void(0)" @click="gotoLogin">
              &lt; Back to Login
            </a>
            <v-btn mt-auto color="primary" :loading="loading" :disabled="loading" @click="onForgot">
              Send verification code
            </v-btn>
          </div> -->
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '../assets/logo/logo.svg'

export default {
  data() {
    return {
      logo,
      loading: false,
      otp: '',
    }
  },
  methods: {
    async onFinish() {
      console.log('finished')
      try {
        await this.$store.dispatch('auth/login2fa', {
          otp: this.otp,
        })
        this.dialog = false
      } catch (err) {}
    },
    gotoLogin() {
      this.$router.push('/')
    },
  },
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}

.align-items-center {
  align-items: center;
}

.auth-container {
  max-width: 430px;
  width: 80vw;
}
</style>
