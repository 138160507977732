
import Vue from 'vue'
import { mapGetters } from 'vuex'

export default Vue.extend({
  name: 'Setting',

  props: ['visible'],

  data: () => ({
    showIds: false,
    otp: '',
    mfa: false,
    show: false,
  }),
  mounted() {
    this.mfa = this.user ? this.user.user._2fa : false
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
    async saveFunction() {
      try {
        await this.$store.dispatch('management/settings', {
          darkmode: this.$vuetify.theme.dark,
        })
        this.dialog = false
      } catch (err) {}

      // this.settings({
      //   darkmode: this.$vuetify.theme.dark,
      // })
      //   .then(() => {
      //     this.dialog = false
      //   })
      //   .catch()
    },
    // ...mapActions('management', ['settings']),
    async mfaChanged() {
      try {
        if (this.mfa) {
          await this.$store.dispatch('management/activate2FA', {})
          this.show = true
        } else {
          await this.$store.dispatch('management/deactivate2FA', {})
          this.show = false
        }
      } catch (error) {}
    },
    getMFAQr() {
      return this.mfaResponse?.secret?.qr
    },
    async onFinish() {
      try {
        await this.$store.dispatch('management/confirm2FA', { otp: this.otp })
      } catch (error) {}
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      mfaResponse: 'management/getMFA',
    }),
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
})
