
import Vue from 'vue'
import OrdersV2 from '../components/orders/OrdersV2.vue'

export default Vue.extend({
  name: 'OrdersView',
  components: {
    OrdersV2,
  },
})
