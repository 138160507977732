import authService from '../services/auth.service'
import { getPrimeToken } from '@/helpers/primeAuth'
import { REGISTER_SUCCESS_MESSAGE, RESET_PASSWORD_SUCCESS_MESSAGE, CHANGE_PASSWORD_SUCCESS_MESSAGE } from '@/config/message'
import router from '../router'
import _ from 'lodash'

const user = JSON.parse(localStorage.getItem('user'))
const token = getPrimeToken()
const state = {
  user,
  token,
  loggedIn: token ? true : null,
  tempUser: null,
}

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  loggedIn: (state) => state.loggedIn,
  tempUser: (state) => state.tempUser,
}

const actions = {
  async login({ dispatch, commit }, { email, password }) {
    try {
      const data = await authService.capitalLogin(email, password)
      if (data.user._2fa) {
        commit('addTempUser', { user: { ...data.user, password }, token: data.otpToken })
        router.push({ name: 'MFA' })
      } else {
        commit('loginSuccess', { data, token: data.primeToken })
        router.push({ name: 'Home' })
      }
    } catch (error) {
      console.log(error)
      commit('loginFailure')
      localStorage.clear()
      dispatch('alert/error', error, { root: true })
      throw error
    }
  },
  async login2fa({ dispatch, commit }, { otp }) {
    try {
      const data = await authService.capital2faLogin({ otp, user: state.tempUser })
      commit('loginSuccess', { data, token: data.primeToken })
      router.push({ name: 'Home' })
    } catch (error) {
      console.log(error)
      commit('loginFailure')
      localStorage.clear()
      dispatch('alert/error', error, { root: true })
      throw error
    }
  },
  async register({ dispatch }, { userName, email, password }) {
    const avatarID = _.random(100000, 999999)
    return await authService
      .capitalRegister(avatarID, userName, `${userName}_${email}`, email, password)
      .then(() => {
        dispatch('alert/success', REGISTER_SUCCESS_MESSAGE, { root: true })
        return true
      })
      .catch((error) => {
        dispatch('alert/error', error, { root: true })
        return false
      })
  },
  async forgotPassword({ dispatch }, { email }) {
    try {
      await authService.capitalForgotPassword(email)
      dispatch('alert/success', RESET_PASSWORD_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      console.log(error)
      dispatch('alert/error', error, { root: true })
      throw error
    }
  },
  async changePassword({ dispatch }, { password, token }) {
    try {
      await authService.changePassword(password, token)
      dispatch('alert/success', CHANGE_PASSWORD_SUCCESS_MESSAGE, { root: true })
      return true
    } catch (error) {
      console.log(error)
      dispatch('alert/error', error, { root: true })
      throw error
    }
  },
  async updateLoggedUser({ commit }, data) {
    commit('updateLoggedUser', data)
    const keys = Object.keys(data)
    keys.forEach((key) => {
      user.user[key] = data[key]
    })
    localStorage.setItem('user', JSON.stringify(state.user))
  },
  logout({ commit }) {
    commit('logout')
    localStorage.clear()
    authService.logout()
    router.push({ name: 'Login' })
  },
}

const mutations = {
  addTempUser(state, { user, token }) {
    state.tempUser = { user, token }
  },
  loginSuccess(state, { data, token }) {
    state.status = { loggedIn: true }
    state.user = data
    state.token = token
    state.tempUser = null
  },
  updateLoggedUser(state, data) {
    const keys = Object.keys(data)
    keys.forEach((key) => {
      state.user.user[key] = data[key]
    })
  },
  loginFailure(state) {
    state.status = {}
    state.user = null
    state.token = null
  },
  logout(state) {
    state.status = {}
    state.user = null
    state.token = null
  },
}

export const auth = {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
