<template>
  <div>
    <v-form ref="form">
      <v-row class="mt-10 px-5">
        <v-col class="px-5 py-0" cols="12" sm="6">
          <v-text-field v-model="name" class="my-1" dense outlined label="Name *" :rules="requireRule"></v-text-field>
        </v-col>
        <v-col class="px-5 py-0" cols="12" sm="6">
          <v-autocomplete
            v-model="subType"
            :items="subTypeList"
            label="Entity Sub Type"
            dense
            outlined
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="px-5">
        <v-col class="px-5 py-0" cols="12" sm="6">
          <v-text-field v-model="email" class="my-1" dense outlined label="Email *" :rules="emailRules"></v-text-field>
        </v-col>
        <v-col class="px-5 py-0" cols="12" sm="6">
          <v-text-field
            v-model="phone"
            class="my-1"
            dense
            outlined
            type="tel"
            label="Phone *"
            hint="+19424633336"
            :rules="phoneRule"
            @keyup="validateString"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-8" />
      <v-row class="mt-10 px-5">
        <v-col class="px-5" cols="12" sm="6">
          <v-text-field
            v-model="taxID"
            class="my-1"
            dense
            outlined
            label="Tax ID Number/SSN *"
            :rules="taxIDRules"
          ></v-text-field>
          <v-autocomplete
            v-model="taxCountry"
            :items="countryList"
            @change="setTaxCountry"
            item-text="label"
            item-value="value"
            label="Tax Country *"
            dense
            outlined
            :rules="requireRule"
          ></v-autocomplete>
          <v-autocomplete
            v-model="taxRegion"
            :items="taxRegionList"
            v-if="taxCountry === 'US'"
            item-text="label"
            item-value="value"
            label="Tax State *"
            dense
            outlined
            :rules="requireRule"
          ></v-autocomplete>
          <v-autocomplete
            v-model="regionOfFormation"
            :items="regionOfFormationList"
            item-text="label"
            item-value="value"
            label="Region of formation *"
            dense
            outlined
            :rules="requireRule"
          ></v-autocomplete>
        </v-col>
        <v-col class="px-5" cols="12" sm="6">
          <v-autocomplete
            v-model="country"
            :items="countryList"
            @change="setCountry"
            item-text="label"
            item-value="value"
            label="Country *"
            dense
            outlined
            :rules="requireRule"
          ></v-autocomplete>
          <v-text-field
            v-model="street1"
            class="my-1"
            dense
            outlined
            label="Street Address *"
            :rules="requireRule"
          ></v-text-field>
          <v-text-field v-model="street2" class="my-1" dense outlined label="Street Address 2"></v-text-field>
          <v-text-field v-model="city" class="my-1" dense outlined label="City *" :rules="requireRule"></v-text-field>
          <v-autocomplete
            v-model="region"
            :items="regionList"
            item-text="label"
            item-value="value"
            label="State/Province/Region"
            dense
            outlined
          ></v-autocomplete>
          <v-text-field
            v-model="postalCode"
            class="my-1"
            dense
            outlined
            label="Postal Code *"
            :rules="postalRules"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider class="my-8" />
      <v-row class="mx-5">
        <v-col>
          <v-btn color="primary" @click="dialog = true"> + Add Related Contact </v-btn>
          <contact-list />
          <contact-add :visible="dialog" @close="dialog = false" />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { validate } from 'postal-codes-js'
import ContactList from './ContactList.vue'
import ContactAdd from './ContactAdd.vue'
import countries from '../../utils/country'
import { subTypeList } from '../../utils'

export default {
  components: { ContactList, ContactAdd },
  data() {
    return {
      dialog: false,
      name: '',
      subType: '',
      email: '',
      phone: '',
      taxID: '',
      taxCountry: '',
      taxRegion: '',
      regionOfFormation: '',
      country: '',
      street1: '',
      street2: '',
      city: '',
      region: '',
      postalCode: '',
      countryList: [],
      taxRegionList: [],
      regionOfFormationList: [],
      regionList: [],
      subTypeList: [],
      requireRule: [(v) => !!v || 'This field is required'],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      phoneRule: [
        (v) => !!v || 'Phone is required',
        (v) => (/^(\+?\d{1,3})?(-)?\d{9}$/.test(v) || /^(\+?\d{1,2})?(-)?\d{10}$/.test(v)) || 'Phone must be valid: +19424633336',
      ],
      taxIDRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          this.taxCountry !== 'US' ||
          (this.taxCountry === 'US' && v.length === 9) ||
          'Tax ID Number is not a valid US SSN or ITIN',
      ],
      postalRules: [(v) => this.country === '' || validate(this.country, v) || 'Postal Code must be valid'],
    }
  },
  mounted() {
    this.clearContact()
    this.subTypeList = subTypeList
    this.countryList = countries.map((country) => {
      return {
        label: `${country.countryName} (${country.countryShortCode})`,
        value: country.countryShortCode,
      }
    })
    let USRegions = []
    countries.map((country) => {
      if (country.countryShortCode === 'US') {
        USRegions = country.regions
      }
    })
    this.regionOfFormationList = USRegions.map((USRegion) => {
      return {
        label: `${USRegion.name} (${USRegion.shortCode})`,
        value: USRegion.shortCode,
      }
    })
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
  },
  methods: {
    validateString() {
      const regex = new RegExp('^[0-9+-]*$')
      if (regex.test(this.phone)) {
        if (this.phone.length > 14) {
          this.phone = this.phone.substring(0, this.phone.length - 1)
        }
      } else {
        this.phone = this.phone.substring(0, this.phone.length - 1)
      }
    },
    setTaxCountry(value) {
      if (value === 'US') {
        let taxRegions = []
        countries.map((country) => {
          if (country.countryShortCode === value) {
            taxRegions = country.regions
          }
        })
        this.taxRegionList = taxRegions.map((taxRegion) => {
          return {
            label: `${taxRegion.name} (${taxRegion.shortCode})`,
            value: taxRegion.shortCode,
          }
        })
      }
    },
    setCountry(value) {
      let regions = []
      countries.map((country) => {
        if (country.countryShortCode === value) {
          regions = country.regions
        }
      })
      this.regionList = regions.map((region) => {
        return {
          label: `${region.name} (${region.shortCode})`,
          value: region.shortCode,
        }
      })
    },
    setRefresh() {
      this.name = ''
      this.subType = ''
      this.email = ''
      this.phone = ''
      this.taxID = ''
      this.taxCountry = ''
      this.taxRegion = ''
      this.country = ''
      this.street1 = ''
      this.street2 = ''
      this.city = ''
      this.region = ''
      this.postalCode = ''
      this.$refs.form.reset()
    },
    onCreate() {
      if (this.$refs.form.validate()) {
        this.$emit('changeLoad', true)
        const param = {
          name: this.name,
          subType: this.subType,
          email: this.email,
          phone: this.phone,
          taxID: this.taxID,
          taxCountry: this.taxCountry,
          taxRegion: this.taxRegion,
          regionOfFormation: this.regionOfFormation,
          country: this.country,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          region: this.region,
          postalCode: this.postalCode,
        }
        this.createCompanyAccount(param)
          .then((res) => {
            this.clear()
            this.$emit('changeLoad', false)
            if (res) this.$router.push({ name: 'Accounts' })
          })
          .catch(() => {
            this.clear()
            this.$emit('changeLoad', false)
          })
      }
    },
    ...mapActions('accounts', ['createCompanyAccount']),
    ...mapActions('accounts', ['clearContact']),
    ...mapActions('alert', ['clear']),
  },
}
</script>
