
import { debounce, isEmpty } from 'lodash'
import { permissions, checkPermission } from '../../utils/permissions'
import Vue from 'vue'
import VueConfirmDialog from 'vue-confirm-dialog'

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default Vue.extend({
  name: 'UsersList',
  data: () => ({
    changeRoleDialog: false,
    user: null,
    selectedRole: null,
    permissions,
    checkPermission,
    search: '',
    requireRule: [(v) => !!v || 'This field is required'],
    snackbar: false,
    color: 'primary',
    text: '',
    headers: [
      {
        text: 'User ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Role',
        align: 'start',
        sortable: true,
        value: 'role',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
    loading: true,
    options: {} as { itemsPerPage: number; page: number; sortBy: string; sortDesc: [boolean] },
  }),
  methods: {
    changeRole() {
      this.$confirm({
        message: `Changing '${this.user.role}' to '${
          this.selectedRole
        }'. Are you sure?`,
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: async(confirm: boolean) => {
          if (confirm) {
            await this._changeRole()
          }
        },
      })
    },
    async getUsers() {
      this.loading = true
      const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options

      await this.$store.dispatch('manageUsers/getUsers', {
        limit: limit === -1 ? this.totalResults : limit,
        page,
        sortBy: `${isEmpty(sortBy) ? 'createdAt' : sortBy}:${sortDesc && sortDesc[0] ? 'asc' : 'desc'}`,
        search: this.search,
      })

      this.loading = false
    },
    openChangeRoleDialog(user) {
      this.changeRoleDialog = true
      this.user = user
      this.selectedRole = user.role
    },
    async _changeRole() {
      this.loading = true

      try {
        await this.$store.dispatch('manageUsers/updateUser', {
          userId: this.user.id,
          role: this.selectedRole,
        })
        this.color = 'primary'
        this.text = 'User role successfully changed'
        this.changeRoleDialog = false
      } catch (err) {
        this.color = 'error'
        this.text = err.message
      }
      this.snackbar = true
      this.loading = false
    },
  },
  computed: {
    users() {
      return this.$store.state.manageUsers.users
    },
    totalResults() {
      return this.$store.getters['manageUsers/totalResults']
    },
    rolesList() {
      return [
        { text: 'super', value: 'super' },
        { text: 'admin', value: 'admin' },
        { text: 'user', value: 'user' },
        { text: 'preAdmin', value: 'preAdmin' },
        { text: 'adminRep', value: 'adminRep' },
        { text: 'rep1', value: 'rep1' },
        { text: 'rep2', value: 'rep2' },
        { text: 'customer', value: 'customer' },
        { text: 'dev1', value: 'dev1' },
        { text: 'preDev1', value: 'preDev1' },
      ]
    },
  },
  watch: {
    options: {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
    search: {
      handler() {
        const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options
        const bounced = debounce(() => {
          return this.$store.dispatch('manageUsers/getUsers', {
            limit,
            page,
            sortBy: `${sortBy}:${sortDesc && sortDesc[0] ? 'desc' : 'asc'}`,
            search: this.search,
          })
        }, 300)
        bounced()
      },
    },
  },
})
