import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import moment from 'moment'
import DatetimePicker from 'vuetify-datetime-picker'
import VueGtm from '@gtm-support/vue2-gtm'

Vue.config.productionTip = false

Vue.use(DatetimePicker)

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GTM_ID,
  defer: false,
  compatibility: false,
  enabled: true,
  debug: process.env.VUE_APP_DEBUG,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
})

Vue.filter('formatDate', (value: string) => {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
