
import Vue from 'vue'

export default Vue.extend({
  name: 'StockOrdersV2',

  props: {
    order: {
      type: Object,
      default: () => {
        return { stockOrders: [] }
      },
    },
  },
  data: () => ({
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'Apex Order Ref',
        align: 'start',
        sortable: false,
        value: 'apexOrderRef',
      },
      {
        text: 'Symbol',
        align: 'start',
        sortable: false,
        value: 'symbol',
      },
      {
        text: 'Status',
        align: 'start',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Type',
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: 'Executed Quantity',
        align: 'start',
        sortable: false,
        value: 'execQty',
      },
      {
        text: 'Expected Value',
        align: 'start',
        sortable: false,
        value: 'expectedValue',
      },
      {
        text: 'Price',
        align: 'start',
        sortable: false,
        value: 'price',
      },
      {
        text: 'Quantity',
        align: 'start',
        sortable: false,
        value: 'quantity',
      },
      {
        text: 'Created At',
        align: 'start',
        sortable: false,
        value: 'createdAt',
      },
    ],
    loading: false,
  }),
  computed: {
    stockOrders() {
      return this.order.stockOrders
    },
  },
  methods: {},
})
