<template>
  <v-container fluid>
    <div v-if="current.createdAt">
      <v-row class="header">
        <v-col class="d-flex flex-row justify-space-between" cols="12">
          <div class="d-flex align-center">
            <v-card-title>Edit Account (Individual)</v-card-title>
            <span :class="'ml-2 px-1 badge status-' + current.status.toLowerCase()">
              {{ current.status }}
            </span>
          </div>
          <div>
            <v-btn @click="goBack" color="white" outlined :loading="load" :disabled="load" class="mr-10 primary--text">Cancel & Return To Accounts</v-btn>
            <v-btn class="mx-2" color="primary" :loading="load" :disabled="load" @click="onSave"> Finish & Save </v-btn>
            <!-- <v-btn class="mx-2" color="error" outlined @click="handleCloseAccount" v-if="current.status === 'opened'">
              Close Account
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <div class="pt-10">
        <v-form ref="form">
          <v-row>
            <v-col class="d-flex flex-row justify-sm-start justify-space-between" cols="12">
              <div class="mx-12">
                <v-btn class="mt-5" color="primary" @click="onResendAgreementEmail" :loading="loading" :disabled="loading" block>
                  Resend the customers agreement email
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="px-10 d-flex flex-row justify-sm-start justify-space-between" cols="12">
              <div class="mx-4">
                <div class="font-weight-bold">Account Number:</div>
                <div>{{ current.number }}</div>
              </div>
              <div class="mx-4">
                <div class="font-weight-bold">Registration date:</div>
                <div>{{ current.createdAt.slice(0, 10) }}</div>
              </div>
              <div class="mx-4 d-sm-block d-none">
                <div class="font-weight-bold">CIP:</div>
                <span :class="'ml-2 px-1 badge status-' + current.cip.toLowerCase()">
                  {{ current.cip }}
                </span>
              </div>
              <div class="mx-4 d-sm-block d-none">
                <div class="font-weight-bold">AML:</div>
                <span :class="'ml-2 px-1 badge status-' + current.aml.toLowerCase()">
                  {{ current.aml }}
                </span>
              </div>
            </v-col>
            <v-col class="px-10 d-flex flex-row d-sm-none justify-space-between" cols="12">
              <div class="mx-4">
                <div class="font-weight-bold">CIP:</div>
                <span :class="'ml-2 px-1 badge status-' + current.cip.toLowerCase()">
                  {{ current.cip }}
                </span>
              </div>
              <div class="mx-4">
                <div class="font-weight-bold">AML:</div>
                <span :class="'ml-2 px-1 badge status-' + current.aml.toLowerCase()">
                  {{ current.aml }}
                </span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-8 mt-4" />
          <v-row class="mt-10 px-5">
            <v-col class="px-5 py-0" cols="12" sm="4">
              <v-text-field
                class="my-1"
                dense
                outlined
                label="First Name *"
                v-model="firstName"
                :rules="requireRule.concat(nameRule)"
                disabled
              ></v-text-field>
            </v-col>
            <v-col class="px-5 py-0" cols="12" sm="4">
              <v-text-field class="my-1" dense outlined label="Middle Name" v-model="middleName" :rules="nameRule" disabled></v-text-field>
            </v-col>
            <v-col class="px-5 py-0" cols="12" sm="4">
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Last Name *"
                v-model="lastName"
                :rules="requireRule.concat(nameRule)"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-5">
            <v-col class="px-5 py-0" cols="12" sm="4">
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Email *"
                v-model="email"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col class="px-5 py-0" cols="12" sm="4">
              <v-text-field
                v-model="phone"
                class="my-1"
                dense
                outlined
                type="tel"
                label="Phone *"
                hint="+19424633336"
                :rules="phoneRule"
                @keyup="validateString"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-8" />
          <v-row class="mt-10">
            <v-col class="px-10" cols="12" sm="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="birthday"
                    label="Date of Birth *"
                    persistent-hint
                    dense
                    outlined
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                    @blur="date = parseDate(birthday)"
                    :rules="birthRules"
                    @click:append="menu = true"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
              </v-menu>
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Tax ID Number/SSN *"
                v-model="taxID"
                :rules="taxIDRules"
                disabled
              ></v-text-field>
              <v-autocomplete
                :items="countryList"
                @change="setTaxCountry"
                item-text="label"
                item-value="value"
                label="Tax Country *"
                dense
                outlined
                v-model="taxCountry"
                :rules="requireRule"
                disabled
              ></v-autocomplete>
              <v-autocomplete
                :items="taxRegionList"
                v-if="taxCountry === 'US'"
                item-text="label"
                item-value="value"
                label="Tax State *"
                dense
                outlined
                v-model="taxRegion"
                :rules="requireRule"
              ></v-autocomplete>
            </v-col>
            <v-col class="px-10" cols="12" sm="6">
              <v-autocomplete
                :items="countryList"
                @change="setCountry"
                item-text="label"
                item-value="value"
                label="Country *"
                dense
                outlined
                v-model="country"
                :rules="requireRule"
              ></v-autocomplete>
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Street Address *"
                v-model="street1"
                :rules="requireRule"
              ></v-text-field>
              <v-text-field class="my-1" dense outlined label="Street Address 2" v-model="street2"></v-text-field>
              <v-text-field
                v-model="city"
                class="my-1"
                dense
                outlined
                label="City *"
                :rules="requireRule"
              ></v-text-field>
              <v-autocomplete
                :items="regionList"
                item-text="label"
                item-value="value"
                label="State/Province/Region"
                dense
                outlined
                v-model="region"
              ></v-autocomplete>
              <v-text-field
                class="my-1"
                dense
                outlined
                label="Postal Code *"
                v-model="postalCode"
                :rules="postalRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <v-divider class="mb-8" />
      <div class="subtitle-1">Uploaded Documents</div>
      <v-row class="mb-5 mx-3">
        <v-col class="mt-5" cols="12" md="6">
          <v-file-input counter show-size outlined @change="onUploadDocument" label="Upload Document"
          accept="application/pdf, image/bmp, image/heif, image/heic, image/jpeg, image/jpg, image/png, image/tiff, image/webp"
          multiple
          :rules="fileRules"
          ></v-file-input>
        </v-col>
        <v-col cols="12" md="6">
          <v-data-table
            :headers="headers"
            :items="documents"
            :options.sync="pagination"
            :server-items-length="totalResults"
            class="elevation-1 pt-list"
            :loading="loading"
            loading-text="Loading... Please wait"
          >
          </v-data-table>
        </v-col>
      </v-row>
      <v-divider class="mb-8" />
      <div class="subtitle-1">Document Checks</div>
      <v-form ref="kycform">
        <v-row class="mb-15 mx-3 mt-5">
          <v-col cols="12" md="4">
            <v-select
              :items="documentTypes"
              item-text="label"
              item-value="value"
              label="Document Type *"
              dense
              outlined
              v-model="docType"
              :rules="requireRule"
            ></v-select>
            <v-text-field
              dense
              outlined
              label="Specify Document Type*"
              v-if="docType === 'other'"
              v-model="otherDocType"
              :rules="requireRule"
            ></v-text-field>
            <v-select
              :items="countryList"
              item-text="label"
              item-value="value"
              label="Document Country *"
              dense
              outlined
              v-model="docCountry"
              :rules="requireRule"
            ></v-select>
            <v-select
              :items="allDocuments"
              item-text="fileName"
              item-value="documentID"
              label="Select Document *"
              no-data-text="Upload documents to get them listed here"
              dense
              outlined
              v-model="docID"
              :rules="requireRule"
            ></v-select>
            <v-select
              :items="allDocuments"
              item-text="fileName"
              item-value="documentID"
              no-data-text="Upload documents to get them listed here"
              label="Select Back of Document *"
              dense
              outlined
              v-if="docType === 'drivers_license' || docType === 'residence_permit' || docType === 'government_id'"
              v-model="docBackID"
              :rules="requireRule"
            ></v-select>
            <v-btn class="mt-5" color="primary" @click="onCheckDocument" :loading="loading" :disabled="loading" block>
              Document Check
            </v-btn>
          </v-col>
          <v-col cols="12" md="8">
            <v-simple-table fixed-header>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Document Type</th>
                    <th class="text-left">File Name</th>
                    <th class="text-left">Country</th>
                    <th class="text-left">Status</th>
                    <th class="text-left">Identity</th>
                    <th class="text-left">Address</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in processedChecks" :key="item.name">
                    <td>{{ item.documentType }}</td>
                    <td>
                      <a :href="item.fileUrl" target="_black">
                        {{ item.fileName }}
                      </a>
                      <br />
                      <span v-if="item.backFileID">
                        <a :href="item.backFileUrl" target="_black">
                          {{ item.backFileName }}
                        </a>
                      </span>
                    </td>
                    <td>{{ item.country }}</td>
                    <td>
                      <div class="badge status-pending" v-if="item.status === 'pending'">
                        {{ item.status }}
                      </div>
                      <div class="badge status-approved" v-else-if="item.status === 'approved'">
                        {{ item.status }}
                      </div>
                      <div class="badge status-verified" v-else-if="item.status === 'verified'">
                        {{ item.status }}
                      </div>
                      <div class="badge status-closed" v-else>
                        {{ item.status }}
                      </div>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox v-model="item.proofIdentity" disabled></v-simple-checkbox>
                    </td>
                    <td class="text-center">
                      <v-simple-checkbox v-model="item.proofAddress" disabled></v-simple-checkbox>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-form>
      <v-divider class="mb-8" />
      <div class="subtitle-1">Download Statements</div>
      <v-row class="mb-15 mx-3 mt-5">
        <v-col cols="12" md="6">
          <v-simple-table fixed-header v-if="statements.length > 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Month</th>
                  <th class="text-left">Year</th>
                  <th class="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in statements" :key="item.id">
                  <td>{{ item.monthName }}</td>
                  <td>{{ item.year }}</td>
                  <td class="text-right">
                    <v-btn
                      color="blue-grey"
                      class="ma-2 white--text"
                      @click="downloadStatement({ ...item, format: 'csv' })"
                    >
                      CSV
                      <v-icon right dark> mdi-cloud-download </v-icon>
                    </v-btn>
                    <v-btn
                      color="orange"
                      class="ma-2 white--text"
                      @click="downloadStatement({ ...item, format: 'pdf' })"
                    >
                      PDF
                      <v-icon right dark> mdi-cloud-download </v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <template v-else>
            <p>No data to display</p>
          </template>
        </v-col>
      </v-row>
      <v-dialog v-model="uploading" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Uploading ...
            <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
    <div class="d-flex justify-center align-center vh-100" v-else>
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <v-dialog v-model="dialog" max-width="390">
      <v-card>
        <v-card-title class="text-h5 justify-center">
          <v-icon large color="blue darken-2"> mdi-exclamation-thick</v-icon>
        </v-card-title>
        <v-card-title class="text-h5 justify-center"> Are you sure? </v-card-title>
        <v-card-text class="text-center"> This account will be closed. </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="handleCancelClick"> cancel </v-btn>
          <v-btn color="primary" :loading="load" :disabled="load" @click="handleAgreeClick"> yes, agree </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statementsDownloadDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Statement Download </v-card-title>
        <v-card-text>Your statement is Ready!</v-card-text>
        <v-card-actions class="justify-center">
          <v-spacer></v-spacer>
          <v-btn color="primary" text :href="statementLink" @click="statementsDownloadDialog = false" download>
            Download
          </v-btn>
          <v-btn color="grey" text @click="statementsDownloadDialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { validate } from 'postal-codes-js'
import countries from '../../utils/country'
import { documentTypes } from '../../utils'
import { isFileTypeAllowed } from '../../config/validationRules'
export default Vue.extend({
  name: 'AccountIndividualEdit',
  data() {
    return {
      headers: [
        { text: 'File Type', value: 'fileType', sortable: false },
        { text: 'File Name', value: 'fileName', sortable: false },
        { text: 'Uploaded Date', value: 'updatedAt', sortable: false },
      ],
      search: '',
      pagination: {
        itemsPerPage: 5,
        page: 1,
      },
      load: false,
      dialog: false,
      current: {},
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      taxID: '',
      taxCountry: '',
      taxRegion: '',
      country: '',
      street1: '',
      street2: '',
      city: '',
      region: '',
      postalCode: '',
      docType: '',
      docCountry: '',
      otherDocType: '',
      docID: '',
      docBackID: '',
      uploading: false,
      loading: false,
      documentTypes: documentTypes,
      countryList: [],
      taxRegionList: [],
      regionList: [],
      requireRule: [(v) => !!v || 'This field is required'],
      fileRules: [
        value => !value || value.every(file => file.size <= 5242880) || 'File size should be less than 5 MB',
        value => !value || value.every(file => isFileTypeAllowed(file.type)) || 'Only images and PDF documents are allowed',
        value => !value || value.length <= 5 || 'Only 5 documents upload at once are allowed',
      ],
      nameRule: [
        (v) => /^(?!.*[^a-zA-Z0-9 -])/.test(v) || 'This field should not contain special characters',
        (v) => (v && v.length <= 40) || 'Name must be less than 40 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      phoneRule: [
        (v) => !!v || 'Phone is required',
        (v) => (/^(\+?\d{1,3})?(-)?\d{9}$/.test(v) || /^(\+?\d{1,2})?(-)?\d{10}$/.test(v)) || 'Phone must be valid: +19424633336',
      ],
      birthRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          (this.calculateAge(new Date(v)) >= 18 && this.calculateAge(new Date(v)) <= 99) ||
          'Must be between the ages of 18 and 99',
      ],
      taxIDRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          this.taxCountry !== 'US' ||
          (this.taxCountry === 'US' && v.length === 9) ||
          'Tax ID Number is not a valid US SSN or ITIN',
      ],
      postalRules: [(v) => this.country === '' || validate(this.country, v) || 'Postal Code must be valid'],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      birthday: '',
      menu: false,
      statements: [],
      statementsDownloadDialog: false,
      statementLink: null,
    }
  },
  created() {
    this.getCurrentAccount({ id: this.$route.params.id }).then(() => {
      this.current = this.currentAccount
      this.setCountry(this.current.country)
      this.setTaxCountry(this.current.taxCountry)
      this.setInitial(this.current)
      this.setStatementsMonthRange(new Date(Date.parse(this.current.createdAt)))
    })
    this.getCurrentAccountDocuments({
      id: this.$route.params.id,
      pagination: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.page,
      }
    })
    this.getCurrentAccountAllDocuments({
      id: this.$route.params.id,
    })
  },
  mounted() {
    this.countryList = countries.map((country) => {
      return {
        label: `${country.countryName} (${country.countryShortCode})`,
        value: country.countryShortCode,
      }
    })
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    ...mapGetters({
      currentAccount: 'accounts/currentAccount',
      allDocuments: 'accounts/allDocuments',
      documents: 'accounts/documents',
      checks: 'accounts/checks',
    }),
    processedChecks() {
      return this.checks.map((check) => {
        return {
          ...check,
          documentType: (documentTypes.find(doc => doc.value === check.documentType) || {}).label,
        }
      })
    },
    totalResults() {
      return this.$store.state.accounts.documentsPagination.totalResults
    },
    params() {
      return {
        ...this.pagination,
        query: this.search
      }
    },
  },
  methods: {
    validateString() {
      const regex = new RegExp('^[0-9+-]*$')
      if (regex.test(this.phone)) {
        if (this.phone.length > 14) {
          this.phone = this.phone.substring(0, this.phone.length - 1)
        }
      } else {
        this.phone = this.phone.substring(0, this.phone.length - 1)
      }
    },
    goBack() {
      this.$router.back()
    },
    async downloadStatement(item) {
      console.log(`>> downloadStatement params=${JSON.stringify({ ...item, accountID: this.current.accountID })}`)
      try {
        const link = await this.getStatementLink({
          accountID: this.current.accountID,
          month: item.month,
          year: item.year,
          format: item.format,
        })
        console.log(`<< link=${link}`)

        this.statementLink = link
        this.statementsDownloadDialog = true
      } catch (err) {
        console.log('Could not obtain download statement link from PT')
      }
    },
    setTaxCountry(value) {
      if (value === 'US') {
        let taxRegions = []
        countries.map((country) => {
          if (country.countryShortCode === value) {
            taxRegions = country.regions
          }
        })
        this.taxRegionList = taxRegions.map((taxRegion) => {
          return {
            label: `${taxRegion.name} (${taxRegion.shortCode})`,
            value: taxRegion.shortCode,
          }
        })
      }
    },
    setCountry(value) {
      let regions = []
      countries.map((country) => {
        if (country.countryShortCode === value) {
          regions = country.regions
        }
      })
      this.regionList = regions.map((region) => {
        return {
          label: `${region.name} (${region.shortCode})`,
          value: region.shortCode,
        }
      })
    },
    setStatementsMonthRange(startDate, endDate = new Date()) {
      const statements = []
      const month = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ]
      const startYear = startDate.getFullYear()
      const endYear = endDate.getFullYear()
      for (let curYear = startYear; curYear <= endYear; curYear += 1) {
        let _startMonth = 0
        let _endMonth = 11
        if (curYear === endYear) {
          _endMonth = endDate.getMonth()
          if (startYear === endYear) {
            _startMonth = startDate.getMonth()
          }
        } else {
          _startMonth = startDate.getMonth()
        }
        for (
          let curMonth = _startMonth;
          curMonth <= (curMonth === _endMonth && curYear === endYear ? _endMonth - 1 : _endMonth);
          curMonth += 1
        ) {
          statements.push({ year: curYear, month: curMonth + 1, monthName: month[curMonth] })
        }
      }
      this.statements = statements
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    calculateAge(birthday) {
      const ageDifMs = Date.now() - birthday.getTime()
      const ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    setInitial(current) {
      this.firstName = current.name.split(' ')[0]
      this.middleName = current.name.split(' ')[1]
      this.lastName = current.name.split(' ')[2]
      this.email = current.email
      this.phone = current.phone
      this.birthday = current.birthday
      this.taxID = current.taxID
      this.taxCountry = current.taxCountry
      this.taxRegion = current.taxRegion
      this.country = current.country
      this.street1 = current.street1
      this.street2 = current.street2
      this.city = current.city
      this.region = current.region
      this.postalCode = current.postalCode
    },
    setRefresh() {
      this.firstName = ''
      this.middleName = ''
      this.lastName = ''
      this.email = ''
      this.phone = ''
      this.birthday = ''
      this.taxID = ''
      this.taxCountry = ''
      this.taxRegion = ''
      this.country = ''
      this.street1 = ''
      this.street2 = ''
      this.city = ''
      this.region = ''
      this.postalCode = ''
    },
    onSave() {
      if (this.$refs.form.validate()) {
        this.load = true
        const param = {
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          birthday: this.birthday,
          taxID: this.taxID,
          taxCountry: this.taxCountry,
          taxRegion: this.taxRegion,
          country: this.country,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          region: this.region,
          postalCode: this.postalCode,
        }
        this.updateIndividualAccount({
          ...param,
          id: this.current._id,
          contactID: this.current.contactID,
        })
          .then(() => {
            this.load = false
            setTimeout(() => {
              this.clear()
            }, 2000)
            this.$router.push({ name: 'Accounts' })
          })
          .catch(() => {
            this.load = false
            setTimeout(() => {
              this.clear()
            }, 2000)
          })
      }
    },
    onUploadDocument(files) {
      if (files.length) {
        if (files.some(file => file.size > 5242880) ||
            files.some(file => !isFileTypeAllowed(file.type)) ||
            files.length > 5) {
          return
        }
        const { current, uploadDocument } = this
        this.uploading = true
        uploadDocument({
          files,
          contactID: current.contactID,
        })
          .then(() => {
            this.getCurrentAccountDocuments({
              id: this.$route.params.id,
              pagination: {
                limit: this.pagination.itemsPerPage,
                page: this.pagination.page,
              }
            }).then(() => {
              this.uploading = false
            }).catch(() => {
              this.uploading = false
            })
          })
          .catch(() => {
            this.uploading = false
          })
      }
    },
    onCheckDocument() {
      if (this.$refs.kycform.validate()) {
        this.loading = true
        const param = {
          docType: this.docType,
          docCountry: this.docCountry,
          otherDocType: this.otherDocType,
          docID: this.docID,
          docBackID: this.docBackID,
        }
        this.documentCheck({
          ...param,
          contactID: this.current.contactID,
        })
          .then(() => {
            this.loading = false
            this.docType = null
            this.otherDocType = null
            this.docCountry = null
            this.docID = null
            this.docBackID = null
            this.$refs.kycform.reset()
            this.clear()
          })
          .catch(() => {
            this.loading = false
            this.clear()
          })
      }
    },
    onResendAgreementEmail() {
      const { current, resendAgreementEmail } = this
      this.load = true
      resendAgreementEmail({ contactID: current.accountID })
        .then(() => {
          this.load = false
          setTimeout(() => {
            this.clear()
          }, 2000)
        })
        .catch(() => {
          this.load = false
          setTimeout(() => {
            this.clear()
          }, 2000)
        })
    },
    getDate(date) {
      return date.replace('T', ' ').slice(0, 19)
    },
    handleCloseAccount() {
      this.dialog = true
    },
    handleCancelClick() {
      this.dialog = false
    },
    handleAgreeClick() {
      this.load = true
      this.deleteAccount({ id: this.current._id })
        .then(() => {
          this.dialog = false
          this.load = false
          this.$router.push({ name: 'AccountList' })
        })
        .catch(() => {
          this.dialog = false
          this.load = false
        })
    },
    ...mapActions('accounts', [
      'getCurrentAccount',
      'getCurrentAccountDocuments',
      'getCurrentAccountAllDocuments',
      'updateIndividualAccount',
      'deleteAccount',
      'uploadDocument',
      'documentCheck',
      'getStatementLink',
      'resendAgreementEmail'
    ]),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    params: {
      handler() {
        this.getCurrentAccountDocuments({
          id: this.$route.params.id,
          pagination: {
            limit: this.pagination.itemsPerPage,
            page: this.pagination.page,
          }
        })
      },
      deep: true
    }
  },
})
</script>
