<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Add New Contact</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="px-15">
          <v-form ref="form">
            <v-row class="mt-10 px-5">
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="First Name *"
                  v-model="firstName"
                  :rules="requireRule.concat(nameRule)"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field class="my-1" dense outlined label="Middle Name" v-model="middleName"></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Last Name *"
                  v-model="lastName"
                  :rules="requireRule.concat(nameRule)"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="px-5">
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Email *"
                  v-model="email"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  v-model="phone"
                  class="my-1"
                  dense
                  outlined
                  type="tel"
                  label="Phone *"
                  hint="+19424633336"
                  :rules="phoneRule"
                  @keyup="validateString"
                ></v-text-field>
              </v-col>
              <v-col class="px-5 py-0" cols="12" sm="4">
                <v-text-field
                  v-model="label"
                  class="my-1"
                  dense
                  outlined
                  label="Label *"
                  :rules="requireMaxRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="my-8" />
            <v-row class="mt-10">
              <v-col class="px-10" cols="12" sm="6">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="birthday"
                      label="Date of Birth (YYYY-MM-DD)*"
                      persistent-hint
                      dense
                      outlined
                      append-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                      @blur="date = parseDate(birthday)"
                      :rules="birthRules"
                      @click:append="menu = true"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
                </v-menu>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Tax ID Number/SSN *"
                  v-model="taxID"
                  :rules="taxIDRules"
                ></v-text-field>
                <v-autocomplete
                  :items="countryList"
                  @change="setTaxCountry"
                  item-text="label"
                  item-value="value"
                  label="Tax Country *"
                  dense
                  outlined
                  v-model="taxCountry"
                  :rules="requireRule"
                ></v-autocomplete>
                <v-autocomplete
                  :items="taxRegionList"
                  v-if="taxCountry === 'US'"
                  item-text="label"
                  item-value="value"
                  label="Tax State *"
                  dense
                  outlined
                  v-model="taxRegion"
                  :rules="requireRule"
                ></v-autocomplete>
              </v-col>
              <v-col class="px-10" cols="12" sm="6">
                <v-autocomplete
                  :items="countryList"
                  @change="setCountry"
                  item-text="label"
                  item-value="value"
                  label="Country *"
                  dense
                  outlined
                  v-model="country"
                  :rules="requireRule"
                ></v-autocomplete>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Street Address *"
                  v-model="street1"
                  :rules="requireRule"
                ></v-text-field>
                <v-text-field class="my-1" dense outlined label="Street Address 2" v-model="street2"></v-text-field>
                <v-text-field
                  v-model="city"
                  class="my-1"
                  dense
                  outlined
                  label="City *"
                  :rules="requireRule"
                ></v-text-field>
                <v-autocomplete
                  :items="regionList"
                  item-text="label"
                  item-value="value"
                  label="State/Province/Region"
                  dense
                  outlined
                  v-model="region"
                ></v-autocomplete>
                <v-text-field
                  class="my-1"
                  dense
                  outlined
                  label="Postal Code *"
                  v-model="postalCode"
                  :rules="postalRule"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mx-10 d-flex justify-center justify-sm-end">
                <v-btn color="primary" :loading="load" :disabled="load" @click="onSave"> Save </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
import { validate } from 'postal-codes-js'
import _ from 'lodash'
import countries from '@/utils/country'
export default {
  data() {
    return {
      load: false,
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      phone: '',
      label: '',
      taxID: '',
      taxCountry: '',
      taxRegion: '',
      country: '',
      street1: '',
      street2: '',
      city: '',
      region: '',
      postalCode: '',
      countryList: [],
      taxRegionList: [],
      regionList: [],
      requireRule: [(v) => !!v || 'This field is required'],
      requireMaxRule: [
        (v) => !!v || 'This field is required',
        (v) => (v && v.length <= 100) || 'Field must be less than 100 characters',
      ],
      nameRule: [
        (v) => /^(?!.*[^a-zA-Z0-9 -])/.test(v) || 'This field should not contain special characters',
        (v) => (v && v.length <= 40) || 'Name must be less than 40 characters',
      ],
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
      ],
      phoneRule: [
        (v) => !!v || 'Phone is required',
        (v) => (/^(\+?\d{1,3})?(-)?\d{9}$/.test(v) || /^(\+?\d{1,2})?(-)?\d{10}$/.test(v)) || 'Phone must be valid: +19424633336',
      ],
      taxIDRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          this.taxCountry !== 'US' ||
          (this.taxCountry === 'US' && v.length === 9) ||
          'Tax ID Number is not a valid US SSN or ITIN',
      ],
      postalRule: [(v) => this.country === '' || validate(this.country, v) || 'Postal Code must be valid'],
      birthRules: [
        (v) => !!v || 'This field is required',
        (v) =>
          (this.calculateAge(new Date(v)) >= 18 && this.calculateAge(new Date(v)) <= 99) ||
          'Must be between the ages of 18 and 99',
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      birthday: '',
      menu: false,
    }
  },
  props: ['visible', 'edit', 'accountID'],
  mounted() {
    this.countryList = countries.map((country) => {
      return {
        label: `${country.countryName} (${country.countryShortCode})`,
        value: country.countryShortCode,
      }
    })
    window.addEventListener('resize', () => {
      this.menu = false
    })
  },
  computed: {
    dialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },
  },
  methods: {
    validateString() {
      const regex = new RegExp('^[0-9+-]*$')
      if (regex.test(this.phone)) {
        if (this.phone.length > 14) {
          this.phone = this.phone.substring(0, this.phone.length - 1)
        }
      } else {
        this.phone = this.phone.substring(0, this.phone.length - 1)
      }
    },
    closeDialog() {
      this.dialog = false
      this.$refs.form.reset()
    },
    setTaxCountry(value) {
      if (value === 'US') {
        let taxRegions = []
        countries.map((country) => {
          if (country.countryShortCode === value) {
            taxRegions = country.regions
          }
        })
        this.taxRegionList = taxRegions.map((taxRegion) => {
          return {
            label: `${taxRegion.name} (${taxRegion.shortCode})`,
            value: taxRegion.shortCode,
          }
        })
      }
    },
    setCountry(value) {
      let regions = []
      countries.map((country) => {
        if (country.countryShortCode === value) {
          regions = country.regions
        }
      })
      this.regionList = regions.map((region) => {
        return {
          label: `${region.name} (${region.shortCode})`,
          value: region.shortCode,
        }
      })
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    calculateAge(birthday) {
      const ageDifMs = Date.now() - birthday.getTime()
      const ageDate = new Date(ageDifMs) // miliseconds from epoch
      return Math.abs(ageDate.getUTCFullYear() - 1970)
    },
    onSave() {
      if (this.$refs.form.validate()) {
        const name = `${this.firstName} ${this.middleName} ${this.lastName}`
        const param = {
          name,
          accountID: this.accountID,
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          label: this.label,
          birthday: this.birthday,
          taxID: this.taxID,
          taxCountry: this.taxCountry,
          taxRegion: this.taxRegion,
          country: this.country,
          street1: this.street1,
          street2: this.street2,
          city: this.city,
          region: this.region,
          postalCode: this.postalCode,
        }
        const index = _.random(10, 99)
        if (this.edit === 'true') {
          this.load = true
          const newContact = {
            ...param,
          }
          this.addContacts({ newContact })
            .then(() => {
              this.load = false
              this.dialog = false
              this.clear()
              this.$refs.form.reset()
            })
            .catch(() => {
              this.load = false
              this.dialog = false
              this.clear()
            })
        } else {
          const newContact = {
            index,
            ...param,
          }
          this.addNewContact({ newContact })
          this.$refs.form.reset()
          this.dialog = false
        }
      }
    },
    ...mapActions('accounts', ['addNewContact', 'addContacts']),
    ...mapActions('alert', ['clear']),
  },
  watch: {
    date() {
      this.birthday = this.formatDate(this.date)
    },
  },
}
</script>
