import axios from 'axios'
import { API_BASE_URL } from '../config/endpoints'
import { checkTokenExpire } from '../helpers/primeAuth'
import authHeader from './authHeader'
import { refreshToken } from '../services/auth.service'

export const axiosInstace = axios.create({
  baseURL: API_BASE_URL,
  timeout: 20000,
})

export const initAuthInterceptor = () => {
  axiosInstace.interceptors.request.use((request) => {
    const authToken = authHeader().ProxyToken
    const cgToken = authHeader().Authorization
    if (authToken && checkTokenExpire(authToken)) {
      request.headers.Authorization = authToken
      request.headers.ProxyToken = cgToken
    } else {
      localStorage.clear()
      delete request.headers.Authorization
      delete request.headers.ProxyToken
      location.reload()
    }
    return request
  })
  axiosInstace.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        const { config: originalRequest } = error
        return refreshToken().then((token) => {
          originalRequest.headers.ProxyToken = `Bearer ${token}`
          return axios(originalRequest)
            .then((result) => {
              return result
            })
            .catch(() => {
              localStorage.clear()
              location.reload()
            })
        })
      }
      return Promise.reject(error)
    }
  )
}

export const initMultiInterceptor = () => {
  axiosInstace.interceptors.request.use((response) => {
    response.headers['content-type'] = 'multipart/form-data'
    return response
  })
}

export const removeMultiInterceptor = () => {
  axiosInstace.interceptors.request.use((response) => {
    delete response.headers['content-type']
    return response
  })
}

export const primeApi = (requestType, url, payload) => {
  return new Promise((resolve, reject) => {
    axiosInstace[requestType](url, payload)
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const primeLoginApi = async(requestType, url, payload) => {
  return await axiosInstace[requestType](url, payload)
}
