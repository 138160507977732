
import { debounce, isEmpty, has, startCase } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import { permissions, checkPermission } from '../../utils/permissions'
import { passwordRules } from '../../config/validationRules'

export default Vue.extend({
  name: 'UsersList',
  data: () => ({
    permissions,
    checkPermission,
    search: '',
    headers: [
      {
        text: 'User ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'User Name',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Email',
        align: 'start',
        sortable: true,
        value: 'email',
      },
      {
        text: 'Date registered',
        align: 'start',
        sortable: true,
        value: 'createdAt',
      },
      {
        text: 'Capital Gain',
        align: 'start',
        value: 'capitalUser',
      },
      {
        text: 'Prime Trust',
        align: 'start',
        value: 'primeUser',
      },
      {
        text: 'Actions',
        align: 'start',
        value: 'actions',
        sortable: false,
      },
    ],
    loading: true,
    load: false,
    options: {} as { itemsPerPage: number; page: number; sortBy: string; sortDesc: [boolean] },
    createDialog: false,
    deleteDialog: false,

    name: '',
    email: '',
    emailRules: [
      (v) => !!v || 'Email is required',
      (v) => /^(.)+@[\w-]+?\.[a-zA-Z]{2,3}$/.test(v) || 'Email must be valid',
    ],
    role: 'rep1',
    roleRules: [
      (v: string) => !!v || 'Role is required',
    ],
    password: '',
    passwordRules,
    confirmPassword: '',
    showConfirmPass: true,
    showPassword: true,
    nameRules: [
      (v: string) => !!v || 'Name is required',
      (v: string) => (v && v.length <= 40) || 'Name must be less than 40 characters',
    ],
    avatarId: null,

    editUser: {
      name: ''
    },
  }),
  methods: {
    toggleShowNewPass() {
      this.showPassword = !this.showPassword
    },
    toggleShowConfirmPass() {
      this.showConfirmPass = !this.showConfirmPass
    },
    async getUsers() {
      this.loading = true
      // console.log(`<<< sortBy = ${JSON.stringify(this.options, null, 2)}`)
      const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options

      await this.$store.dispatch('management/getUsers', {
        limit: limit === -1 ? this.totalResults : limit,
        page,
        sortBy: `${isEmpty(sortBy) ? 'createdAt' : sortBy}:${sortDesc && sortDesc[0] ? 'asc' : 'desc'}`,
        search: this.search,
      })

      this.loading = false
    },
    editItem(item: { id: string }) {
      this.$router.push({ name: 'ManagementEdit', params: { id: item.id } })
    },
    deleteItem(item: { id: string }) {
      // console.log(`DELETE USER item=${JSON.stringify(item)}`)
      this.editUser = item
      this.deleteDialog = true
    },
    async deleteUserConfirm() {
      await this.$store.dispatch('management/deleteUser', this.editUser.id)
      this.closeDelete()
    },
    closeDelete() {
      this.deleteDialog = false
    },

    validateField() {
      this.$refs.form.validate()
    },
    async addNewUser() {
      if (this.$refs.form.validate()) {
        this.load = true
        try {
          await this.$store.dispatch('management/createNewUser', {
            name: this.name,
            email: this.email,
            password: this.password,
            avatarName: uuidv4(),
            avatarId: 0,
            capitalGain: true,
            primeTrust: true,
            role: this.role,
          })
          this.$store.dispatch('alert/success', 'User has been added')
          this.createDialog = false
        } catch (err) {
          this.$store.dispatch('alert/error', err.message)
        }
        this.load = false
      }
    },
    closeAddUserDialog() {
      this.avatarId = null
      this.$refs.form.reset()
      this.createDialog = false
    },
    hasBrokarageAccount(user) {
      return has(user, 'apex.account')
    },
    formatApexFormStatus(statusStr) {
      return startCase(statusStr)
    },
    getApexFormStatusColor(item) {
      switch (item.apexFormStatus) {
        case 'submitted':
          return 'blue'
        case 'custodian_complete':
          return 'green'
        case 'in_progress':
          return 'orange'
        default:
          return 'grey'
      }
    },
  },
  computed: {
    users() {
      return this.$store.state.management.users
    },
    rolesList() {
      return this.$store.getters['management/getRoles']
    },
    totalResults() {
      return this.$store.getters['management/totalResults']
    },
  },
  watch: {
    options: {
      handler() {
        this.getUsers()
      },
      deep: true,
    },
    search: {
      handler() {
        const { itemsPerPage: limit, page, sortBy, sortDesc } = this.options
        const bounced = debounce(() => {
          return this.$store.dispatch('management/getUsers', {
            limit,
            page,
            sortBy: `${sortBy}:${sortDesc && sortDesc[0] ? 'desc' : 'asc'}`,
            search: this.search,
            role: 'rep1',
          })
        }, 300)
        bounced()
      },
    },
  },
  mounted() {
    this.$store.dispatch('management/getRoles')
  }
})
