import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './auth.module'
import { portfolios } from './portfolios.module'
import { symbols } from './symbols.module'
import { alert } from './alert.module'
import { capitalUsers } from './capitalUsers.module'
import { common } from './common.module'
import { orders } from './orders.module'
import { accounts } from './accounts.module'
import { management } from './management.module'
import { manageUsers } from './manageUsers.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    capitalUsers,
    auth,
    portfolios,
    symbols,
    alert,
    common,
    orders,
    accounts,
    management,
    manageUsers,
  },
})
